export const BULK_ACTIONS = {
  BILL_LODGEMENT: "export_bill_lodgement",
  EBRC: "ebrc",
  REGULARISATION: "bank_regularisation",
};

export const BULK_TABLE_ROW_ACTION = {
  VIEW: "view_bulk_summary",
  DOWNLOAD: "download",
  WITHDRAW: "withdraw",
  REMIND: "remind",
  ACCEPT: "accept",
  REJECT: "reject",
  REMINDER_HISTORY: "reminder_history",
};

export const BULK_MODAL_SCREEN_TYPE = {
  BILL_LODGEMENT: "export_bill_lodgement",
  EBRC: "ebrc",
  REGULARISATION: "regularisation",
  SELECTED_BANK_ACTIONS: "selected_bank_actions",
  BULK_SUMMARY_VIEW: "view",
  SUCCESS_POPUP: "success_popup",
  BL_PENDING_DOCS: "bl_pending_docs",
  REMINDER_HISTORY: "reminder_history",
};

export const BULK_COMMON_SLUGS = {
  UPLOAD_SB: "upload_sb",
};

export const BULK_SUMMARY_VIEW_POPUP_SLUGS = {
  CANCEL: "cancel",
  SHOW_DETAILS: "show_details",
  SHOW_SUMMARY: "show_summary",
  EXPORT_BILL_LODGEMENT: "export_bill_lodgement",
  FEMA_DECLARATION: "bulk_action",
};

export const BANK_SELECTED_POPUP_SLUGS = {
  CHANGE_BANK: "change_bank",
  BACK: "back",
  VIEW_DETAILS_ELIGIBLE: "view_details_eligible",
  INITIATE_BULK_ACTION: "initiate_bulk_action",
  VIEW_DETAILS_PENDING_DOCS: "view_details_pending_docs",
  VIEW_DETAILS_OVERDUE: "view_details_overdue",
};

export const SUCCESS_POPUP_SLUGS = {
  MAP_OTHER_BANKS: "map_other_bank",
  VIEW_DETAILS: "view_details",
};

export const BL_DOCS_POPUP_SLUG = {
  SUBMIT: "submit",
  CLOSE: "close",
};

export const BULK_SUMMARY_TEXT_SLUGS = {
  REQUEST_DATE: "request_date",
  BULK_ID: "bulk_id",
  SB_COUNT: "sb_count",
  INVOICE_COUNT: "invoice_count",
};

export const SINGLE_BULK_PAGE_SLUGS = {
  ACCEPT: "checker_accept",
  REJECT: "checker_reject",
  WITHDRAW: "maker_withdraw",
  REMIND: "maker_remind",
};
