// react imports
import React, { useEffect, useState } from "react";

// redux imports

// third-party imports
import endpoints from "../api/endpoints";
import { apiRequest } from "../redux/common/actions";
import { useDispatch } from "react-redux";

// in-app imports
import BlunoTable from "./BlunoTable";
import Button from "./Button";

import dropUpIcon from "../assets/media/exportBills/DropUp-Blue.png";

// utils
import { checkIfBalanceZero, validateDecimalNumber } from "../utils/Validation";
import BlunoTooltip from "./BlunoTooltip";
import ModalV2 from "./ModalV2";
import BankChargesModal from "./export-bills/BankChargesModal";
import { sanitizeHtml } from "../utils/sanitizeHtml";
import BlunoIcon from "./BlunoIcon";

const MapIRMSummary = ({
  data,
  getReconData,
  onActionClick,
  onEditSection,
  sbBalanceAmount,
  rowFormData,
  undebouncedRowFormData,
  setRowFormData,
  showOtherIrms = false,
  setShowOtherIrms,
  isIrmAddedManually = false,
  setIsIrmAddedManually,
  irmFilter,
  setIrmFilter,
}) => {
  const dispatch = useDispatch();

  // local state variables
  const [open, setOpen] = useState();
  const [hoverEdit, setHoverEdit] = useState(false);
  const [showDeviationPopup, setShowDeviationPopup] = useState(false);
  const [deviationPopupData, setDeviationPopupData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [irmChargeId, setIrmChargeId] = useState(null);

  useEffect(() => {
    if (data?.is_dropdown_open !== null) {
      setOpen(data?.is_dropdown_open);
    }
  }, [data?.is_dropdown_open]);

  const refreshReconPageData = () => {
    getReconData(true);
  };

  const onRowFormDataChange = (value, row, slug) => {
    if (value && !validateDecimalNumber(String(value).replaceAll(",", ""))) {
      return;
    }
    setRowFormData((prev) => ({
      ...prev,
      [row?.id]: {
        ...prev[row?.id],
        [slug]: { ...prev[row?.id][slug], value: value },
      },
    }));
  };

  const onButtonClick = (action) => {
    if (action?.slug === "standard_sb_deviation_lte_5") {
      setDeviationPopupData(data?.modal_data?.standard_sb_deviation_lte_5);
      setShowDeviationPopup(true);
    } else {
      onActionClick(data?.slug, action);
    }
  };

  const onBankChargesIconClick = async (irmId, action) => {
    const dataRes = await dispatch(
      apiRequest(endpoints.getIRMData, `/${irmId}`)
    );
    if (
      dataRes?.success &&
      dataRes?.data &&
      dataRes?.data?.other_charges_modal
    ) {
      setModalData(dataRes?.data?.other_charges_modal);
      setIrmChargeId(irmId);
      setShowModal(true);
    }
  };

  const onRowFormFocus = (e, row, slug) => {
    setTimeout(() => {
      e.target.select();
    }, 0);
  };

  return (
    <>
      {showModal && (
        <div className="single-irm__modal-bg">
          <ModalV2 slug={"standard"}>
            <BankChargesModal
              data={modalData}
              setShowModal={setShowModal}
              setModalData={setModalData}
              refreshPageData={refreshReconPageData}
              irmId={irmChargeId}
            />
          </ModalV2>
        </div>
      )}
      <div className={`mapirm-summary`}>
        <div
          className="mapirm-summary__container"
          onClick={() => setOpen(!open)}
        >
          <div className="mapirm-summary__title">
            {data?.status_icon && (
              <img
                src={data?.status_icon}
                alt="step-done"
                className="mapirm-summary__title-stepdone"
              />
            )}
            <h2 className="mapirm-summary__title-title">
              {data?.title}
              {/* {data?.sub_title?.title && <p>{data?.sub_title?.title}</p>}
              {data?.sub_title?.text && <span>{data?.sub_title?.text} </span>} */}
            </h2>
            {data?.is_editable && data?.edit_info && (
              <BlunoIcon
                src={data?.edit_info?.icon}
                alt={data?.edit_info?.action}
                hoverText={data?.edit_info?.tooltip_text}
                iconWidth="16px"
                iconHeight="16px"
                onClick={(e) => {
                  e.stopPropagation();

                  onEditSection(
                    data?.slug,
                    data?.edit_info?.action,
                    data?.edit_info?.alert_id
                  );
                }}
              />
            )}
          </div>
          {data?.is_dropdown && (
            <div
              className="mapirm-summary__dropdown"
              onClick={() => setOpen(!open)}
            >
              <p className="mapirm-summary__dropdown-text">
                {open ? "Hide Details" : "Show Details"}
              </p>
              <img
                src={dropUpIcon}
                alt="Dropdown Icon"
                className={`mapirm-summary__dropdown-icon ${
                  !open ? "mapirm-summary__dropdown--rotate" : ""
                }`}
              />
            </div>
          )}
        </div>
        {open && (
          <div className="mapirm-summary__wrapper">
            {data?.empty_state ? (
              <div className="mapirm-summary__emptylist">
                <div className="mapirm-summary__emptylist-header">
                  {data?.buttons?.map((btn) => {
                    return (
                      <Button
                        buttonLabel={btn?.text}
                        buttonType={btn?.sub_type}
                        icon={btn?.icon}
                        hoverIcon={btn?.hover_icon}
                        iconAlignment={btn?.icon_alignment}
                        onClick={() => {
                          setShowOtherIrms(true);
                        }}
                      />
                    );
                  })}
                </div>
                <div className="mapirm-summary__emptylist-content">
                  {data?.empty_state?.icon && (
                    <img
                      src={data?.empty_state?.icon}
                      alt="No IRM Available"
                      className="mapirm-summary__emptylist-icon"
                    />
                  )}
                  <p className="mapirm-summary__emptylist-text">
                    {data?.empty_state?.text}
                  </p>
                </div>
                {data?.empty_state_buttons && (
                  <div className="mapirm-summary__submit">
                    {data?.empty_state_buttons?.map((action) => {
                      return (
                        action?.type === "button" && (
                          <Button
                            buttonLabel={action?.text}
                            buttonType={action?.sub_type}
                            icon={action.icon}
                            hoverIcon={action?.hover_icon}
                            iconAlignment={action?.icon_alignment}
                            tooltipText={action?.tooltip_text}
                            tooltipPosition={{ bottom: "40px", left: "50px" }}
                            disabled={action?.disabled}
                            onClick={() => onActionClick(data?.slug, action)}
                          />
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div className="mapirm-summary__content">
                <div className="mapirm-summary__info">
                  <div className="mapirm-summary__info-header">
                    <div className="mapirm-summary__info-header--left">
                      {data?.irms?.sub_title2 && (
                        <>
                          <div className="mapirm-summary__info-text">
                            {data?.irms?.sub_title2?.icon && (
                              <img
                                src={data?.irms?.sub_title2?.icon}
                                alt={data?.irms?.sub_title2?.icon}
                              />
                            )}
                            <p
                              className={`${
                                data?.irms?.sub_title2?.type === "hyperlink"
                                  ? "mapirm-summary__info-text--link"
                                  : ""
                              }`}
                              onClick={() =>
                                data?.irms?.sub_title2?.type === "hyperlink" &&
                                onActionClick(
                                  data?.slug,
                                  data?.irms?.sub_title2
                                )
                              }
                            >
                              {data?.irms?.sub_title2?.text}
                            </p>
                          </div>
                        </>
                      )}
                      {(data?.irms?.sub_title1 || data?.irms?.sub_title2) && (
                        <p className="mapirm-summary__info-partition">|</p>
                      )}

                      {data?.irms?.sub_title1 && (
                        <h2 className="mapirm-summary__info-title">
                          {data?.irms?.sub_title1?.title}
                          {data?.irms?.sub_title1?.text && (
                            <p>{data?.irms?.sub_title1?.text}</p>
                          )}
                          <span>{data?.irms?.sub_title1?.subtext} </span>
                        </h2>
                      )}

                      <div className="mapirm-summary__info-tabs">
                        {data?.irms?.filters?.map((filter) => {
                          return (
                            <p
                              className={`mapirm-summary__info-tab ${
                                filter?.slug === irmFilter &&
                                "mapirm-summary__info-tab--active"
                              }`}
                              onClick={() => {
                                onActionClick(
                                  "map_irms",
                                  {
                                    slug: "irm_filter_change",
                                    irm_filter: filter?.slug,
                                  },
                                  null,
                                  null
                                );
                                setIrmFilter(filter?.slug);
                              }}
                            >
                              {filter?.text}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                    <div className="mapirm-summary__info-header--right">
                      {data?.irms?.buttons?.map((btn) => {
                        return (
                          <Button
                            buttonLabel={btn?.text}
                            buttonType={btn?.sub_type}
                            icon={btn?.icon}
                            hoverIcon={btn?.hover_icon}
                            iconAlignment={btn?.icon_alignment}
                            onClick={() => {
                              setShowOtherIrms(true);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <BlunoTable
                    data={data?.irms}
                    onTableRowSelection={(id, section, slug, row) =>
                      onActionClick(section, slug, row)
                    }
                    rowFormData={rowFormData}
                    onRowFormChange={onRowFormDataChange}
                    onRowFormFocus={onRowFormFocus}
                    undebouncedRowFormData={undebouncedRowFormData}
                    // onActionClick={onActionClick}
                    section={data?.slug}
                    metadata={{
                      sb_balance_amount: checkIfBalanceZero(sbBalanceAmount),
                    }}
                    showHoverBlueRow={false}
                    genericState={isIrmAddedManually}
                    setGenericState={setIsIrmAddedManually}
                    maxDataHeight="300px"
                    irmSimulationFilter={irmFilter}
                    onIconClick={onBankChargesIconClick}
                  />
                </div>
                {data?.buttons && (
                  <div className="mapirm-summary__submit">
                    {data?.buttons?.map((action) => {
                      return (
                        action?.type === "button" && (
                          <Button
                            buttonLabel={action?.text}
                            buttonType={action?.sub_type}
                            // icon={action?.icon}
                            iconAlignment={action?.icon_alignment}
                            tooltipText={action?.tooltip_text}
                            tooltipPosition={{ bottom: "40px", left: "50px" }}
                            disabled={action?.disabled}
                            onClick={() => onButtonClick(action)}
                          />
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {showDeviationPopup && (
        <div className="single-irm__modal-bg">
          <ModalV2 slug={"standard"}>
            <ReconDeviationPopup
              data={deviationPopupData}
              onActionClick={onButtonClick}
              setDeviationPopupData={setDeviationPopupData}
              setShowDeviationPopup={setShowDeviationPopup}
            />
          </ModalV2>
        </div>
      )}
    </>
  );
};

const ReconDeviationPopup = ({
  data,
  onActionClick,
  setDeviationPopupData,
  setShowDeviationPopup,
}) => {
  const onPopupButtonClick = (action) => {
    if (action?.slug === "cancel") {
      setDeviationPopupData({});
      setShowDeviationPopup(false);
    } else {
      onActionClick(action);
      setDeviationPopupData({});
      setShowDeviationPopup(false);
    }
  };

  return (
    <div className="mapirm-summary__popup">
      <div className="mapirm-summary__popup-header">
        <h2 className="mapirm-summary__popup-title">{data?.title}</h2>
        <img
          src={data?.icon}
          alt="close icon"
          onClick={() => onPopupButtonClick({ slug: "cancel" })}
        />
      </div>
      <div className="mapirm-summary__popup-content">
        <p
          className="mapirm-summary__popup-text"
          dangerouslySetInnerHTML={sanitizeHtml(data?.text)}
        />
        <p className="mapirm-summary__popup-subtext">{data?.sub_text}</p>
        {data?.buttons && (
          <div className="mapirm-summary__popup-buttons">
            {data?.buttons?.map((item, idx) => {
              return (
                <Button
                  key={idx}
                  icon={item?.icon}
                  buttonType={item?.sub_type}
                  buttonLabel={item?.text}
                  disabled={item?.disabled}
                  iconAlignment={item?.icon_alignment}
                  width="110px"
                  onClick={() => onPopupButtonClick(item)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default MapIRMSummary;
