// react imports
import React, { useState, useEffect, useRef } from "react";

// in-app imports
import SBHeader from "../../components/export-bills/SBHeader";
import SBDetails from "../../components/export-bills/SBDetails";
import SkeletonBasic from "../../components/SkeletonLoaders/SkeletonBasic";

const SbSummary = (props) => {
  const {
    highlightedData,
    onActionClick,
    onDocAction,
    newReconRef,
    uploadSingleFile,
    newReconSummaryRef,
    isSBDetailView,
    setIsSBDetailView,
    summaryErrorMessage,
    sbType = "",
    documentManagement = false,
    closeModal,
    closeIcon,
  } = props;

  // local state variables
  const waitRef = useRef(false);
  const headingRef = useRef(null);

  const handleScroll = async (ref) => {
    await ref.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    setTimeout(() => {
      waitRef.current = false;
    }, 500);
  };

  const handleParentScroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { clientHeight } = newReconSummaryRef.current;

    if (
      clientHeight > headingRef.current?.clientHeight + 20 &&
      newReconRef.current.scrollTop >=
        clientHeight - (headingRef.current?.clientHeight + 50) &&
      !waitRef.current
    ) {
      setIsSBDetailView(false);
      if (newReconSummaryRef?.current?.style?.position !== undefined)
        newReconSummaryRef.current.style.position = "sticky";
    }
  };

  // Add scroll event listener to the parent element
  useEffect(() => {
    if (documentManagement) return;
    const newReconPage = newReconRef.current;
    if (newReconPage) {
      newReconPage.addEventListener("scroll", handleParentScroll);
    }

    return () => {
      if (newReconPage) {
        newReconPage.removeEventListener("scroll", handleParentScroll);
      }
    };
  }, [newReconRef]);

  const handleDropdownClick = () => {
    if (!isSBDetailView) {
      if (newReconSummaryRef?.current?.style?.position !== undefined)
        newReconSummaryRef.current.style.position = "relative";
      waitRef.current = true;
      handleScroll(newReconSummaryRef.current);
    } else {
      if (newReconSummaryRef?.current?.style?.position !== undefined)
        newReconSummaryRef.current.style.position = "sticky";
    }
    setIsSBDetailView(!isSBDetailView);
  };

  return (
    <div
      className={`m-sb-summary ${
        documentManagement ? "m-sb-summary--doc-management" : ""
      }`}
    >
      <SkeletonBasic showLoader={highlightedData ? false : true}>
        <SBHeader
          isDropdown={highlightedData?.header?.dropdown}
          isSBDetailView={isSBDetailView}
          onDropdownClick={() => handleDropdownClick()}
          data={highlightedData?.header}
          sbType={sbType}
          onActionClick={onActionClick}
          onDocAction={onDocAction}
          headingRef={headingRef}
          documentManagement={documentManagement}
          closeModal={closeModal}
          closeIcon={closeIcon}
        />

        <SBDetails
          isSBDetailView={isSBDetailView}
          middleData={highlightedData?.middle}
          invoiceData={highlightedData?.invoice_details}
          transportData={highlightedData?.transport_details}
          billLodgementData={highlightedData?.bill_lodgement_details}
          buttons={highlightedData?.buttons}
          onDocAction={onDocAction}
          uploadSingleFile={uploadSingleFile}
          summaryErrorMessage={summaryErrorMessage}
          onActionClick={onActionClick}
        />
      </SkeletonBasic>
    </div>
  );
};

export default SbSummary;
