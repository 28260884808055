// react imports
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// redux import
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

// third-party imports

// in-app imports
import BlunoTable from "../../components/BlunoTable";
import { GetQueryParamValue } from "../../utils";
import BulkActionModal from "../../components/bulkInfo/BulkActionModal";
import ModalV2 from "../../components/ModalV2";
import BulkSummary from "../../components/bulkInfo/BulkSummary";
import {
  BULK_ACTIONS,
  BULK_MODAL_SCREEN_TYPE,
  BULK_SUMMARY_TEXT_SLUGS,
  BULK_SUMMARY_VIEW_POPUP_SLUGS,
} from "../../utils/constants/BulkConstants";
import Button from "../../components/Button";

const BulkBillLodgement = () => {
  // defined to access react based apis
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // local states
  const [apiData, setApiData] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [rowSelectionState, setRowSelectionState] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalScreenType, setModalScreenType] = useState();
  const [showInfiniteScrollLoader, setShowInfiniteScrollLoader] =
    useState(false);

  const infiniteScrollLock = useRef(false);

  const { bulkBLId } = useParams();
  const bankName = GetQueryParamValue("bank_name");

  const onTableRowSelection = (id) => {
    if (!rowSelectionState[id])
      setRowSelectionState((prev) => ({ ...prev, [id]: true }));
    else
      setRowSelectionState((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
  };

  const onAllTableRowSelection = (rowSelectionState, data) => {
    if (
      Object.keys(rowSelectionState).length <= data.length &&
      Object.keys(rowSelectionState).length !== 0
    ) {
      setRowSelectionState({});
    } else {
      let rowState = {};
      data?.map((item) => {
        rowState[item?.id] = true;
      });
      setRowSelectionState(rowState);
    }
  };

  const onSubmitBulkBillLodgement = (action) => {
    let selectedIds = Object.keys(rowSelectionState);
    const onSubmit = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.submitBulkAction, {
          action_type: action?.slug,
          selected_ids: selectedIds,
          bank_name: bankName,
          id: bulkBLId,
        })
      );
      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.success_modal) {
          setModalScreenType(BULK_MODAL_SCREEN_TYPE?.SUCCESS_POPUP);
          setModalData(dataRes?.data?.success_modal);
          setShowModal(true);
        }
      }
    };
    onSubmit();
  };

  const onSummaryButtonClick = (action) => {
    switch (action?.slug) {
      case BULK_SUMMARY_VIEW_POPUP_SLUGS?.EXPORT_BILL_LODGEMENT:
        setModalData({
          ...apiData?.bulk_action_declaration,
          action_details: apiData?.bulk_action_declaration?.action_details?.map(
            (item) => {
              switch (item?.slug) {
                case BULK_SUMMARY_TEXT_SLUGS?.SB_COUNT:
                  return {
                    ...item,
                    value: Object.keys(rowSelectionState)?.length,
                  };

                case BULK_SUMMARY_TEXT_SLUGS?.INVOICE_COUNT:
                  const keys = Object.keys(rowSelectionState);
                  return {
                    ...item,
                    value: apiData?.table_data?.data?.reduce((acc, item) => {
                      if (keys.includes(String(item?.id))) {
                        if (item?.nested_data) acc += item?.nested_data?.length;
                      }

                      return acc;
                    }, keys.length),
                  };

                default:
                  return item;
              }
            }
          ),
        });
        setShowModal(true);
        setModalScreenType(BULK_MODAL_SCREEN_TYPE?.BULK_SUMMARY_VIEW);
        break;
      case "back":
        navigate("/bulk-info", {
          state: {
            bank: bankName,
            show_bank_popup: true,
            action_type: BULK_ACTIONS?.BILL_LODGEMENT,
          },
        });
        break;
    }
  };

  const fetchBulkBillLodgementData = async () => {
    const pathQueryString = `/${bulkBLId}?action_type=export_bill_lodgement&page_no=${pageNo}&bank=${bankName}&show_table_data=true`;
    const dataRes = await dispatch(
      apiRequest(endpoints.fetchBulkBillLodgement, pathQueryString)
    );
    if (dataRes?.success && dataRes?.data) {
      if (
        dataRes?.data?.status_object &&
        dataRes?.data?.status_object?.deeplink
      ) {
        navigate(dataRes?.data?.status_object?.deeplink);
      }
      setApiData(dataRes.data);
      setPageNo(dataRes?.data?.table_data?.pagination?.current_page);
    }
  };

  const handleInfiniteScroll = async () => {
    if (
      apiData?.table_data?.pagination?.has_next &&
      !infiniteScrollLock.current
    ) {
      infiniteScrollLock.current = true;
      setShowInfiniteScrollLoader(true);
      await fetchMoreBulkBillLodgementData();
      infiniteScrollLock.current = false;
      setShowInfiniteScrollLoader(false);
    }
  };

  const fetchMoreBulkBillLodgementData = async () => {
    const pathQueryString = `/${bulkBLId}?action_type=export_bill_lodgement&page_no=${
      pageNo + 1
    }&bank=${bankName}&show_table_data=true`;
    const dataRes = await dispatch(
      apiRequest(endpoints.fetchMoreBulkBillLodgement, pathQueryString)
    );
    if (dataRes?.success && dataRes?.data) {
      setApiData((prev) => ({
        ...prev,
        table_data: {
          ...prev?.table_data,
          data: [...prev?.table_data?.data, ...dataRes?.data?.table_data?.data],
          pagination: dataRes?.data?.table_data?.pagination,
        },
      }));
      setPageNo(dataRes?.data?.table_data?.pagination?.current_page);
    }
  };

  useEffect(() => {
    fetchBulkBillLodgementData();
  }, []);

  return (
    <>
      {showModal && (
        <div className="manage-settings__add-user-modal-bg">
          <ModalV2 slug={"standard"}>
            <BulkActionModal
              data={modalData}
              setShowModal={setShowModal}
              setModalData={setModalData}
              modalScreenType={modalScreenType}
              setModalScreenType={setModalScreenType}
              onActionClick={onSubmitBulkBillLodgement}
              singleBulk={true}
            />
          </ModalV2>
        </div>
      )}
      <div className="bulk-bl">
        <div className="bulk-bl__summary">
          <BulkSummary
            data={apiData?.header}
            onSummaryButtonClick={onSummaryButtonClick}
            buttonDisabled={Object?.keys(rowSelectionState)?.length <= 0}
            externalBack={true}
          />
        </div>
        <div className="bulk-bl__table">
          {apiData?.table_data && (
            <BlunoTable
              showHoverBlueRow={false}
              data={apiData?.table_data}
              rowSelectionState={rowSelectionState}
              onTableRowSelection={onTableRowSelection}
              onAllTableRowSelection={onAllTableRowSelection}
              infiniteScroll={true}
              onInfiniteScrollReachBottom={handleInfiniteScroll}
              showInfiniteScrollLoader={showInfiniteScrollLoader}
            />
          )}
        </div>
        {apiData?.buttons && (
          <div className="bulk-bl__buttons">
            {apiData?.buttons?.map((item, idx) => {
              return (
                <Button
                  key={idx}
                  icon={item?.icon}
                  buttonType={item?.sub_type}
                  buttonLabel={item?.text}
                  iconAlignment={item?.icon_alignment}
                  onClick={() => {
                    onSummaryButtonClick(item);
                  }}
                  disabled={Object.keys(rowSelectionState)?.length <= 0}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default BulkBillLodgement;
