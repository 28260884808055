//react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { showToast } from "../../redux/toast/slice";

//in app imports
import UploadFileCard from "../UploadFile/UploadFile";
import BlunoRadio from "../BlunoRadio";
import Dropdown from "../Dropdown";
import Button from "../Button";
import TextInput from "../TextInput";
import {
  DOCUMENT_TYPE,
  MODAL_STATE,
  SUB_MODAL_TYPE,
  UPLOAD_DOCUMENT_FLOW,
} from "../../utils/constants/TableConstants";
import { DOCUMENT_ACTION } from "../../utils/constants/DocumentConstants";
import { downloadDocumentUtility } from "../../utils/downloadDocument";
import PendingModal from "./PendingModal";
import SuccessModal from "./SuccessModal";
import BlunoTooltip from "../BlunoTooltip";
import MuiTextInput from "../blunoInputs/MuiTextInputs";

const UploadDocCard = ({
  data = null,
  setShowModal,
  sbId = "",
  bulkId = null,
  tableData = {},
  setTableData,
  subModalData = {},
  setSubModalData,
  uploadFlow = UPLOAD_DOCUMENT_FLOW?.SB_TABLE,
  subModalType = "invisible",
  setSubModalType,
  maxFileNameWidth = "180px",
  onActionClick = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [transportMetaDeta, setTransportMetadata] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [transportDocId, setTransportDocId] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const formData = new FormData();

  const redirectToLink = (theSlug) => {
    navigate(theSlug, { state: { text: "Pending Docs" } });
  };

  const updatedSingleRowData = async () => {
    const apiData = await dispatch(
      apiRequest(endpoints.getSbSingleRowData, `/${sbId}`)
    );

    if (apiData?.success && apiData?.data) {
      const dataObj = apiData?.data?.[0];
      if (uploadFlow === UPLOAD_DOCUMENT_FLOW?.SB_TABLE) {
        const updatedData = tableData?.data?.map((item) =>
          item?.id === dataObj?.id ? dataObj : item
        );
        setTableData({
          ...tableData,
          data: updatedData,
        });
      }
      setModalData(dataObj?.bank_flow?.modal);

      return dataObj;
    } else {
      // error toast message
      if (apiData?.data?.toast) {
        dispatch(showToast(apiData?.data?.toast));
      }

      return null;
    }
  };

  const handleSubmitClick = async (action) => {
    if (uploadFlow === UPLOAD_DOCUMENT_FLOW?.BULK_BL) {
      onActionClick(action);
      return;
    } else {
      const apiRes = await updatedSingleRowData();
      if (apiRes) {
        if (
          apiRes?.bank_flow?.invoice_docs?.status === MODAL_STATE?.completed &&
          apiRes?.bank_flow?.transport_docs?.status === MODAL_STATE?.completed
        ) {
          setSubModalType(SUB_MODAL_TYPE?.success);
          setSubModalData(apiRes?.success_modal);
        } else {
          setSubModalType(SUB_MODAL_TYPE?.pending);
          setSubModalData(apiRes?.pending_modal);
        }
        setIsChanged(false);
      }
    }
  };

  const handleCloseClick = async (action) => {
    if (uploadFlow === UPLOAD_DOCUMENT_FLOW?.BULK_BL) {
      onActionClick(action);
    } else {
      if (!isChanged) {
        setShowModal(false);
        return;
      }
      const apiRes = await updatedSingleRowData();
      if (apiRes) {
        if (
          apiRes?.bank_flow?.invoice_docs?.status === MODAL_STATE?.pending ||
          apiRes?.bank_flow?.transport_docs?.status === MODAL_STATE?.pending
        ) {
          isChanged && setSubModalType(SUB_MODAL_TYPE?.pending);
          setSubModalData(apiRes?.pending_modal);
        } else {
          isChanged && setShowModal(false);
        }

        !isChanged && setShowModal(false);
        setIsChanged(false);
      }
    }
  };

  const uploadSingleFile = (selectedFiles, doc_type, invoiceId) => {
    let metadata = {};
    if (doc_type === DOCUMENT_TYPE?.transportDoc) {
      if (!transportMetaDeta?.document_type) {
        setErrorMsg("Please select document type");
        return;
      }

      metadata = {
        flow: uploadFlow,
        sb_id: sbId,
        bulk_id: bulkId,
        invoice_id: invoiceId,
        document_type: transportMetaDeta?.document_type,
        document_type_other:
          transportMetaDeta?.document_type === "others"
            ? transportMetaDeta?.document_type_other
            : "",
      };
    } else if (doc_type === DOCUMENT_TYPE?.invoiceDoc) {
      metadata = {
        flow: uploadFlow,
        sb_id: sbId,
        invoice_id: invoiceId,
        bulk_id: bulkId,
      };
    }

    formData.append("file", selectedFiles[0]);
    formData.append("doc_type", doc_type);
    formData.append("metadata", JSON.stringify(metadata));

    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );

      if (dataRes?.success && dataRes?.data) {
        const apiRes = await updatedSingleRowData();
        if (apiRes) {
          const theData = apiRes?.bank_flow?.modal;
          if (
            theData?.transport_docs?.data?.length > 0 &&
            theData?.transport_docs?.data?.[0]?.document?.uploaded_documents
              ?.length > 0
          ) {
            const upoloadedDoc =
              theData?.transport_docs?.data?.[0]?.document
                ?.uploaded_documents?.[0];
            setTransportDocId(upoloadedDoc?.id);
          }
        }
        setIsChanged(true);
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    };

    uploadApiCall();
  };

  const onDocAction = (
    section,
    actionBtn,
    documentId,
    fileName,
    docType,
    invoiceId
  ) => {
    let metadata;
    if (uploadFlow === UPLOAD_DOCUMENT_FLOW?.BULK_BL) {
      metadata = {
        flow: uploadFlow,
        bulk_id: bulkId,
      };
    }
    const docAction = async (
      section,
      actionBtn,
      documentId,
      fileName,
      docType
    ) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          section: section,
          action: actionBtn?.action,
          document_id: documentId,
          sb_id: sbId,
          flow: uploadFlow,
          document_type: docType,
          invoice_id: invoiceId,
          bulk_id: bulkId,
          metadata: JSON.stringify(metadata),
        })
      );

      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn?.action === DOCUMENT_ACTION?.VIEW) {
            window.open(dataRes?.data?.document_link, "_blank");
          } else if (actionBtn?.action === DOCUMENT_ACTION?.DOWNLOAD) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        }
        if (actionBtn?.action === "detach") {
          // error toast message
          if (dataRes?.data?.toast) {
            dispatch(showToast(dataRes?.data?.toast));
          } else {
            await updatedSingleRowData();
            setTransportDocId("");
            setIsChanged(true);
          }
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    };

    docAction(section, actionBtn, documentId, fileName, docType);
  };

  const detachTransportDocument = async () => {
    let metadata;
    if (uploadFlow === UPLOAD_DOCUMENT_FLOW?.BULK_BL) {
      metadata = {
        flow: uploadFlow,
        bulk_id: bulkId,
      };
    }
    const dataRes = await dispatch(
      apiRequest(endpoints.docAction, {
        section: "sb_docs",
        action: "detach",
        document_id: transportDocId,
        sb_id: sbId,
        document_type: DOCUMENT_TYPE?.transportDoc,
        metadata: JSON.stringify(metadata),
      })
    );

    if (dataRes?.success && dataRes?.data) {
      const apiRes = await updatedSingleRowData();
      if (apiRes) {
        setModalData((prevState) => {
          const newState = {
            ...prevState,
            transport_docs: {
              ...prevState?.transport_docs,
              data: apiRes?.bank_flow?.modal?.transport_docs?.data,
            },
          };
          return newState;
        });
        setTransportDocId("");
      }
      setIsChanged(true);
    } else {
      // error toast message
      if (dataRes?.data?.toast) {
        dispatch(showToast(dataRes?.data?.toast));
      }
    }
  };

  const handleInputChange = (theValue, theSlug) => {
    setTransportMetadata({
      ...transportMetaDeta,
      [theSlug]: theValue,
    });

    if (transportDocId) {
      detachTransportDocument();
    }
    setIsChanged(true);
  };

  const isTransportDocDisabled = () => {
    if (
      !transportMetaDeta?.document_type ||
      (transportMetaDeta?.document_type === "others" &&
        !transportMetaDeta?.document_type_other)
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setModalData(data);
    setTransportMetadata({
      ...transportMetaDeta,
      document_type: data?.transport_docs?.document_type?.default?.value,
      document_type_other:
        data?.transport_docs?.document_type_other?.default_value,
    });
    if (
      data?.transport_docs?.data?.length > 0 &&
      data?.transport_docs?.data?.[0]?.document?.uploaded_documents?.length > 0
    ) {
      const upoloadedDoc =
        data?.transport_docs?.data?.[0]?.document?.uploaded_documents?.[0];
      setTransportDocId(upoloadedDoc?.id);
    }
  }, [data]);

  return (
    <>
      {subModalType === SUB_MODAL_TYPE?.invisible && (
        <div className="s-table__modal">
          <div className="s-table__modal-section">
            <p className="s-table__modal-title">
              {modalData?.invoice_docs?.title}
            </p>
            <div className="s-table__modal-files">
              <div className="s-table__modal-files--invoice">
                {modalData?.invoice_docs?.data?.map((doc) => {
                  return (
                    <div>
                      <p className="s-docsnap__left">
                        <span className="s-docsnap__srno">{doc?.sr_no}</span>
                        <span className="s-docsnap__text">{doc?.text}</span>
                      </p>
                      <div className="s-table__modal-doc">
                        <UploadFileCard
                          upload_enabled={doc?.document?.upload_enabled}
                          documents={doc?.document?.uploaded_documents}
                          doc_type={doc?.document?.slug}
                          invoiceId={doc?.id}
                          uploadSingleFile={uploadSingleFile}
                          // docId={docId}
                          section="sb_docs"
                          onDocAction={(...args) =>
                            onDocAction(...args, doc?.id)
                          }
                          maxFileNameWidth={maxFileNameWidth}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="s-table__modal-section s-table__modal-second">
            <p className="s-table__modal-title">
              {modalData?.transport_docs?.title}
            </p>
            <div className="s-table__transport">
              <p className="s-table__transport-header">
                {modalData?.transport_docs?.transport_mode?.header}
              </p>
              <p className="s-table__transport-text">
                {modalData?.transport_docs?.transport_mode?.text}
              </p>
            </div>
            {modalData?.transport_docs?.submitted_doc_text ? (
              <div className="s-table__transport">
                <p className="s-table__transport-header">Document Type:</p>
                <p className="s-table__transport-text">
                  {modalData?.transport_docs?.submitted_doc_text}
                </p>
              </div>
            ) : (
              <div className="s-table__transport-mode">
                <div className="s-table__modal-dropdown">
                  <div>
                    <Dropdown
                      options={
                        modalData?.transport_docs?.document_type?.options
                      }
                      defaultValue={transportMetaDeta?.document_type}
                      height="40px"
                      minWidth="190px"
                      fontSize="13px"
                      onSelectChange={(e) =>
                        handleInputChange(
                          e.value,
                          modalData?.transport_docs?.document_type?.slug
                        )
                      }
                      isDisabled={
                        modalData?.transport_docs?.document_type?.disabled
                      }
                      placeholder={
                        modalData?.transport_docs?.document_type?.placeholder
                      }
                      placeholderFontSize="13px"
                      placeholderFontWeight="400"
                      fancyPlaceholder={true}
                      topSpacing="-5px"
                      iconWidth="10px"
                    />
                  </div>
                </div>
                {transportMetaDeta?.document_type === "others" && (
                  <div className="s-table__modal-dropdown s-table__modal-dropdown--other">
                    <div style={{ marginTop: "10px", marginBottom: "-10px" }}>
                      <MuiTextInput
                        placeholder={
                          modalData?.transport_docs?.document_type_other
                            ?.placeholder
                        }
                        type={"text"}
                        value={transportMetaDeta?.document_type_other}
                        onChange={(e) => {
                          handleInputChange(
                            e.target.value,
                            modalData?.transport_docs?.document_type_other?.slug
                          );
                        }}
                        height="40px"
                        minWidth="180px"
                        width={"180px"}
                        id={
                          modalData?.transport_docs?.document_type_other?.slug
                        }
                        fontSize="13px"
                        disabled={
                          modalData?.transport_docs?.document_type_other
                            ?.disabled
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="s-table__modal-files" style={{ paddingTop: "0px" }}>
              {modalData?.transport_docs?.data?.map((doc) => {
                return (
                  <div>
                    {doc?.text && (
                      <p className="s-docsnap__left">
                        <span className="s-docsnap__srno">{doc?.sr_no}</span>
                        <span className="s-docsnap__text">{doc?.text}</span>
                      </p>
                    )}
                    <div
                      className="s-table__modal-doc"
                      style={{
                        marginTop: !doc?.document?.upload_enabled && "0px",
                      }}
                    >
                      <UploadFileCard
                        upload_enabled={doc?.document?.upload_enabled}
                        documents={doc?.document?.uploaded_documents}
                        doc_type={doc?.document?.slug}
                        invoiceId={doc?.id}
                        uploadSingleFile={uploadSingleFile}
                        // docId={docId}
                        section="sb_docs"
                        onDocAction={onDocAction}
                        disabled={isTransportDocDisabled()}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="s-table__modal-buttons">
            {modalData?.buttons?.map((btn) => {
              return (
                <Button
                  buttonLabel={btn?.text}
                  buttonType={btn?.sub_type}
                  onClick={() => {
                    handleSubmitClick(btn);
                  }}
                />
              );
            })}
          </div>
          <div className="s-table__modal-tprt">
            <BlunoTooltip text="Open SB" useChildren={true}>
              <img
                src={modalData?.deeplink_icon}
                alt="navigate"
                className="s-table__modal-icon-navigate"
                onClick={() => {
                  redirectToLink(modalData?.deeplink);
                }}
              />
            </BlunoTooltip>
            <img
              src={modalData?.close_icon}
              alt="close"
              className="s-table__modal-icon-close"
              onClick={() => {
                handleCloseClick({ slug: "close" });
              }}
            />
          </div>
        </div>
      )}
      {subModalType === SUB_MODAL_TYPE?.pending && (
        <PendingModal
          data={subModalData}
          setShowModal={setShowModal}
          setSubModalType={setSubModalType}
        />
      )}
      {subModalType === SUB_MODAL_TYPE?.success && (
        <SuccessModal
          data={subModalData}
          setShowModal={setShowModal}
          setSubModalType={setSubModalType}
        />
      )}
    </>
  );
};

export default UploadDocCard;
