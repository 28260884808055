import React, { forwardRef, useEffect, useState } from "react";
import InputErrorMsg from "./InputErrorMsg";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "../assets/svg/SearchIcon";

const TextInput = forwardRef(
  (
    {
      placeholder = "Enter Text",
      type = "text",
      icon = "",
      iconClass = "",
      iconWidth = "16px",
      iconAlignment = "left",
      isIconClickable = false,
      onIconClick,
      height = "48px",
      minWidth = "100px",
      width,
      inputParWidth,
      onChange,
      onKeyDown,
      value = "",
      isHighlighted,
      id,
      minLength,
      maxLength,
      extraClass,
      disabled = false,
      errorMessage = "",
      errorIcon,
      errorIconAlignment,
      fixedText = "",
      paddingLeft = null,
      onFocus = () => {},
      showMuiInput = false,
      searchIcon = true,
      readonly = false,
      isFocusedExternal = null,
      cursor = "text",
      fontSize = "13px",
      fixedTextLeftStyle,
      fixedTextTopStyle,
      fixedTextFontSize,
      disableCopyPaste = false,
      fixedTextColor = "#000",
      textAlign,
      errorMessageExtraClass,
      handleBlurAction = () => {},
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e) => {
      if (isFocusedExternal === null) setIsFocused(true);
      onFocus(e);
    };
    const handleBlur = (e) => {
      if (isFocusedExternal === null) setIsFocused(false);
      handleBlurAction(e);
    };
    const inputStyles = {
      height: height,
      minWidth: minWidth,
      width: width,
    };

    const inputBoxStyles = {
      paddingLeft: paddingLeft,
      fontSize: fontSize,
      textAlign: textAlign,
    };

    useEffect(() => {
      setIsFocused(isFocusedExternal);
    }, [isFocusedExternal]);

    return (
      <>
        {!showMuiInput ? (
          <div className="s-textinput__cont" style={{ width: inputParWidth }}>
            <div className={`s-textinput`} style={inputStyles}>
              {icon && (
                <img
                  className={`s-textinput__icon s-textinput__icon--${iconAlignment} ${
                    isIconClickable && "s-textinput__icon--cursor"
                  }`}
                  src={icon}
                  onClick={() => {
                    isIconClickable && onIconClick();
                  }}
                />
              )}
              {fixedText && (
                <p
                  className={`s-textinput__fixed ${
                    disabled ? "s-textinput__input--disabled" : ""
                  }`}
                  style={{
                    left: fixedTextLeftStyle,
                    top: fixedTextTopStyle,
                    fontSize: fixedTextFontSize,
                    color: fixedTextColor,
                    fontWeight: fixedTextColor === "#888888" ? "500" : "400",
                    textAlign: "center",
                  }}
                >
                  {fixedText}
                </p>
              )}
              <input
                className={`s-textinput__input s-textinput__input--${iconAlignment}  ${
                  (isHighlighted ? "s-textinput__input--highlighted" : "",
                  disabled ? "s-textinput__input--disabled" : "")
                } ${extraClass}`}
                style={inputBoxStyles}
                type={type}
                autoComplete={type === "password" ? "off" : "on"}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                onFocus={handleFocus}
                onBlur={handleBlur}
                id={id}
                minLength={minLength}
                maxLength={maxLength}
                onKeyDown={onKeyDown}
                ref={ref}
                disabled={disabled}
                onCopy={(e) => {
                  if (disableCopyPaste) e.preventDefault();
                }}
                onPaste={(e) => {
                  if (disableCopyPaste) e.preventDefault();
                }}
                onCut={(e) => {
                  if (disableCopyPaste) e.preventDefault();
                }}
              />
            </div>
            {errorMessage && (
              <InputErrorMsg
                message={errorMessage}
                icon={errorIcon}
                iconAlignment={errorIconAlignment}
                extraClass={errorMessageExtraClass}
              />
            )}
          </div>
        ) : (
          <div className={`s-textinput ${extraClass}`}>
            {fixedText && <p className="s-textinput__fixed">{fixedText}</p>}
            <TextField
              variant="outlined"
              type={type}
              label={placeholder}
              onChange={onChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={value}
              id={id}
              focused={isFocused}
              onCopy={(e) => {
                if (disableCopyPaste) e.preventDefault();
              }}
              onPaste={(e) => {
                if (disableCopyPaste) e.preventDefault();
              }}
              onCut={(e) => {
                if (disableCopyPaste) e.preventDefault();
              }}
              inputProps={{
                readOnly: readonly,
                minLength: minLength,
                maxLength: maxLength,
                style: {
                  height: height,
                  lineHeight: height,
                  fontSize: fontSize,
                  paddingTop: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0px",
                  paddingLeft: paddingLeft,
                  marginLeft: "5px",
                },
              }}
              InputProps={{
                endAdornment: (
                  <>
                    {iconAlignment === "right" && icon && (
                      <InputAdornment
                        position="end"
                        sx={{
                          marginLeft: "5px",
                          marginRight: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          isIconClickable && onIconClick();
                        }}
                      >
                        <img
                          className={`s-textinput__icon ${iconClass}`}
                          src={icon}
                          style={{ width: iconWidth }}
                        />
                      </InputAdornment>
                    )}
                  </>
                ),
                startAdornment: (
                  <>
                    {iconAlignment === "left" && searchIcon && (
                      <InputAdornment
                        position="start"
                        sx={{ marginLeft: "-5px" }}
                      >
                        <SearchIcon
                          color={
                            isFocused || isHighlighted ? "#046cb8" : "black"
                          }
                        />
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              onKeyDown={onKeyDown}
              inputRef={ref}
              disabled={disabled}
              error={!!errorMessage}
              helperText={
                errorMessage ? (
                  <InputErrorMsg
                    message={errorMessage}
                    icon={errorIcon}
                    iconAlignment={errorIconAlignment}
                  />
                ) : null
              }
              sx={{
                "& .MuiInputBase-root": {
                  height: height,
                  minWidth: minWidth,
                  width: width,
                  paddingLeft: paddingLeft,
                  borderRadius: "8px",
                  bgcolor: "#fff",
                  cursor: cursor,
                },

                "& .MuiInputLabel-root": {
                  fontSize: fontSize,
                  top: "-10px",
                  left: iconAlignment === "left" ? "13px" : "",
                  paddingLeft: paddingLeft,
                  "&.Mui-focused": {
                    fontSize: "13px",
                    transform: " translate(10px, 3px) scale(0.85)",
                    left: iconAlignment === "left" ? "" : "5px",
                    paddingLeft: "0px",
                  },
                  "&.MuiInputLabel-shrink": {
                    transform: "translate(10px, 3px) scale(0.85)",
                    left: iconAlignment === "left" ? "" : "5px",
                    color: isHighlighted ? "#046cb8" : "",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  color: isHighlighted ? "#046cb8" : "",
                  "& fieldset": {
                    borderColor: isHighlighted ? "#046cb8" : "",
                  },
                  "&:hover fieldset": {
                    borderColor: "#046cb8",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: isHighlighted ? "#046cb8" : "",
                    borderWidth: "1px",
                  },
                },
                input: { cursor: cursor },
                cursor: cursor,
                "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
              InputLabelProps={{
                shrink: isFocused || Boolean(value),
              }}
              className={` ${
                (isHighlighted ? "s-textinput__input--highlighted" : "",
                disabled ? "s-textinput__input--disabled" : "")
              }
            
               ${extraClass}`}
              fullWidth
            />
          </div>
        )}
      </>
    );
  }
);

export default TextInput;
