import React, { useEffect, useState } from "react";
import RejectRequestModal from "./RejectRequestModal";
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

export default function OptionsComponent({
  data,
  id,
  positionModal,
  onOptionsFunction,
}) {
  const [showRejectModal, setShowRejectModal] = useState(false);

  useEffect(() => {
    positionModal();
  }, [showRejectModal]);

  useEffect(() => {
    positionModal();
  }, []);
  return (
    <div className="bills__options-modal">
      {showRejectModal ? (
        <RejectRequestModal
          data={data?.reject_modal_data}
          id={id}
          setShowRejectModal={setShowRejectModal}
          positionModal={positionModal}
          onOptionsFunction={onOptionsFunction}
        />
      ) : (
        data?.buttons?.map((item, idx) => {
          if (item)
            return (
              <div
                className="bills__options-modal-item"
                key={idx}
                onClick={(e) => {
                  if (item?.slug === "reject") {
                    setShowRejectModal(true);
                  } else {
                    onOptionsFunction(item, id, item);
                  }
                }}
              >
                <img src={item?.icon} className="bills__options-modal-icon" />
                <div className="bills__options-modal-text-container">
                  <p className="bills__options-modal-text">{item?.text}</p>
                  {item?.sub_text && (
                    <p className="bills__options-modal-subText">
                      {item?.sub_text}
                    </p>
                  )}
                </div>
              </div>
            );
        })
      )}
    </div>
  );
}
