// react imports
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";

// third-party imports

// in-app imports
import IrmSummary from "../modules/SingleSB/IrmSummary";
import BankChargesModal from "../components/export-bills/BankChargesModal";
import ModalV2 from "../components/ModalV2";

const IRMDetailsPage = () => {
  // defined to access react based APIs
  const dispatch = useDispatch();
  const { irmId } = useParams();

  // local state variables
  const [irmData, setIrmData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  const getReconciliationData = async (showOtherCharges = false) => {
    const dataRes = await dispatch(
      apiRequest(endpoints.getIRMData, `/${irmId}`)
    );
    if (dataRes?.success && dataRes?.data) {
      setIrmData(dataRes?.data);
      if (showOtherCharges) {
        // setShowModal(true);
        setModalData(dataRes?.data?.other_charges_modal);
      }
    }
  };

  const fetchBankChargesInfo = () => {
    setModalData(irmData?.other_charges_modal);
    setShowModal(true);
  };

  useEffect(() => {
    getReconciliationData();
  }, [irmId]);

  return (
    <>
      {showModal && (
        <div className="single-irm__modal-bg">
          <ModalV2 slug={"standard"}>
            <BankChargesModal
              data={modalData}
              setShowModal={setShowModal}
              setModalData={setModalData}
              refreshPageData={getReconciliationData}
              irmId={irmId}
            />
          </ModalV2>
        </div>
      )}
      <div className="single-irm">
        <IrmSummary
          data={irmData}
          fetchBankChargesInfo={fetchBankChargesInfo}
        />
      </div>
    </>
  );
};

export default IRMDetailsPage;
