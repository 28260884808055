import React, { useEffect, useRef, useState } from "react";
import BlunoTable from "../../components/BlunoTable";
import Button from "../../components/Button";
import { closeAlert, resetAlertData, showAlert } from "../../redux/alert/slice";
import { useDispatch, useSelector } from "react-redux";
import { POPUP_SLUGS } from "../../utils/constants/SettingsConstants";
import ModalV2 from "../../components/ModalV2";
import AddUserPopup from "../../components/settings/manageUsers/AddUserPopup";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import useCallbackPrompt from "../../hooks/useCallBackPrompt";
import { useNavigate } from "react-router-dom";
import ManageUsersOptionsComponent from "./ManageUsersOptionsComponent";
import BlunoTooltip from "../../components/BlunoTooltip";

export default function ManageUsersCard({ getManageUsersData, data, setData }) {
  const [rowSelectionState, setRowSelectionState] = useState({});
  const orignalRowData = useRef({});
  const [rowData, setRowData] = useState({});
  const [changed, setChanged] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [allSelectedTableData, setAllSelectedTableData] = useState();
  const [selectableRows, setSelectableRows] = useState();
  const [blockerAlertData, setBlockerAlertData] = useState({});

  useCallbackPrompt(changed, blockerAlertData); // Navigation blocker hook

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAlertOk = useSelector((state) => state.alert.isAlertOk);
  const alertProps = useSelector((state) => state.alert.alertProps);

  const handleOptionsClick = async (e, action, id) => {
    if (action?.slug === "expired") {
      const apiRes = await dispatch(
        apiRequest(endpoints.regenerateInvite, { id: id })
      );
      if (apiRes?.success) {
        updateData();
      }
    } else if (action?.slug === "delete") {
      onDelete(id);
    }
  };

  const onTableRowSelection = (id) => {
    setRowSelectionState((prev) => {
      if (rowSelectionState?.[id] === undefined) {
        if (Object.keys(prev).length === selectableRows - 1)
          setAllSelected(true);
        return {
          ...prev,
          [id]: true,
        };
      } else {
        if (Object.keys(prev).length === selectableRows) setAllSelected(false);
        const { [id]: _, ...rest } = prev;
        return rest;
      }
    });
  };

  const onAllTableRowSelection = (rowSelectionState, data) => {
    if (
      rowSelectionState &&
      Object.keys(rowSelectionState).length <= selectableRows &&
      Object.keys(rowSelectionState).length !== 0
    ) {
      setRowSelectionState({});
      setAllSelected(false);
    } else {
      let tempState = {};
      data?.map((item) => {
        if (
          !Object.keys(item).includes("selection_support") ||
          item?.["selection_support"] === true
        )
          tempState[item.id] = true;
      });
      setRowSelectionState(tempState);
      setAllSelected(true);
    }
  };

  const onRoleChange = (e, id) => {
    setRowData((prev) => {
      const tempData = { ...prev, [id]: e.value };
      if (JSON.stringify(tempData) === JSON.stringify(orignalRowData.current))
        setChanged(false);
      else setChanged(true);
      return tempData;
    });
  };

  const onDelete = (id) => {
    id = Array.isArray(id) ? id : [id];
    id = id.map(String);
    dispatch(
      showAlert({
        items: {
          ...data?.delete_popup,
          text:
            id.length === 1
              ? data?.delete_popup?.text +
                data?.table?.data
                  ?.filter((item) => id?.includes(item?.id.toString()))
                  ?.map((item) => {
                    return item?.name?.text;
                  })
                  ?.reduce((tempStr, item, idx) => {
                    if (idx > 0) {
                      tempStr += ", ";
                    }
                    tempStr += item;
                    return tempStr;
                  }) +
                "?"
              : data?.delete_popup?.text + "selected users?",
        },
        props: {
          slug: "remove_user",
          id: id,
        },
      })
    );
  };

  const updateData = async () => {
    const apiRes = await dispatch(
      apiRequest(endpoints.getManageGroupsSettings)
    );
    if (apiRes?.success && apiRes?.data) {
      setData((prev) => ({ ...apiRes?.data, prev }));
    }
  };

  const deleteUser = async (id) => {
    let apiData = {};
    apiData["users"] = id?.map((item) => {
      return {
        id: item,
        role: orignalRowData?.current?.[item],
      };
    });
    const apiRes = await dispatch(apiRequest(endpoints?.deleteUser, apiData));
    if (apiRes?.success) {
      dispatch(closeAlert());
      dispatch(resetAlertData());
      updateData();
      setRowSelectionState({});
      setAllSelected(false);
    }
  };

  const changeUserRoles = async () => {
    let apiData = {};
    apiData["users"] = [];
    for (const [key, value] of Object.entries(rowData)) {
      if (value !== orignalRowData?.current?.[key])
        apiData["users"]?.push({
          id: key,
          new_role: value,
          role: orignalRowData?.current?.[key],
        });
    }
    const apiRes = await dispatch(
      apiRequest(endpoints?.changeUserRoles, apiData)
    );
    if (apiRes?.success) {
      let tempRowData = JSON.parse(JSON.stringify(rowData));
      apiRes?.data?.users?.map((item) => {
        tempRowData[item.id] = item?.role;
      });
      setRowData(tempRowData);
      orignalRowData.current = tempRowData;
      setChanged(false);
    }
  };

  useEffect(() => {
    const tempRowData = {};
    let selectableRowsTemp = 0;
    data?.table?.data?.map((item) => {
      tempRowData[item.id] = item?.role?.default?.value;
      if (
        item?.selection_support ||
        !Object.keys(item).includes("selection_support")
      )
        selectableRowsTemp++;
    });
    setAllSelectedTableData({
      ...data,
      table: {
        ...data?.table,
        data: data.table.data.map((item) => {
          if (item.delete) {
            return {
              ...item,
              delete: { ...item?.delete, hidden: true },
            };
          }
          return item;
        }),
      },
    });
    setSelectableRows(selectableRowsTemp);
    setRowData(tempRowData);
    setChanged(false);
    setBlockerAlertData({ items: data?.blocker_popup, props: ["blocker"] });
    orignalRowData.current = tempRowData;
  }, [data]);

  useEffect(() => {
    if (alertProps?.slug === POPUP_SLUGS?.REMOVE_USER)
      deleteUser(alertProps?.id);
  }, [isAlertOk]);

  return (
    <>
      {showAddUserModal && (
        <div className="manage-settings__add-user-modal-bg">
          <ModalV2 slug={"standard"}>
            <AddUserPopup
              data={data?.add_user_popup}
              setShowAddUserModal={setShowAddUserModal}
              updateData={updateData}
            />
          </ModalV2>
        </div>
      )}
      <div className="manage-settings">
        <div
          className={`manage-settings__header ${
            rowSelectionState && Object.keys(rowSelectionState).length > 0
              ? "manage-settings__header--underline"
              : ""
          }`}
        >
          <div className="manage-settings__header__tab-container">
            {data?.header?.tabs?.map((item, idx) => {
              return (
                <p
                  key={idx}
                  className={`manage-settings__header__tab ${
                    !item?.disabled
                      ? "manage-settings__header__tab--active"
                      : "manage-settings__header__tab--inactive"
                  }`}
                  onClick={(e) => {
                    navigate(item?.deeplink);
                  }}
                >
                  {item?.text}
                </p>
              );
            })}
          </div>
          {rowSelectionState && Object.keys(rowSelectionState).length === 0 && (
            <div className="manage-settings__header-add">
              {data?.header?.add_member_button && (
                <Button
                  buttonLabel={data?.header?.add_member_button?.text}
                  buttonType={data?.header?.add_member_button?.sub_type}
                  icon={data?.header?.add_member_button?.icon}
                  hoverIcon={data?.header?.add_member_button?.hover_icon}
                  iconAlignment={
                    data?.header?.add_member_button?.icon_alignment
                  }
                  onClick={() => {
                    setShowAddUserModal(true);
                  }}
                  iconExtraClass="home__add-sb--extra"
                />
              )}
              {changed &&
                data?.header?.buttons?.map((item, idx) => {
                  return (
                    <Button
                      key={idx}
                      icon={item?.icon}
                      buttonType={item?.sub_type}
                      buttonLabel={item?.text}
                      iconAlignment={item?.icon_alignment}
                      onClick={(e) => {
                        changeUserRoles();
                      }}
                    />
                  );
                })}
            </div>
          )}
        </div>
        {rowSelectionState && Object.keys(rowSelectionState).length > 0 && (
          <div className="manage-settings__delete-header">
            <div className="manage-settings__delete-header-item-v2">
              <p className="manage-settings__header__tab-selected-text">
                <span>{Object.keys(rowSelectionState).length} </span>
                of {data?.table?.data?.length}
                {data?.header?.delete_header?.select_text}
              </p>
            </div>
            <div className="manage-settings__delete-header-partition"></div>
            <div
              className="manage-settings__delete-header-item"
              onClick={(e) => {
                setRowSelectionState({});
                setAllSelected(false);
              }}
            >
              {/* <img
                src={data?.header?.delete_header?.deselect_icon}
                className="manage-settings__delete-header-image manage-settings__delete-header-image--cross"
              />
              <p className="manage-settings__delete-header-text">
                {data?.header?.delete_header?.deselect_text}
              </p> */}
              {/* <BlunoTooltip useChildren={true} text="Clear all"> */}
              <img
                src="https://static.bluno.in/miscl/clear-filter.png"
                className="manage-settings__delete-header-image manage-settings__delete-header-image--clear"
                alt=""
              />
              <p className="manage-settings__delete-header-text">
                Clear filter
              </p>
              {/* </BlunoTooltip> */}
            </div>

            <div
              className="manage-settings__delete-header-item"
              onClick={(e) => {
                onDelete(Object.keys(rowSelectionState));
              }}
            >
              <img
                src={data?.header?.delete_header?.delete_icon}
                className="manage-settings__delete-header-image manage-settings__delete-header-image--dustbin"
              />
              <p className="manage-settings__delete-header-text">
                {data?.header?.delete_header?.delete_text}
              </p>
            </div>
          </div>
        )}
        <div className="manage-settings__body">
          <BlunoTable
            data={allSelected ? allSelectedTableData?.table : data?.table}
            OptionsComponent={ManageUsersOptionsComponent}
            onTableRowSelection={onTableRowSelection}
            rowSelectionState={rowSelectionState}
            showHoverOnSelect={true}
            onAllTableRowSelection={onAllTableRowSelection}
            onSelectChange={onRoleChange}
            onImageFunction={onDelete}
            allSelected={allSelected}
            onOptionsFunction={handleOptionsClick}
            dataHeight="100%"
            showHoverBlueRow={false}
          />
        </div>
      </div>
    </>
  );
}
