import React from "react";

const HealthCheckCard = ({ data }) => {
  return (
    <div className="g-settings__cont">
      <p className="g-settings__title">
        {data?.title} <span>{data?.last_refresh}</span>
      </p>
      <div className="h-settings__card">
        <span
          className={`h-settings__status h-settings__status--${data?.info?.status}`}
        />
        <p className="h-settings__info">{data?.info?.text}</p>
      </div>
      <div className="h-settings__list">
        {data?.status_list?.map((item) => {
          return (
            <div className="h-settings__card">
              <div>
                <div className="h-settings__left">
                  <img
                    className="h-settings__bank-icon"
                    src={item?.bank_icon}
                    alt={item?.bank_name}
                  />
                  <p className="h-settings__bank-name">{item?.bank_name}</p>
                </div>
                <span
                  className={`h-settings__status h-settings__status--${item?.status}`}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HealthCheckCard;
