//react imports
import React, { useState } from "react";

//in app imports
import BlunoTableDropdown from "../BlunoTableDropdown";
import BlunoTableOptions from "../BlunoTableOptions";
import BlunoTooltip from "../BlunoTooltip";
import Button from "../Button";

//redux imports
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";
import endpoints from "../../api/endpoints";
import BulkOptionButtonPopup from "../bulkInfo/BulkOptionButtonPopup";

export default function ActionColumn({
  data,
  row,
  OptionsComponent,
  onOptionsFunction,
  tableRef,
  tableHeadRef,
  showHoverBlueRow,
  specialColumnFunction,
  highlightRow,
  unHighlightRow,
}) {
  const dispatch = useDispatch();
  const [showOptionComponent, setShowOptionComponent] = useState(false);
  const [modalDataByOptionButton, setModalDataByOptionButton] = useState({});

  const handleUserPostBulkAction = async (theId, theAction) => {
    switch (theAction?.slug) {
      case "maker_remind":
      case "maker_withdraw":
      case "checker_accept":
      case "checker_reject":
        setShowOptionComponent(true);
        setModalDataByOptionButton(data?.modal_data?.[theAction?.slug]);
        highlightRow(row?.id);
        break;
    }
  };

  return (
    <div
      className={`document-management__column ${
        data?.icons ? "" : "document-management__column--buttons"
      }`}
    >
      {data?.icons ? (
        data?.icons?.map((item, idx) => {
          return (
            <BlunoTooltip
              useChildren={true}
              text={item?.hover_text}
              alignment="center"
              position="fixed"
              left="4px"
            >
              <img
                src={item?.icon}
                className={`document-management__column-icon ${
                  item?.disabled
                    ? "document-management__column-icon--disabled"
                    : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!item?.disabled)
                    specialColumnFunction(
                      item?.slug,
                      row?.id,
                      item?.doc_id,
                      item?.sub_text,
                      data?.modal_data
                    );
                }}
              />
            </BlunoTooltip>
          );
        })
      ) : (
        <div className="bulk-info__table-button-container">
          {data?.buttons?.map((item, idx) => {
            return (
              <>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUserPostBulkAction(row?.id, item);
                  }}
                  buttonSize="xs"
                  buttonLabel={item?.text}
                  extraClass={`s-button--${item?.sub_type} bulk-info__table-button`}
                  fontSize="11px"
                />
              </>
            );
          })}
        </div>
      )}
      <BlunoTableOptions
        row={row}
        OptionsComponent={
          showOptionComponent ? BulkOptionButtonPopup : OptionsComponent
        }
        onOptionsFunction={onOptionsFunction}
        header={{ slug: "options" }}
        tableRef={tableRef}
        tableHeadRef={tableHeadRef}
        showHoverBlueRow={showHoverBlueRow}
        highlightRow={highlightRow}
        unHighlightRow={unHighlightRow}
        showOptionComponent={showOptionComponent}
        setShowOptionComponent={setShowOptionComponent}
        modalDataByOptionButton={modalDataByOptionButton}
        setModalDataByOptionButton={setModalDataByOptionButton}
      />
    </div>
  );
}
