// react imports
import React, { useEffect, useState } from "react";

const NestedTable = ({ data, headers, getComponent }) => {
  const [rowData, setRowData] = useState();

  useEffect(() => {
    setRowData(data);
  }, [data]);

  return (
    <>
      {rowData?.map((row, rowIndex) => (
        <>
          <tr
            key={`nested-tr-${rowIndex}`}
            className={`s-table__data-row s-table__data-row--noHover ${
              rowIndex !== rowData?.length - 1
                ? "s-table__data-row--noBorder"
                : ""
            }`}
            id={`row_id_${row?.id}`}
          >
            {headers?.map((header, index) => {
              return (
                <td key={`td-${header?.slug}-${index}`}>
                  <div
                    className={`s-table__table--align-${
                      row?.[header?.slug]?.alignment
                    } ${row?.is_footer ? "s-table__table--footer" : ""} ${
                      index === 0 ? `s-table__table-flexcell` : ""
                    }`}
                  >
                    {getComponent(row, header, rowIndex)}
                  </div>
                </td>
              );
            })}
          </tr>
        </>
      ))}
    </>
  );
};

export default NestedTable;
