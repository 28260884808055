export const DOCUMENT_MANAGEMENT_TYPE = {
  sb: "shipping_bill",
  ebrc: "ebrc",
  invoice: "invoice",
};

export const PARAM_TYPE = {
  shipping_bill: "sb",
  ebrc: "ebrc",
  invoice: "invoice",
};
