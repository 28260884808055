//react imports
import React from "react";

//redux imports
import { useDispatch, useSelector } from "react-redux";
import {
  updateNotificationObject,
  updateShowBrForm,
} from "../redux/common/slice";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";

//inapp imports
import Button from "./Button";

const NotificationBanner = ({ data }) => {
  const dispatch = useDispatch();
  const currentStage = useSelector((state) => state.decisionTask.currentStage);

  const handleUploadLater = async () => {
    const apiRes = await dispatch(
      apiRequest(
        endpoints.submitUploadLater,
        {},
        `?application_id=${currentStage?.applicationId}`
      )
    );
    if (apiRes?.success && apiRes?.data) {
      const notificationObjectTemp1 = {
        showNotificationBanner: false,
      };
      dispatch(updateNotificationObject(notificationObjectTemp1));
    }
  };

  const handleButtonClick = (theSlug) => {
    switch (theSlug) {
      case "upload_later":
        handleUploadLater();
        break;
      case "upload_document":
        const notificationObjectTemp2 = {
          showNotificationBanner: false,
        };
        dispatch(updateShowBrForm(true));
        dispatch(updateNotificationObject(notificationObjectTemp2));
        break;
    }
  };

  const renderTemplate = (theData) => {
    switch (theData?.template_type) {
      case "basic":
        return (
          <div
            className={`s-notification-banner__basic ${"s-notification-banner__basic--animate"}`}
          >
            <p
              className={`s-notification-banner__${theData?.template_type}--title`}
            >
              {theData?.template?.title}
            </p>
            <div
              className={`s-notification-banner__${theData?.template_type}--buttons`}
            >
              {theData?.template?.buttons?.map((btn) => {
                return (
                  <Button
                    buttonType={btn?.sub_type}
                    buttonLabel={btn?.text}
                    extraClass={
                      btn?.slug === "upload_later" &&
                      `s-notification-banner__${theData?.template_type}--transparent`
                    }
                    onClick={() => {
                      handleButtonClick(btn?.slug);
                    }}
                  />
                );
              })}
            </div>
          </div>
        );
    }
  };

  return <div className="s-notification-banner">{renderTemplate(data)}</div>;
};

export default NotificationBanner;
