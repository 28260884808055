// react imports
import React, { useEffect, useRef, useState } from "react";

// redux imports
import endpoints from "../../api/endpoints";
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/toast/slice";

// third-party imports

// in-app imports
import Button from "../Button";
import { INPUT_TYPE } from "../../utils/constants/CommonConstants";
import TextInput from "../TextInput";
import MuiTextInput from "../blunoInputs/MuiTextInputs";
import { DOCUMENT_ACTION } from "../../utils/constants/DocumentConstants";
import { downloadDocumentUtility } from "../../utils/downloadDocument";
import {
  DOCUMENT_TYPE,
  UPLOAD_DOCUMENT_FLOW,
} from "../../utils/constants/TableConstants";
import BlunoTooltip from "../BlunoTooltip";
import Dropdown from "../Dropdown";
import BlunoIcon from "../BlunoIcon";

const BankChargesModal = ({
  data,
  setShowModal,
  setModalData,
  refreshPageData,
  irmId,
}) => {
  // defined to access react based APIs
  const dispatch = useDispatch();

  // local states
  const [currentViewState, setCurrentViewState] = useState("edit_only");
  const [inputvalues, setInputValues] = useState({});
  const [chargesMap, setChargesMap] = useState({});
  const [documentState, setDocumentState] = useState({});
  const [chargesList, setChargesList] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [chargeErrorMessage, setChargeErrorMessage] = useState({});
  const formData = new FormData();

  // refs
  const fileInputRef = useRef([]);
  const lastAddedChargeRef = useRef(null);

  // constants
  const ATTACHED_DOC_ICON = "https://static.bluno.in/miscl/attached-doc.png";
  const DUSTBIN_ICON = "https://static.bluno.in/miscl/dustbin-icon.png";

  const onPopupClose = (refresh = false) => {
    setShowModal(false);
    setModalData({});
    if (refresh) refreshPageData(true);
  };

  const onActionClick = (action) => {
    switch (action?.slug) {
      case "cancel":
        onPopupClose();
        break;
      case "submit":
        submitOtherChargesInfo();
        break;
    }
  };

  const submitOtherChargesInfo = async () => {
    const dataRes = await dispatch(
      apiRequest(
        endpoints.submitOtherChargesInfo,
        {
          charges_map: chargesMap,
          currency: data?.currency,
        },
        `/${irmId}/other-charges/submit`
      )
    );
    if (dataRes?.success && dataRes?.data) {
      onPopupClose(true);
    } else {
      if (dataRes?.data?.toast) {
        dispatch(
          showToast({
            ...dataRes?.data?.toast,
            data: {
              ...dataRes?.data?.toast?.data,
              center: {
                ...dataRes?.data?.toast?.data?.center,
                text:
                  dataRes?.data?.toast?.metadata?.amount &&
                  dataRes?.data?.toast?.metadata?.currency
                    ? dataRes?.data?.toast?.data?.center?.text +
                      " " +
                      dataRes?.data?.toast?.metadata?.amount +
                      " " +
                      dataRes?.data?.toast?.metadata?.currency
                    : dataRes?.data?.toast?.data?.center?.text,
              },
            },
          })
        );
      }
    }
  };

  const uploadSingleFile = (selectedFiles, doc_type, index) => {
    let metadata = {
      flow: UPLOAD_DOCUMENT_FLOW?.IRM_DOC_CHANGES,
    };
    formData.append("file", selectedFiles[0]);
    formData.append("doc_type", doc_type);
    formData.append("metadata", JSON.stringify(metadata));

    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );

      if (dataRes?.success && dataRes?.data) {
        const uploadedDoc =
          dataRes?.data?.uploaded_document_info?.[0]?.uploaded_documents[0];

        setDocumentState((prevState) => ({
          ...prevState,
          [index]: {
            docInfo: uploadedDoc,
            isUploadEnabled: false,
            docId: dataRes?.data?.document_id,
          },
        }));

        setChargesMap((prev) => {
          const chargeType = chargesList[index]?.charge?.slug;
          return {
            ...prev,
            [chargeType]: {
              ...prev[chargeType],
              document_id: dataRes?.data?.document_id,
            },
          };
        });
      } else {
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    };

    uploadApiCall();
  };

  const onDocAction = (actionBtn, documentId, fileName, index) => {
    let metadata = {
      flow: UPLOAD_DOCUMENT_FLOW?.IRM_DOC_CHANGES,
    };

    const docAction = async (actionBtn, documentId, fileName, index) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          action: actionBtn?.action,
          document_id: documentId,
          document_type: DOCUMENT_TYPE?.miscellaneous,
          metadata: JSON.stringify(metadata),
        })
      );

      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn?.action === DOCUMENT_ACTION?.VIEW) {
            window.open(dataRes?.data?.document_link, "_blank");
          } else if (actionBtn?.action === DOCUMENT_ACTION?.DOWNLOAD) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        }
        if (actionBtn?.action === "detach") {
          setDocumentState((prev) => ({
            ...prev,
            [index]: {
              docInfo: null,
              isUploadEnabled: true,
              docId: null,
            },
          }));

          setChargesMap((prev) => {
            const chargeType = chargesList[index]?.charge?.slug;
            if (!chargeType) return prev;

            const { document_id, ...rest } = prev[chargeType] || {};

            return {
              ...prev,
              [chargeType]: rest,
            };
          });
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    };

    docAction(actionBtn, documentId, fileName, index);
  };

  // const formatInputValue = (value) => {
  //   if (value === "") return value;

  //   value = value.replace(/^0+/, "");

  //   if (value.startsWith(".")) {
  //     value = "0" + value;
  //   }

  //   value = value.replace(/(\.\d*[1-9])0+$/, "$1");

  //   value = value.replace(/\.$/, "");

  //   return value;
  // };

  const formatInputValue = (value) => {
    if (value === "") return value;

    value = value.replace(/^0+/, "");

    if (value.startsWith(".")) {
      value = "0" + value;
    }

    value = value.replace(/(\.\d*?[1-9])0+$/, "$1");

    value = value.replace(/\.$/, "");

    if (/^\d+\.(0+)$/.test(value)) {
      value = value.replace(/\.\d+$/, "");
    }

    return value;
  };

  const handleInputChange = (
    value,
    slug,
    type,
    index,
    eventType = "change"
  ) => {
    const decimalRegex = /^\d*(\.\d{0,2})?$/;

    if (type === "charge" && value !== "") {
      if (
        data?.max_allowed_charge &&
        value > parseFloat(data?.max_allowed_charge.replace(",", ""))
      ) {
        setChargeErrorMessage((prev) => ({
          ...prev,
          [index]: `Amount cannot be greater than ${data?.max_allowed_charge}`,
        }));
        return;
      } else if (!decimalRegex.test(value)) {
        setChargeErrorMessage((prev) => ({
          ...prev,
          [index]: "Amount allowed up to 2 decimal places",
        }));
        return;
      }
    }

    setChargeErrorMessage((prev) => {
      const updatedErrors = { ...prev };
      delete updatedErrors[index];
      return updatedErrors;
    });

    // Format the input value
    let formattedValue = value;
    if (eventType === "blur") {
      formattedValue = formatInputValue(value);
    }

    setInputValues((prev) => ({ ...prev, [slug]: formattedValue }));

    setChargesMap((prev) => {
      const chargeType = chargesList[index]?.charge?.slug;
      if (!chargeType) return prev;

      return {
        ...prev,
        [chargeType]: {
          ...prev[chargeType],
          [type]: formattedValue,
        },
      };
    });
  };

  // const handleInputChange = (value, slug, type, index) => {
  //   const decimalRegex = /^\d*(\.\d{0,2})?$/;

  //   if (type === "charge" && value !== "" && !decimalRegex.test(value)) {
  //     setChargeErrorMessage((prev) => ({
  //       ...prev,
  //       [index]: "Amount allowed up to 2 decimal places",
  //     }));
  //     return;
  //   }

  //   setChargeErrorMessage((prev) => {
  //     const updatedErrors = { ...prev };
  //     delete updatedErrors[index];
  //     return updatedErrors;
  //   });

  //   setInputValues((prev) => ({ ...prev, [slug]: value }));

  //   setChargesMap((prev) => {
  //     const chargeType = chargesList[index]?.charge?.slug;
  //     if (!chargeType) return prev;

  //     return {
  //       ...prev,
  //       [chargeType]: {
  //         ...prev[chargeType],
  //         [type]: value,
  //       },
  //     };
  //   });
  // };

  const handleFileInputChange = (e, index) => {
    if (e.target.files.length > 0) {
      const selectedFiles = e.target.files;
      if (selectedFiles?.[0]?.size > 5 * Math.pow(2, 20)) {
        dispatch(
          showToast({
            message:
              "The file size is too large. Please upload a smaller file.",
            type: "error",
          })
        );
        return;
      }

      uploadSingleFile(selectedFiles, DOCUMENT_TYPE?.miscellaneous, index);
    }
  };

  const handleDropdownChange = (value) => {
    const selectedOption = dropdownOptions?.find(
      (option) => option?.value === value
    );
    if (selectedOption) {
      setDropdownOptions((prev) =>
        prev?.filter((option) => option.value !== value)
      );
      setDocumentState((prev) => {
        return {
          ...prev,
          [Object.keys(prev).length]: {
            docInfo: null,
            isUploadEnabled: true,
            docId: null,
          },
        };
      });
      setChargesList((prev) => [
        ...prev,
        {
          label: selectedOption?.label,
          charge: {
            type: "text_box",
            slug: selectedOption?.value,
            title: selectedOption?.label,
            sub_type: "number",
            default_value: "0.00",
          },
          reason_details: {
            type: "text_area_with_attachment",
            reason: {
              slug: `${selectedOption?.value}_reason`,
              title: "Add remark if any or attach relevant document",
              placeholder: "Add remark if any or attach relevant document",
              sub_type: "text",
              icon: ATTACHED_DOC_ICON,
            },
            delete_icon: DUSTBIN_ICON,
            tooltip_text: "Remove",
          },
        },
      ]);

      setTimeout(() => {
        lastAddedChargeRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 0);
    }
  };

  const handleRemoveCharge = (index) => {
    const removedCharge = chargesList[index];

    // add removed charge to dropdown options
    if (index > 1) {
      setChargesList((prev) => prev.filter((_, i) => i !== index));
      setDropdownOptions((prev) => [
        ...prev,
        {
          label: removedCharge?.charge?.title,
          value: removedCharge?.charge?.slug,
        },
      ]);
    }

    // remove charge values from input values, docState and chargesMap
    setInputValues((prev) => {
      const chargeSlug = removedCharge?.charge?.slug;
      const chargeReasonSlug = removedCharge?.reason_details?.reason?.slug;
      const { [chargeSlug]: _, [chargeReasonSlug]: __, ...rest } = prev;
      return rest;
    });
    setDocumentState((prev) => {
      const { [index]: _, ...rest } = prev;
      return rest;
    });
    setChargesMap((prev) => {
      const chargeType = removedCharge?.charge?.slug;
      const { [chargeType]: _, ...rest } = prev;
      return rest;
    });
  };

  useEffect(() => {
    setCurrentViewState(data?.current_view);

    if (data?.charges_data?.edit_only) {
      data?.charges_data?.edit_only?.data?.forEach((item) => {
        setInputValues((prev) => ({
          ...prev,
          [item?.charge?.slug]: item?.charge?.default_value.replace(",", ""),
          [item?.reason_details?.reason?.slug]:
            item?.reason_details?.reason?.default_value,
        }));

        setChargesMap((prev) => {
          const chargeType = item?.charge?.slug;
          const updatedCharge = { ...prev[chargeType] } || {};

          if (item?.charge?.default_value) {
            updatedCharge.charge = `${item?.charge?.default_value}`;
          }
          if (item?.reason_details?.reason?.default_value) {
            updatedCharge.reason = item?.reason_details?.reason?.default_value;
          }
          if (item?.reason_details?.uploaded_document) {
            updatedCharge.document_id =
              item?.reason_details?.uploaded_document?.id;
          }

          return {
            ...prev,
            [chargeType]: updatedCharge,
          };
        });
      });
      setChargesList(data?.charges_data?.edit_only?.data);
      setDropdownOptions(
        data?.charges_data?.edit_only?.more_charges_dropdown?.options
      );
    }
    initializeDocumentState();
  }, [data]);

  const getInputElement = (item, index) => {
    switch (item?.type) {
      case INPUT_TYPE?.TEXT_BOX:
        return (
          <div>
            <TextInput
              type={item?.sub_type}
              value={inputvalues?.[item?.slug]}
              placeholder=""
              height="26px"
              minWidth="84px"
              width="84px"
              inputParWidth="84px"
              paddingLeft="8px"
              onChange={(e) =>
                handleInputChange(
                  e.target.value,
                  item?.slug,
                  "charge",
                  index,
                  "change"
                )
              }
              handleBlurAction={(e) =>
                handleInputChange(
                  e.target.value,
                  item?.slug,
                  "charge",
                  index,
                  "blur"
                )
              }
              errorMessage={chargeErrorMessage?.[index]}
              errorMessageExtraClass="bank-charges__edit-input-error"
              // errorMsgPostionType="absolute"
              // errorMsgBottomPosition="-17px"
            />
            <p>{data?.currency}</p>
          </div>
        );
      case INPUT_TYPE?.TEXT_AREA_WITH_DOC:
        return (
          <div className="bank-charges__edit-input-global">
            <div className="bank-charges__edit-input">
              <div className="bank-charges__edit-input-area">
                <MuiTextInput
                  multiline={true}
                  height="90px"
                  baseInputHeight="80px"
                  minWidth={item?.delete_icon ? "280px" : "300px"}
                  rows="4"
                  maxLength={item?.reason?.limits?.max}
                  placeholder={item?.reason?.placeholder}
                  key={index}
                  value={inputvalues?.[item?.reason?.slug]}
                  onChange={(e) => {
                    handleInputChange(
                      e.target.value,
                      item?.reason?.slug,
                      "reason",
                      index
                    );
                  }}
                />
                <input
                  id={documentState?.[index]?.docInfo?.id}
                  type="file"
                  ref={(element) => (fileInputRef.current[index] = element)}
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  style={{ display: "none" }}
                  onChange={(e) => handleFileInputChange(e, index)}
                />
                {documentState?.[index]?.isUploadEnabled && (
                  // <img
                  //   src={item?.reason?.icon}
                  //   alt=""
                  //   className="bank-charges__edit-input-attach"
                  //   onClick={() => fileInputRef?.current[index]?.click()}
                  // />
                  <BlunoIcon
                    src={item?.reason?.icon}
                    alt="Attach"
                    iconWidth="12px"
                    iconHeight="auto"
                    onClick={() => fileInputRef?.current[index]?.click()}
                    extraClass="bank-charges__edit-input-attach"
                    iconFilterVariant="primary-blue"
                  />
                )}
              </div>
              {item?.delete_icon && (
                <BlunoTooltip
                  useChildren={true}
                  text={item?.tooltip_text}
                  position="fixed"
                >
                  <img
                    src={item?.delete_icon}
                    className="bank-charges__edit-input-delete"
                    alt=""
                    onClick={() => handleRemoveCharge(index)}
                  />
                </BlunoTooltip>
              )}
            </div>
            <p>PDF file & Zip file supported | Max file size 5MB</p>
          </div>
        );
      case INPUT_TYPE?.DROPDOWN:
        return (
          <div className="bank-charges__edit-select">
            <p className="bank-charges__edit-select-text">{item?.title}</p>
            <div className="bank-charges__edit-select-dropdown">
              <Dropdown
                options={dropdownOptions}
                height="40px"
                minWidth="180px"
                menuHeight="300px"
                fontSize="13px"
                onSelectChange={(e) => handleDropdownChange(e.value, index)}
                placeholder={item?.placeholder}
                placeholderFontSize="13px"
                placeholderFontWeight="400"
                fancyPlaceholder={true}
                resetValueOnSelect={true}
                isDisabled={dropdownOptions.length > 0 ? false : true}
              />
            </div>
          </div>
        );
    }
  };

  const initializeDocumentState = () => {
    const initialState = {};
    data?.charges_data?.edit_only?.data?.forEach((item, index) => {
      const uploadedDocument = item?.reason_details?.uploaded_document;
      initialState[index] = {
        docInfo: uploadedDocument || null,
        isUploadEnabled: !uploadedDocument,
        docId: uploadedDocument?.id || null,
      };
    });
    setDocumentState(initialState);
  };

  return (
    <>
      <div className="bank-charges">
        <div className="bank-charges__header">
          <h2 className="bank-charges__title">{data?.title}</h2>
          <img src={data?.close_icon} alt="" onClick={() => onPopupClose()} />
        </div>
        <div className="bank-charges__content">
          <div className="bank-charges__info">
            <p className="bank-charges__info-text">{data?.text}</p>
            {data?.edit_icon && currentViewState === "view_only" && (
              <img
                src={data?.edit_icon}
                alt=""
                onClick={() => setCurrentViewState("edit_only")}
              />
            )}
          </div>
          {/* {currentViewState === "view_only" ? (
            <div className="bank-charges__view">
              <div className="bank-charges__view-list">
                <div className="bank-charges__view-headers">
                  <p className="bank-charges__view-header-1">
                    {data?.charges_data?.view_only?.headers[0]}
                  </p>
                  <p className="bank-charges__view-header-2">
                    {data?.charges_data?.view_only?.headers[1]}
                  </p>
                  <p className="bank-charges__view-header-3">
                    {data?.charges_data?.view_only?.headers[2]}
                  </p>
                </div>
                {data?.charges_data?.view_only?.data?.map((item, idx) => {
                  const prefix = String.fromCharCode(97 + idx);
                  return (
                    <div className="bank-charges__view-item">
                      <p className="bank-charges__view-label">{`${prefix}. ${item?.label}`}</p>
                      <p
                        className="bank-charges__view-charge"
                        dangerouslySetInnerHTML={sanitizeHtml(item?.charge)}
                      />
                      <div className="bank-charges__view-reason">
                        <p className="bank-charges__view-reason-text">
                          {item?.reason_details?.reason}
                        </p>
                        <div className="bank-charges__view-reason-doc">
                          <p
                            className="bank-charges__view-reason-docname"
                            onClick={() =>
                              window.open(
                                item?.reason_details?.deeplink,
                                "_blank"
                              )
                            }
                          >
                            {item?.reason_details?.document_name}
                          </p>
                          <img src={item?.reason_details?.icon} alt="" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : ( */}
          <div className="bank-charges__edit">
            <div className="bank-charges__edit-list">
              {chargesList?.map((item, idx) => {
                const prefix = String.fromCharCode(97 + idx);
                return (
                  <div
                    className="bank-charges__edit-item"
                    ref={
                      idx === chargesList.length - 1 ? lastAddedChargeRef : null
                    }
                  >
                    <p className="bank-charges__edit-name">{`${prefix}. ${item?.label}`}</p>
                    <div className="bank-charges__edit-charge">
                      {getInputElement(item?.charge, idx)}
                      {/* <span>{item?.charge?.fixed_text}</span> */}
                    </div>
                    <div className="bank-charges__edit-reason">
                      {getInputElement(item?.reason_details, idx)}
                      {documentState?.[idx]?.docInfo &&
                        !documentState?.[idx]?.isUploadEnabled && (
                          <div className="bank-charges__edit-doc">
                            <div className="bank-charges__edit-doc-info">
                              <img
                                src={documentState?.[idx]?.docInfo?.icon}
                                alt=""
                              />
                              <p
                                className={`bank-charges__edit-doc-name ${
                                  documentState?.[idx]?.docInfo?.deeplink
                                    ? "s-uploaded-file-v2__file-name--deeplink"
                                    : ""
                                } `}
                                onClick={() => {
                                  documentState?.[idx]?.docInfo?.deeplink &&
                                    window.open(
                                      documentState?.[idx]?.docInfo?.deeplink,
                                      "_blank"
                                    );
                                }}
                              >
                                {documentState?.[idx]?.docInfo?.name}
                              </p>
                            </div>
                            <div className="bank-charges__edit-doc-actions">
                              <img
                                src={documentState?.[idx]?.docInfo?.info_icon}
                                alt=""
                              />
                              {documentState?.[idx]?.docInfo?.actions?.map(
                                (action) => {
                                  return (
                                    action?.action === "detach" && (
                                      <BlunoTooltip
                                        useChildren={true}
                                        text={action?.tooltip_text}
                                        position="fixed"
                                      >
                                        <img
                                          style={{ marginTop: "3px" }}
                                          src={action?.icon}
                                          alt={action?.action}
                                          className={`s-uploaded-file-v2__action`}
                                          onClick={() =>
                                            onDocAction(
                                              action,
                                              documentState?.[idx]?.docInfo?.id,
                                              null,
                                              idx
                                            )
                                          }
                                        />
                                      </BlunoTooltip>
                                    )
                                  );
                                }
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
            {data?.charges_data?.edit_only?.more_charges_dropdown &&
              getInputElement(
                data?.charges_data?.edit_only?.more_charges_dropdown
              )}
          </div>
          {/* )} */}
        </div>
        {/* {data?.buttons && currentViewState === "edit_only" && ( */}
        <div className="m-bulk-action__buttons">
          {data?.buttons?.map((item, idx) => {
            return (
              <Button
                key={idx}
                icon={item?.icon}
                buttonType={item?.sub_type}
                buttonLabel={item?.text}
                disabled={item?.disabled}
                iconAlignment={item?.icon_alignment}
                onClick={() => onActionClick(item)}
              />
            );
          })}
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default BankChargesModal;
