import { InputError } from "../app/constants/InputError";

export const validatePAN = (value) => {
  const regex = /^[A-Z]{3}[PCHFTABLJGE][A-Z](?!0000)[0-9]{4}[A-Z]$/;
  return regex.test(value);
};

export const validateIEC = (value) => {
  const regex = /^[0-9a-zA-Z]*$/;
  return regex.test(value);
};

export const validateEMAIL = (value) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(value);
};

export const validatIFSC = (value) => {
  return /^[A-Z]{4}0[A-Z0-9]{6}$/.test(value);
};

export const validateSmallAlphabetNumber = (value, isSpaceAllowed = false) => {
  return /[a-z0-9]/.test(value) || (isSpaceAllowed ? value === " " : false);
};

export const validateCapitalAlphabetNumber = (
  value,
  isSpaceAllowed = false
) => {
  return /[A-Z0-9]/.test(value) || (isSpaceAllowed ? value === " " : false);
};

export const validateAlphabetNumber = (value, isSpaceAllowed = false) => {
  return /[a-zA-Z0-9]/.test(value) || (isSpaceAllowed ? value === " " : false);
};

export const validateAlphaValue = (value, isSpaceAllowed = false) => {
  if (isSpaceAllowed) {
    return /^[a-zA-Z ]*$/.test(value);
  } else return /^[a-zA-Z]*$/.test(value);
};

export const validateAlphaNumericValue = (value) => {
  return /^[a-zA-Z0-9]*$/.test(value);
};

export const validateCompanyName = (value) => {
  return /^[a-zA-Z0-9`@#\$%&\*\(\)\-_=\+\[\{\}\]\|\\;:"',<\.>\/\?~!\s]*$/.test(
    value
  );
};

export const validateAlphabet = (value, isSpaceAllowed = false) => {
  return /[a-zA-Z]/.test(value) || (isSpaceAllowed ? value === " " : false);
};

export const validateNumber = (value, isSpaceAllowed = false) => {
  return /[0-9]/.test(value) || (isSpaceAllowed ? value === " " : false);
};

export const validateNumeric = (value, isSpaceAllowed = false) => {
  return /^[0-9]*$/.test(value) || (isSpaceAllowed ? value === " " : false);
};

export const validateAnyExceptWhiteSpace = (value) => {
  return /^\S*$/.test(value);
};

export const validateDecimalNumber = (value, isSpaceAllowed = false) => {
  return (
    /^[0-9]+\.?[0-9]{0,2}$/.test(value) ||
    (isSpaceAllowed ? value === " " : false)
  );
};

export const validatePassword = (value, showCombinedMessage = false) => {
  if (value?.length < 8) {
    return {
      success: false,
      message: !showCombinedMessage
        ? InputError?.ShortPassword?.message
        : InputError?.AllInOnePasswordError?.message,
    };
  }

  if (/\s/.test(value)) {
    return {
      success: false,
      message: !showCombinedMessage
        ? InputError?.PasswordContainsSpace?.message
        : InputError?.AllInOnePasswordError?.message,
    };
  }

  if (!/[A-Z]/.test(value)) {
    return {
      success: false,
      message: !showCombinedMessage
        ? InputError?.PasswordUpperCase?.message
        : InputError?.AllInOnePasswordError?.message,
    };
  }

  if (!/[a-z]/.test(value)) {
    return {
      success: false,
      message: !showCombinedMessage
        ? InputError?.PasswordLowerCase?.message
        : InputError?.AllInOnePasswordError?.message,
    };
  }

  if (!/[0-9]/.test(value)) {
    return {
      success: false,
      message: !showCombinedMessage
        ? InputError?.PasswordNumbericValue?.message
        : InputError?.AllInOnePasswordError?.message,
    };
  }

  if (!/[!@#$%^&*(),.?":{}|<>[\]\\/`~;_+=-]/.test(value)) {
    return {
      success: false,
      message: !showCombinedMessage
        ? InputError?.PasswordSpecialCharacter?.message
        : InputError?.AllInOnePasswordError?.message,
    };
  }

  if (value.length > 20) {
    return {
      success: false,
      message: InputError?.AllInOnePasswordError?.message,
    };
  }

  return { success: true, message: "" };
};

export const generalKeyDownValidation = (
  validateFn,
  value,
  e,
  isSpaceAllowed = false
) => {
  return (
    !validateFn(value, isSpaceAllowed) &&
    value.length === 1 &&
    !e.ctrlKey &&
    !e.metaKey
  );
};

export const checkIfBalanceZero = (value) => {
  return parseFloat(value.replace(/\D/g, ""));
};
