import React, { useEffect, useState } from "react";
import BlunoTooltip from "../BlunoTooltip";
import { formatFileSize } from "../../utils/formatFileSize";

export default function UploadedFile({
  file,
  removeFile,
  onDocAction,
  showDustbin = true,
}) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        {
          if (prev >= 80) {
            clearInterval(interval);
            return prev;
          }
          return prev + 1;
        }
      });
    }, 50);
  }, []);
  useEffect(() => {
    if (file?.id && !file?.error_message) setProgress(100);
  }, [file]);

  const renderActionBtn = (item, idx) => {
    if (!showDustbin && item?.action === "detach") {
      return <></>;
    }

    return (
      <div
        onClick={() => {
          !item?.disabled &&
            onDocAction(item?.action, file?.id, file?.name, file?.metadata);
        }}
      >
        <BlunoTooltip
          useChildren={true}
          text={item?.tooltip_text}
          position="fixed"
        >
          <img
            className={`s-uploaded-file-v2__action ${
              item?.disabled ? "s-uploaded-file-v2__action--disabled" : ""
            }`}
            src={item?.icon}
            key={idx}
          />
        </BlunoTooltip>
      </div>
    );
  };

  return (
    <div className="s-uploaded-file-v2">
      <img className="s-uploaded-file-v2__file-icon" src={file?.icon} />
      <div className="s-uploaded-file-v2-container">
        <div className="s-uploaded-file-v2__file">
          <div className="s-uploaded-file-v2__file-container">
            <p
              className={`s-uploaded-file-v2__file-name ${
                file?.id ? "" : "s-uploaded-file-v2__file-name--failure"
              } ${
                file?.deeplink ? "s-uploaded-file-v2__file-name--deeplink" : ""
              }`}
              onClick={() => {
                file?.deeplink && window.open(file?.deeplink, "_blank");
              }}
            >
              {file?.name?.split(".")[0]}
            </p>
            <p className="s-uploaded-file-v2__file-extension">
              .{file?.name?.split(".").pop()}
            </p>
          </div>
          <div className="s-uploaded-file-v2__file-info">
            <p className="s-uploaded-file-v2__file-size">
              {formatFileSize(file?.size)}
            </p>
            <div className="s-uploaded-file-v2-break" />
            {!file?.error_message && progress < 100 && (
              <p className="s-uploaded-file-v2__progress-info">
                {progress}% Uploaded
              </p>
            )}
          </div>
        </div>
        {!file?.error_message && progress < 100 && (
          <div className="s-uploaded-file-v2__progress-bar">
            <div
              className="s-uploaded-file-v2__progress-bar s-uploaded-file-v2__progress-bar-completed"
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
      </div>
      <div className="s-uploaded-file-v2__action-container">
        {file?.error_message ? (
          <BlunoTooltip
            useChildren={true}
            text={file?.error_message}
            // left={`-${file?.error_message?.length * 2}px`}
            position="fixed"
          >
            <img
              className="s-uploaded-file-v2__action"
              src={file?.info_icon}
            ></img>
          </BlunoTooltip>
        ) : (
          progress >= 100 && (
            <img className="s-uploaded-file-v2__action" src={file?.info_icon} />
          )
        )}
        {file?.actions?.map((item, idx) => {
          return renderActionBtn(item, idx);
        })}
        {(progress < 100 || file?.error_message) && (
          <BlunoTooltip useChildren={true} text="Delete" position="fixed">
            <img
              className="s-uploaded-file-v2__action"
              src="https://static.bluno.in/miscl/dustbin-icon.png"
              onClick={() => {
                removeFile(file?.frontendId);
              }}
            />
          </BlunoTooltip>
        )}
      </div>
    </div>
  );
}
