export const SIDEBAR_ITEMS = {
  header: "bluno",
  icon: "https://static.bluno.in/miscl/logo.png",
  navigation: [
    {
      icon_selected: "https://static.bluno.in/side-bar/selected_home.png",
      icon_unselected: "https://static.bluno.in/side-bar/unselected_home.png",
      display_name: "Home",
      is_selected: true,
      is_disabled: false,
      slug: "home",
      deeplink: "/",
      position: "top",
    },
    {
      icon_selected:
        "https://static.bluno.in/side-bar/selected_export_bills.png",
      icon_unselected:
        "https://static.bluno.in/side-bar/unselected_export_bills.png",
      display_name: "Export Bills",
      is_selected: true,
      is_disabled: false,
      slug: "export_bills",
      deeplink: "/bills/sb",
      position: "top",
    },
    {
      icon_selected: "https://static.bluno.in/side-bar/selected_buyers.png",
      icon_unselected: "https://static.bluno.in/side-bar/unselected_buyers.png",
      display_name: "Buyers",
      is_selected: false,
      is_disabled: false,
      slug: "buyers",
      deeplink: "/buyers",
      position: "top",
    },
    {
      icon_selected: "https://static.bluno.in/side-bar/selected_support.png",
      icon_unselected:
        "https://static.bluno.in/side-bar/unselected_support.png",
      display_name: "Support",
      is_selected: false,
      is_disabled: true,
      slug: "support",
      deeplink: "/support",
      position: "top",
    },
    {
      icon_selected: "https://static.bluno.in/side-bar/selected_pricing.png",
      icon_unselected:
        "https://static.bluno.in/side-bar/unselected_pricing.png",
      display_name: "Pricing",
      is_selected: false,
      is_disabled: true,
      slug: "pricing",
      deeplink: "/pricing",
      position: "bottom",
    },
    {
      icon_selected: "https://static.bluno.in/side-bar/selected_refer.png",
      icon_unselected: "https://static.bluno.in/side-bar/unselected_refer.png",
      display_name: "Refer and Earn",
      is_selected: false,
      is_disabled: true,
      slug: "refer",
      deeplink: "/refer-and-earn",
      position: "bottom",
    },
    {
      icon_selected: "https://static.bluno.in/side-bar/selected_settings.png",
      icon_unselected:
        "https://static.bluno.in/side-bar/unselected_settings.png",
      display_name: "Settings",
      is_selected: false,
      is_disabled: true,
      slug: "settings",
      deeplink: "/settings",
      position: "bottom",
    },
  ],
};
export const SUPPORTED_CURRENCIES = {
  USD: "USD",
  INR: "INR",
};
export const HOME_PAGE_URL = {
  url: "https://bluno.in/",
};

export const DEBOUNCE = {
  delay: 500, // in ms
};
export const REFRESH_IRM_ALERT = {
  data: {
    left: {
      status_icon: "https://static.bluno.in/miscl/blue_tick.png",
    },
    center: {
      text: "Successfully submitted, please check after some time",
    },
    right: {
      close_icon: "https://static.bluno.in/miscl/cross_black.png",
    },
  },
  type: "success",
  duration_in_ms: 4000,
  position: {
    vertical: "top",
    horizontal: "right",
  },
};
