import React, { useEffect } from "react";

const DocManagementMore = ({
  data,
  id,
  onOptionsFunction,
  positionModal,
  checkOutsideClick,
}) => {
  const handleButtonClick = (e, item, id) => {
    if (item?.slug === "reminder_history") {
      onOptionsFunction(e, item, id);
      checkOutsideClick(e, { slug: "cancel" });
    } else {
      onOptionsFunction(e, item, id);
    }
  };
  useEffect(() => {
    positionModal();
  }, []);
  return (
    <div className="document-management__more">
      {data?.map((item, idx) => {
        return (
          <div
            className="document-management__more-item"
            onClick={(e) => handleButtonClick(e, item, id)}
            key={idx}
          >
            {item?.icon && (
              <img
                src={item?.icon}
                className="document-management__more-item-icon"
              />
            )}
            <p className="document-management__more-item-text">{item?.text}</p>
          </div>
        );
      })}
    </div>
  );
};

export default DocManagementMore;
