import React, { useEffect, useRef, useState } from "react";
import OptionDots from "../assets/svg/OptionDots";
import { v4 as uuidv4 } from "uuid";
import BlunoTooltip from "./BlunoTooltip";

export default function BlunoTableOptions({
  row,
  OptionsComponent,
  onOptionsFunction,
  header,
  tableRef,
  tableHeadRef,
  showHoverBlueRow,
  padding,
  highlightRow,
  unHighlightRow,
  showOptionComponent,
  setShowOptionComponent = () => {},
  modalDataByOptionButton,
  setModalDataByOptionButton = () => {},
  specialColumnFunction,
}) {
  const component = useRef();
  const parent = useRef();
  const isScrollable = useRef();

  const [showComponentByThreeDots, setShowComponentByThreeDots] =
    useState(false);
  const [hover, setHover] = useState(false);

  const checkOutsideClick = (event, action) => {
    if (
      (component?.current && !component?.current?.contains(event.target)) ||
      action?.slug === "cancel"
    ) {
      unHighlightRow(row?.id);
      setShowComponentByThreeDots(false);
      setShowOptionComponent(false);
      setModalDataByOptionButton({});
    }
  };

  const calculateAvailableSpace = () => {
    const parentBound = parent?.current?.getBoundingClientRect();
    const componentHeight = component?.current?.offsetHeight || 0;

    const spaceAbove = parentBound?.top;
    const spaceBelow = window.innerHeight - parentBound?.bottom;

    return { spaceAbove, spaceBelow, componentHeight };
  };

  const spaceTop = () => {
    const { spaceAbove, componentHeight } = calculateAvailableSpace();
    return spaceAbove >= componentHeight;
  };

  const spaceBottom = () => {
    const { spaceBelow, componentHeight } = calculateAvailableSpace();
    return spaceBelow >= componentHeight;
  };

  const positionLeft = () => {
    if (!component?.current?.style) return undefined;
    component.current.style.left =
      parent?.current?.getBoundingClientRect()?.x -
      component?.current?.clientWidth +
      "px";
  };

  const positionAbove = () => {
    if (!component?.current?.style) return undefined;
    const parentBound = parent?.current?.getBoundingClientRect();
    const componentHeight = component?.current?.clientHeight;

    component.current.style.top = `${parentBound?.y - componentHeight - 5}px`;
  };
  const positionBelow = () => {
    if (!component?.current?.style) return undefined;
    component.current.style.top =
      parent?.current?.getBoundingClientRect()?.y + 35 + "px";
  };

  const changePosition = () => {
    const { spaceAbove, spaceBelow } = calculateAvailableSpace();

    if (spaceBottom()) {
      positionBelow();
    } else if (spaceTop()) {
      positionAbove();
    } else if (spaceBelow >= spaceAbove) {
      positionBelow();
    } else {
      positionAbove();
    }

    positionLeft();
  };

  const positionModal = () => {
    if (spaceBottom()) positionBelow();
    else if (spaceTop()) positionAbove();
    else positionBelow();
    positionLeft();
  };

  useEffect(() => {
    document.addEventListener("mousedown", checkOutsideClick);
    document.addEventListener("wheel", changePosition);
    isScrollable.current =
      tableRef?.current?.firstElementChild?.clientHeight <
      tableRef?.current?.firstElementChild.scrollHeight;
    return () => {
      document.removeEventListener("mousedown", checkOutsideClick);
      document.removeEventListener("wheel", changePosition);
    };
  }, []);

  useEffect(() => {
    setShowComponentByThreeDots(false);
    unHighlightRow(row?.id);
  }, [row]);

  useEffect(() => {
    setTimeout(() => {
      changePosition();
    }, 10);
  }, [showOptionComponent]);

  return (
    <>
      <div
        className={`s-table__options ${
          row?.[header?.slug]?.disabled ? "s-table__options--disabled" : ""
        }`}
      >
        <BlunoTooltip
          useChildren={true}
          position="fixed"
          text={row?.disabled ? "" : "More"}
          left="12px"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (!row?.disabled && !row?.[header?.slug]?.disabled) {
                setShowComponentByThreeDots(true);
                highlightRow(row?.id);
              }
            }}
            ref={parent}
            className={`s-table__options-dots ${
              row?.disabled
                ? ""
                : showHoverBlueRow
                ? "s-table__options-dots--hover-effect"
                : "s-table__options-dots--hover-effect--blue"
            } ${
              row?.[header?.slug]?.disabled
                ? "s-table__options-dots--disabled"
                : ""
            }`}
            style={{ padding: padding }}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
          >
            <OptionDots
              hoverColor={
                row?.disabled ||
                showHoverBlueRow ||
                row?.[header?.slug]?.disabled
                  ? "#000000"
                  : "#046cb8"
              }
              color={
                hover
                  ? row?.disabled ||
                    showHoverBlueRow ||
                    row?.[header?.slug]?.disabled
                    ? "#000000"
                    : "#046cb8"
                  : "#000000"
              }
            />
          </div>
        </BlunoTooltip>
        <div className="s-table__options-modal" ref={component}>
          {(showComponentByThreeDots || showOptionComponent) && (
            <OptionsComponent
              data={
                showOptionComponent
                  ? modalDataByOptionButton
                  : row?.[header?.slug]?.modal_data
              }
              id={row?.id}
              onOptionsFunction={onOptionsFunction}
              changePosition={changePosition}
              positionModal={positionModal}
              checkOutsideClick={checkOutsideClick}
            />
          )}
        </div>
      </div>
    </>
  );
}
