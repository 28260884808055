import React, { useState, useRef, useEffect } from "react";
import Dropdown from "../Dropdown";
import MuiTextInput from "../blunoInputs/MuiTextInputs";
import Button from "../Button";
import UploadFileCard from "../UploadFile/UploadFile";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { useDispatch } from "react-redux";
import { replace, useLocation, useNavigate, useParams } from "react-router-dom";
import { downloadDocumentUtility } from "../../utils/downloadDocument";
import { DOCUMENT_ACTION } from "../../utils/constants/DocumentConstants";

const IRMAdditionModal = ({
  data,
  irmId,
  isIrmAddedManually = false,
  setIsIrmAddedManually,
  setActiveRowId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const { sbId } = useParams();
  const [irmMetadata, setIrmMetadata] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [docId, setDocId] = useState();
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [uploadEnabled, setUploadEnabled] = useState();

  const handleInputChange = (theValue, theSlug) => {
    setIrmMetadata({
      ...irmMetadata,
      [theSlug]: theValue,
    });
    setErrorMsg({});
  };

  const MANUAL_ATTACH_SLUGS = {
    REASON: "manual_attach_irm_reason",
    OTHER: "manual_attach_irm_other_reason",
    DOCUMENT: "manual_attach_irm_document",
  };

  const formData = new FormData();
  const uploadSingleFile = (selectedFiles, doc_type) => {
    const metadata = {
      ...irmMetadata,
      flow: "reconciliation",
    };
    formData.append("file", selectedFiles[0]);
    formData.append("doc_type", doc_type);
    formData.append("metadata", JSON.stringify(metadata));

    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );

      if (dataRes?.success && dataRes?.data) {
        setUploadEnabled(false);
        setErrorMsg({});

        if (dataRes?.data?.document_id) {
          setDocId(dataRes?.data?.document_id);
        }

        if (dataRes?.data?.uploaded_document_info) {
          setUploadedDocuments(
            dataRes?.data?.uploaded_document_info?.[0]?.uploaded_documents
          );
        }
      }
    };

    uploadApiCall();
  };

  const onDocAction = (
    section,
    actionBtn,
    documentId,
    fileName,
    element_id = ""
  ) => {
    const docAction = async (
      section,
      actionBtn,
      documentId,
      fileName
      // element_id
    ) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          section: section,
          action: actionBtn?.action,
          document_id: documentId,
          sb_id: sbId,
          document_type: "MISCELLANEOUS",
        })
      );

      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn?.action === DOCUMENT_ACTION?.["DOWNLOAD"]) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        } else {
          setUploadedDocuments([]);
          setUploadEnabled(true);
        }
      }
    };

    docAction(section, actionBtn, docId, fileName);
  };

  const validateManuallyAddedIrmFields = () => {
    const tempErrorMsg = {};

    if (!irmMetadata?.manual_attach_irm_reason) {
      tempErrorMsg.manual_attach_irm_reason = "Please select a reason";
    }
    if (!docId) {
      tempErrorMsg.manual_attach_irm_document = "Please upload a document";
    }
    if (
      irmMetadata?.manual_attach_irm_reason === "other" &&
      !irmMetadata?.manual_attach_irm_other_reason
    ) {
      tempErrorMsg.manual_attach_irm_other_reason = "Please enter a reason";
    }

    if (Object.keys(tempErrorMsg).length === 0) {
      return true;
    } else {
      setErrorMsg(tempErrorMsg);
      return false;
    }
  };

  const handleAddManualIrm = async (e) => {
    if (validateManuallyAddedIrmFields()) {
      const dataRes = await dispatch(
        apiRequest(
          endpoints.reconManualAddIrm,
          {
            document_id: docId,
            attachment_reason: irmMetadata?.manual_attach_irm_reason,
            other_reason: irmMetadata?.manual_attach_irm_other_reason,
          },
          `/${sbId}/irm/${irmId}/attach/manual`
        )
      );

      if (dataRes?.success && dataRes?.data) {
        // navigate(0);
        // navigate(`/bills/sb`, { replace: true });
        setIsIrmAddedManually(!isIrmAddedManually);
        setActiveRowId(null);
      }
      if (!dataRes?.success && dataRes?.data?.slug_message_map) {
        setErrorMsg(
          Object.fromEntries(
            Object.entries(dataRes?.data?.slug_message_map).map(
              ([key, value]) => [key, value.text]
            )
          )
        );
      }
    }
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setActiveRowId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setUploadEnabled(true);
  }, []);

  const renderUiComponent = (theItem) => {
    switch (theItem?.type) {
      case "dropdown":
        return (
          <div className="mapirm__modal-dropdown">
            <Dropdown
              options={theItem?.options}
              defaultValue={irmMetadata?.manual_attach_irm_reason}
              height="40px"
              minWidth="280px"
              menuWidth="280px"
              fontSize="13px"
              onSelectChange={(e) => handleInputChange(e.value, theItem?.slug)}
              isDisabled={theItem?.disabled}
              placeholder={theItem.placeholder}
              placeholderFontSize="13px"
              placeholderFontWeight="400"
              fancyPlaceholder={true}
              topSpacing="-5px"
              errorMessage={errorMsg?.[theItem?.slug]}
            />
          </div>
        );

      case "text_box":
        return (
          <>
            {irmMetadata?.manual_attach_irm_reason === "other" && (
              <div className="mapirm__modal-textbox">
                <MuiTextInput
                  placeholder={theItem?.placeholder}
                  type={"text"}
                  value={irmMetadata?.manual_attach_irm_other_reason}
                  onChange={(e) => {
                    handleInputChange(e.target.value, theItem?.slug);
                  }}
                  disabled={theItem?.disabled}
                  height="40px"
                  minWidth="280px"
                  width="280px"
                  fontSize="13px"
                  errorMessage={errorMsg?.[theItem?.slug]}
                />
              </div>
            )}
          </>
        );
      case "upload_box":
        return (
          <div className="mapirm__modal-uploadbox">
            {theItem?.upload_box_list?.map((obj) => {
              return (
                <>
                  <UploadFileCard
                    upload_enabled={uploadEnabled}
                    documents={uploadedDocuments}
                    doc_type={obj?.document?.slug}
                    uploadSingleFile={uploadSingleFile}
                    docId={docId}
                    onDocAction={onDocAction}
                    section="map_irms"
                    parBgColor="transparent"
                    disabled={
                      irmMetadata?.[MANUAL_ATTACH_SLUGS?.REASON]
                        ? irmMetadata?.[MANUAL_ATTACH_SLUGS?.REASON] === "other"
                          ? irmMetadata?.[MANUAL_ATTACH_SLUGS?.OTHER]
                            ? false
                            : true
                          : false
                        : true
                    }
                    extraUploadListClass="mapirm__modal-uploadbox--extra"
                  />
                  {errorMsg?.[theItem?.slug] && (
                    <p className="mapirm__modal-uploadbox-error">
                      {errorMsg?.[theItem?.slug]}
                    </p>
                  )}
                </>
              );
            })}
          </div>
        );
    }
  };

  return (
    <div ref={modalRef} className="mapirm__modal">
      <div className="mapirm__modal-content">
        <div className="mapirm__modal-inputs">
          {data?.input_list?.map((item) => {
            {
              return renderUiComponent(item);
            }
          })}
        </div>
        <div className="mapirm__modal-buttons">
          {data?.buttons?.map((btn) => {
            {
              return (
                <Button
                  buttonLabel={btn?.text}
                  buttonType={btn?.sub_type}
                  extraClass={"mapirm__modal-buttons--extra"}
                  onClick={(e) => handleAddManualIrm(e)}
                />
              );
            }
          })}
        </div>
      </div>
      <div className="mapirm__modal-help">
        <div className="mapirm__modal-help-left">
          <img src={data?.help_section?.icon} alt="" />
        </div>
        <div className="mapirm__modal-help-content">
          <h2 className="mapirm__modal-help-title">
            {data?.help_section?.title}
          </h2>
          <p className="mapirm__modal-help-text">{data?.help_section?.text}</p>
          <div className="mapirm__modal-help-buttons">
            {data?.help_section?.buttons?.map((item, idx) => {
              {
                return (
                  <Button
                    key={idx}
                    icon={item?.icon}
                    buttonType={item?.sub_type}
                    buttonLabel={item?.text}
                    disabled={item?.disabled}
                    iconAlignment={item?.icon_alignment}
                    extraClass={"mapirm__modal-help--extra"}
                    // onClick={(e) => handleAddManualIrm(e)}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IRMAdditionModal;
