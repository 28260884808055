import React, { useEffect, useState } from "react";
import MuiTextInput from "../blunoInputs/MuiTextInputs";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

export default function RejectRequestModal({
  data,
  id,
  setShowRejectModal,
  onOptionsFunction,
  positionModal = () => {},
}) {
  const [returnReason, setReturnReason] = useState("");

  const handleButtonClick = (slug) => {
    if (slug === "cancel") {
      setShowRejectModal(false);
    } else {
      onOptionsFunction({ slug: "reject" }, id, returnReason);
    }
  };
  useEffect(() => {
    positionModal();
  }, []);
  return (
    <div className="bills__options__reject">
      {data?.inputs?.map((item, idx) => {
        return (
          <MuiTextInput
            multiline={true}
            height="138px"
            rows="4"
            maxLength={item?.limits?.max}
            placeholder={item?.placeholder}
            key={idx}
            value={returnReason}
            onChange={(e) => {
              setReturnReason(e.target.value);
            }}
          />
        );
      })}
      <div className="bills__options__reject-buttons">
        {data?.buttons?.map((item, idx) => {
          return (
            <Button
              key={idx}
              buttonLabel={item?.text}
              buttonType={item?.sub_type}
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick(item?.slug);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
