// react imports
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// redux imports
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";
import endpoints from "../../api/endpoints";

// third-party imports

// in-app imports
import BlunoTable from "../../components/BlunoTable";
import BulkSummary from "../../components/bulkInfo/BulkSummary";
import Button from "../../components/Button";
import {
  BULK_MODAL_SCREEN_TYPE,
  BULK_TABLE_ROW_ACTION,
  SINGLE_BULK_PAGE_SLUGS,
} from "../../utils/constants/BulkConstants";
import BulkActionModal from "../../components/bulkInfo/BulkActionModal";
import ModalV2 from "../../components/ModalV2";
import { GetQueryParamValue } from "../../utils";

const SingleBulk = () => {
  // defined to access react based apis
  const dispatch = useDispatch();
  const { bulkId } = useParams();
  const showInstantFailed =
    GetQueryParamValue("instant_failed_shipping_bills") || false;
  const actionType = GetQueryParamValue("action_type") || null;

  // local states
  const [apiData, setApiData] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalScreenType, setModalScreenType] = useState();

  const [showInfiniteScrollLoader, setShowInfiniteScrollLoader] =
    useState(false);
  const infiniteScrollLock = useRef(false);

  const onButtonClick = (action) => {
    switch (action?.slug) {
      case SINGLE_BULK_PAGE_SLUGS?.ACCEPT:
      case SINGLE_BULK_PAGE_SLUGS?.REJECT:
      case SINGLE_BULK_PAGE_SLUGS?.REMIND:
      case SINGLE_BULK_PAGE_SLUGS?.WITHDRAW:
        setModalScreenType(BULK_MODAL_SCREEN_TYPE?.BULK_SUMMARY_VIEW);
        setModalData(apiData?.modal_data?.[action?.slug]);
        setShowModal(true);
        break;
    }
  };

  const onMakerCheckerAction = async (action, inputData) => {
    const dataRes = await dispatch(
      apiRequest(endpoints.userPostBulkAction, {
        id: bulkId,
        action: action?.slug,
        reason: inputData?.["reason"],
        document_id: inputData?.["document_id"],
      })
    );
    if (dataRes?.success) {
      fetchSingleBulkInfo();
      setShowModal(false);
      setModalData({});
    }
  };

  const onActionClick = (action, inputData) => {
    switch (action?.slug) {
      case BULK_TABLE_ROW_ACTION?.WITHDRAW:
      case BULK_TABLE_ROW_ACTION?.REMIND:
      case BULK_TABLE_ROW_ACTION?.ACCEPT:
      case BULK_TABLE_ROW_ACTION?.REJECT:
        onMakerCheckerAction(action, inputData);
        break;
    }
  };

  const fetchSingleBulkInfo = async () => {
    const dataRes = await dispatch(
      apiRequest(
        endpoints.fetchSingleBulkInfo,
        `/${bulkId}?action_type=${actionType}&page_no=${pageNo}&instant_failed_shipping_bills=${showInstantFailed}`
      )
    );
    if (dataRes?.success && dataRes?.data) {
      setApiData(dataRes.data);
      setPageNo(dataRes?.data?.table_data?.pagination?.current_page);
    }
  };

  const fetchMoreSingleBulkInfo = async () => {
    const dataRes = await dispatch(
      apiRequest(
        endpoints.fetchSingleBulkInfo,
        `/${bulkId}?page_no=${
          pageNo + 1
        }&instant_failed_shipping_bills=${showInstantFailed}`
      )
    );
    if (dataRes?.success && dataRes?.data) {
      setApiData((prev) => ({
        ...prev,
        table_data: {
          ...prev?.table_data,
          data: [...prev?.table_data?.data, ...dataRes.data?.table_data?.data],
          pagination: dataRes?.data?.table_data?.pagination,
        },
      }));
      setPageNo(dataRes?.data?.table_data?.pagination?.current_page);
    }
  };

  const handleInfiniteScroll = async () => {
    if (
      apiData?.table_data?.pagination?.has_next &&
      !infiniteScrollLock.current
    ) {
      infiniteScrollLock.current = true;
      setShowInfiniteScrollLoader(true);
      await fetchMoreSingleBulkInfo();
      setShowInfiniteScrollLoader(false);
      infiniteScrollLock.current = false;
    }
  };

  useEffect(() => {
    fetchSingleBulkInfo();
  }, []);

  return (
    <>
      {showModal && (
        <div className="manage-settings__add-user-modal-bg">
          <ModalV2 slug={"standard"}>
            <BulkActionModal
              data={modalData}
              setShowModal={setShowModal}
              setModalData={setModalData}
              modalScreenType={modalScreenType}
              setModalScreenType={setModalScreenType}
              onActionClick={onActionClick}
            />
          </ModalV2>
        </div>
      )}
      <div className="single-bulk">
        <div className="single-bulk__summary">
          <BulkSummary data={apiData?.header} />
        </div>
        <div className="single-bulk__table">
          {apiData?.table_data && (
            <BlunoTable
              showHoverBlueRow={true}
              showHoverOnSelect={true}
              data={apiData?.table_data}
              showInfiniteScrollLoader={showInfiniteScrollLoader}
              onInfiniteScrollReachBottom={handleInfiniteScroll}
              infiniteScroll={true}
            />
          )}
        </div>
        {apiData?.buttons && apiData?.buttons.length > 0 && (
          <div className="single-bulk__buttons">
            {apiData?.buttons?.map((item, idx) => {
              return (
                <Button
                  key={idx}
                  icon={item?.icon}
                  buttonType={item?.sub_type}
                  buttonLabel={item?.text}
                  iconAlignment={item?.icon_alignment}
                  onClick={() => {
                    onButtonClick(item);
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default SingleBulk;
