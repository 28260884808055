import React, { useState } from "react";
import BlunoTable from "../BlunoTable";

export default function EbrcSummary({ data, setShowModal }) {
  const [compactIRMTable, setCompactIRMTable] = useState(true);
  const [compactInvoiceTable, setCompactInvoiceTable] = useState(true);
  return (
    <div className="ebrc-summary">
      <div className="ebrc-summary__title">
        <p className="ebrc-summary__title-text">{data?.title}</p>
        <img
          src={data?.close_icon}
          className="ebrc-summary__title-icon"
          onClick={() => setShowModal(false)}
        />
      </div>
      <div className="ebrc-summary__heading">
        <div className="ebrc-summary__heading-left">
          <div>
            <p className="ebrc-summary__heading-left-text">
              <span>{data?.left_heading?.title}</span>
              {data?.left_heading?.text}
            </p>
            <p className="ebrc-summary__heading-left-subtext">
              {data?.left_heading?.sub_text}
            </p>
          </div>
          <p className="ebrc-summary__heading-left-bottom">
            {data?.left_sub_heading?.text}
          </p>
        </div>
        <div className="ebrc-summary__heading-right">
          <div className="ebrc-summary__heading-right-top">
            <pre>
              <p className="ebrc-summary__heading-right-label">
                {data?.right_heading?.text}
              </p>
            </pre>
            <p className="ebrc-summary__heading-right-text">
              {data?.right_heading?.sub_text}
              <span>{data?.right_heading?.hz_text}</span>
            </p>
          </div>
          <div className="ebrc-summary__heading-right-bottom">
            <p className="ebrc-summary__heading-right-bottom-label">
              {data?.right_sub_heading?.text}
            </p>
            <img
              src={data?.right_sub_heading?.icon}
              className="ebrc-summary__heading-right-bottom-icon"
            />
            <p className="ebrc-summary__heading-right-bottom-text">
              {data?.right_sub_heading?.sub_text}
            </p>
          </div>
        </div>
      </div>
      <div className="ebrc-summary__table">
        <BlunoTable
          data={data?.invoice_table}
          showHoverBlueRow={false}
          onExpandColumn={(state) => {
            setCompactInvoiceTable(!state);
          }}
        />
        <div
          className={`ebrc-summary__table-footer ${
            compactInvoiceTable ? "ebrc-summary__table-footer--compact" : ""
          }`}
        >
          <p className="ebrc-summary__table-footer-label">Total</p>
          <p className="ebrc-summary__table-footer-text">
            {data?.right_heading?.sub_text}
            <span>{data?.right_heading?.hz_text}</span>
          </p>
        </div>
      </div>
      <div className="ebrc-summary__table">
        <BlunoTable
          data={data?.irm_table}
          showHoverBlueRow={false}
          onExpandColumn={(state) => {
            setCompactIRMTable(!state);
          }}
        />
        <div
          className={`ebrc-summary__table-footer ${
            compactIRMTable ? "ebrc-summary__table-footer--compact" : ""
          }`}
        >
          <p className="ebrc-summary__table-footer-label">Total</p>
          <p className="ebrc-summary__table-footer-text">
            {data?.right_heading?.sub_text}
            <span>{data?.right_heading?.hz_text}</span>
          </p>
        </div>
      </div>
    </div>
  );
}
