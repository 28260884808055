// react imports
import React from "react";

// third-party imports

// in-app imports
import Button from "../Button";

const BulkInfoAction = ({ data, onBulkActionClick }) => {
  return (
    <>
      <div className="bulk-actions">
        <div className="bulk-actions__left">
          <img src={data?.icon} alt="" />
        </div>
        <div className="bulk-actions__right">
          <div className="bulk-actions__content">
            <h2 className="bulk-actions__title">{data?.text}</h2>
            <p className="bulk-actions__text">{data?.subtext}</p>
          </div>
          {data?.buttons && (
            <div className="bulk-actions__buttons">
              {data?.buttons?.map((action) => {
                return (
                  action?.type === "button" && (
                    <Button
                      buttonLabel={action?.text}
                      buttonType={action?.sub_type}
                      icon={action.icon}
                      disabled={action?.disabled}
                      iconAlignment={action?.icon_alignment}
                      onClick={() => onBulkActionClick(action)}
                    />
                  )
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BulkInfoAction;
