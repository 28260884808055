const CompareTypeValidator = {
  equal_to: (value, validation) => {
    return value === validation?.compare_to;
  },

  range: (value, validation) => {
    return (
      Number(value) >= Number(validation?.min) &&
      Number(value) <= Number(validation?.max)
    );
  },

  lt: (value, validation) => {
    // if value is less than  or equal to compare_to
    return Number(value) > 0 && Number(value) <= Number(validation?.compare_to);
  },

  gt: (value, validation) => {
    // if value is greater than  or equal to compare_to
    return Number(value) >= Number(validation?.compare_to);
  },
};

export const FormValidator = {
  EMAIL: (value, validation) => {
    if (!value && !validation?.required) {
      return true;
    }

    const emailRegex = /^[^s@]+@[^s@]+.[^s@]+$/;
    if (emailRegex.test(value)) {
      return true;
    }

    return false;
  },

  PHONE: (value, validation) => {
    if (!value && !validation?.required) {
      return true;
    }

    const phoneNumber = Number(value);
    if (isNaN(phoneNumber)) {
      return false; // Not a valid number
    }

    if (String(phoneNumber).length !== 10) {
      return false;
    }

    return true;
  },

  GENERAL: (value, validation) => {
    if (!validation?.required || value) {
      return true;
    }

    if (validation?.compare_type) {
      return CompareTypeValidator?.[validation?.compare_type]?.(
        value,
        validation
      );
    }

    // if (validation?.required && value) {
    //   return true;
    // }

    return false;
  },
};
