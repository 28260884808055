// react imports
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import endpoints from "../api/endpoints";
import { apiRequest } from "../redux/common/actions";
import {
  updateSidebarItems,
  updateUserBasicInfo,
  updateNotificationObject,
} from "../redux/common/slice";

// third party imports

// in-app imports
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import ToastV2 from "../components/ToastV2";
import AlertPopup from "../components/AlertPopup";
import Loader from "../components/Loader";
import RightPanel from "../components/RightPanel";

// utils
import { updateSelectedState, getSelectedSlug } from "../utils/sidebarHelper";
import AddShippingBill from "../components/AddShippingBill";
import NotificationBanner from "../components/NotificationBanner";
import ModalV2 from "../components/ModalV2";
import PendingOnboardingDocs from "../components/PendingOnboardingDocs";
import AddInwardRemittance from "../components/AddInwardRemittance";
import SupportModal from "../components/SupportModal";

const Root = () => {
  // defined to access react based APIs
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // redux state variables
  const sidebarData = useSelector((state) => state.common.sidebarItems);
  const userInfoData = useSelector((state) => state.common.userBasicInfo);
  const showSbForm = useSelector((state) => state.common.showSbForm);
  const showIrmForm = useSelector((state) => state.common.showIrmForm);
  const showBrForm = useSelector((state) => state.common.showBrForm);
  const showSupportModal = useSelector(
    (state) => state.common.showSupportModal
  );
  const notificationObject = useSelector(
    (state) => state.common.notificationObject
  );

  //local states
  const [sbFormData, setSbFormData] = useState({});
  const [supportModalData, setSupportModalData] = useState({});
  const [irmFormData, setIrmFormData] = useState({});
  const [currentStage, setCurrentStage] = useState("");
  const [notificationData, setNotificationData] = useState({});
  const [notificationBannerData, setNotificationBannerData] = useState({});

  useEffect(() => {
    const getConfigData = async () => {
      // Sidebar Configs
      const sideBarConfigRes = await dispatch(
        apiRequest(endpoints.getSidebarConfigs)
      );
      if (sideBarConfigRes?.success && sideBarConfigRes?.data) {
        const selectedSlug = getSelectedSlug(location.pathname);
        const updatedItems = updateSelectedState(
          sideBarConfigRes?.data,
          selectedSlug,
          location
        );
        dispatch(updateSidebarItems(updatedItems));
      }

      // Basic Profile Info
      const userBasicInfoRes = await dispatch(
        apiRequest(endpoints.getUserBasicInfo)
      );
      if (userBasicInfoRes?.success && userBasicInfoRes?.data) {
        dispatch(
          updateUserBasicInfo(userBasicInfoRes?.data?.profile_attributes)
        );

        const notificationObjectTemp = {
          showNotificationBanner:
            userBasicInfoRes?.data?.notification_menu?.notification_banner
              ?.show_banner,
        };
        dispatch(updateNotificationObject(notificationObjectTemp));
        setNotificationBannerData(
          userBasicInfoRes?.data?.notification_menu?.notification_banner
        );

        setSbFormData(userBasicInfoRes?.data?.sb_form);
        setIrmFormData(userBasicInfoRes?.data?.irm_form);
        setCurrentStage(userBasicInfoRes?.data?.current_stage);
        setNotificationData(userBasicInfoRes?.data?.notification_menu);
        setSupportModalData(userBasicInfoRes?.data?.support_modal);
      }
    };

    if (
      localStorage.getItem("access") === null &&
      sessionStorage.getItem("access") === null
    )
      return;
    getConfigData();
  }, []);

  useEffect(() => {
    const selectedSlug = getSelectedSlug(location.pathname);
    const updatedItems = updateSelectedState(
      sidebarData,
      selectedSlug,
      location
    );
    dispatch(updateSidebarItems(updatedItems));
  }, [location.pathname]);

  if (
    localStorage.getItem("access") === null &&
    sessionStorage.getItem("access") === null
  ) {
    navigate(0);
    return <></>;
  }

  return (
    <>
      {showSbForm && <AddShippingBill data={sbFormData} stage={currentStage} />}
      {showIrmForm && <AddInwardRemittance data={irmFormData} />}
      <ToastV2 />
      <AlertPopup />
      <Loader />
      {showBrForm && (
        <div className="a-buyers__modal-bg">
          <ModalV2 slug={"standard"} children={<PendingOnboardingDocs />} />
        </div>
      )}
      {showSupportModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={<SupportModal data={supportModalData} />}
          />
        </div>
      )}
      <div className="root">
        <div className="root__left">
          <Sidebar data={sidebarData} />
        </div>
        <div className="root__right">
          {/* {notificationObject?.showNotificationBanner && (
            <NotificationBanner data={notificationBannerData} />
          )} */}
          <div className="root__header">
            <Header data={userInfoData} notificationData={notificationData} />
          </div>
          <div className="root__body">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Root;
