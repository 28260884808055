import React, { useState } from "react";
import MuiTextInput from "../components/blunoInputs/MuiTextInputs";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";

const TestCCAvenue = () => {
  const navigate = useNavigate();
  const [redirectLink, setRedirectLink] = useState();

  return (
    <>
      <div className="test-cc-avenue">
        <div className="test-cc-avenue__body">
          <MuiTextInput
            placeholder="Enter CC-Avenue redirection link"
            type="text"
            value={redirectLink}
            height="40px"
            minWidth="360px"
            id="cc_avenue_test"
            onChange={(e) => setRedirectLink(e.target.value)}
            fontSize="13px"
          />
          <Button
            buttonLabel="submit"
            buttonType="primary"
            onClick={() => redirectLink && window.open(redirectLink, "_blank")}
          />
        </div>
      </div>
    </>
  );
};

export default TestCCAvenue;
