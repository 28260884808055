export const OTHER_CHARGES = {
  DISCOUNT: "discount",
  OTHER_DEDUCTIONS: "other_deductions",
  BANK_CHARGES: "bank_charges",
  LC_CHARGES: "lc_charges",
  BILL_DISCOUNT_CHARGES: "bill_discount_charges",
};

export const RECON_ACTION = {
  STANDARD_SB_PAYMENT_AWAITED: "standard_sb_payment_awaited",
};

export const RECON_NUMBER_ACTION = {
  SUBMIT_PAYMENT_AWAITED_CASE: "payment_awaited",
};

export const PAYMENT_AWAITED_SLUGS = {
  EXPECTED_PAYMENT_DATE: "expected_payment_date",
};
