import React from "react";

export default function DocManagementCard({ data, onClick }) {
  return (
    <div
      className={`document-management__card ${
        data?.state === "active" ? "document-management__card--active" : ""
      }`}
      onClick={(e) => onClick(data?.deeplink)}
    >
      {data?.title && (
        <p className="document-management__card-title">{data?.title}</p>
      )}
      {data?.icon && (
        <img src={data?.icon} className="document-management__card-icon" />
      )}
      <div className="document-management__card-text-container">
        <p className="document-management__card-text">{data?.text}</p>
        <div className="document-management__card-partition" />
        <p className="document-management__card-subText">{data?.sub_text}</p>
      </div>
    </div>
  );
}
