//react-imports
import React, { useEffect, useRef, useState } from "react";

//third-party-imports
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

//in-app-imports
import Button from "./Button";
import {
  formatDate,
  formatDateToDDMMYYString,
  formatDateToDDMMYYYYString,
} from "../utils/formatDate";

import calenderActive from "../assets/media/exportBills/calender-active.png";
import TextInput from "./TextInput";
import { subMonths, toDate } from "date-fns";
import { use } from "react";

const BlunoCalender = (props) => {
  const {
    standardDurations = [],
    placeholder = "",
    defaultSelection = {},
    isHighlighted = false,
    isDateCleared = false,
    icon = "",
    height = "30px",
    minWidth = "245px",
    onDateRangeChange = () => {},
    onSingleDateSelect = () => {},
    fancyPlaceholder = false,
    isModalOpen = false,
    numberOfMonths = 2,
    mode = "range",
    disabled = { before: "", after: new Date() },
    calenderPosition = "bottom",
  } = props;

  const modalRef = useRef(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [customRangeText, setCustomRangeText] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [internalStartDate, setInternalStartDate] = useState("");
  const [internalEndDate, setInternalEndDate] = useState("");
  const [externalDateDisplay, setExternalDateDisplay] = useState("");
  const [inputWidth, setInputWidth] = useState(minWidth);
  const [useMonth, setUseMonth] = useState(
    subMonths(new Date(), mode === "range" ? 1 : 0)
  );

  const setFutureDate = {
    toDate:
      mode !== "single"
        ? new Date()
        : new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
    toMonth:
      mode !== "single"
        ? new Date()
        : new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
    toYear:
      mode !== "single"
        ? new Date().getFullYear()
        : new Date().getFullYear() + 2,
  };

  const inputStyles = {
    height: height,
    minWidth: inputWidth,
  };

  const calenderStyles = {
    top: calenderPosition === "top" ? "-375px" : "",
    left: calenderPosition === "top" ? "50%" : "",
    transform: calenderPosition === "top" ? "translateX(-50%)" : "",
  };

  const modifiers = {
    startOfRow: (date) => {
      const dayOfWeek = date.getDay();
      const dayOfMonth = date.getDate();
      return dayOfWeek === 0 || dayOfMonth === 1; // Sunday (start of the row)
    },
    endOfRow: (date) => {
      const dayOfWeek = date.getDay();
      const nextDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1
      );
      return dayOfWeek === 6 || nextDay.getDate() === 1; // Saturday (end of the row)
    },
  };

  const handleSelection = (theItem) => {
    setCustomRangeText(theItem?.value);
    setInternalStartDate(theItem?.start_date);
    setInternalEndDate(theItem?.end_date);

    if (mode === "range") {
      setSelectedDate({
        from: new Date(theItem?.start_date),
        to: new Date(theItem?.end_date),
      });
      setUseMonth(subMonths(new Date(theItem?.end_date), 1));
    } else {
      //selecting custome range when mode is single
      const selectedDateFromRange = new Date(theItem?.end_date);
      setSelectedDate(selectedDateFromRange);
      setUseMonth(subMonths(selectedDateFromRange, 0));

      onSingleDateSelect(selectedDateFromRange);
      setExternalDateDisplay(formatDateToDDMMYYString(selectedDateFromRange));
      setModalOpen(false);
    }
  };

  //TODO: Discuss with product: eg MMT
  useEffect(() => {
    setUseMonth(subMonths(new Date(), 0));
  }, [modalOpen]);

  const handleDateSelection = (event) => {
    // if (!event?.from || !event?.to) {
    setCustomRangeText("");
    // }
    setSelectedDate(event);
    if (mode === "single") {
      onSingleDateSelect(event);
      setExternalDateDisplay(formatDateToDDMMYYString(event));
      setModalOpen(false);
    }
  };

  const renderCustomDateRange = () => {
    if (!standardDurations.length) {
      return <></>;
    }
    return (
      <div className="b-calender__sidebar">
        {standardDurations?.map((item) => {
          return (
            <button
              key={item?.value}
              className={`b-calender__button ${
                customRangeText === item?.value && "b-calender__button--active"
              }`}
              onClick={() => handleSelection(item)}
            >
              {item?.key}
            </button>
          );
        })}
      </div>
    );
  };

  const handleExternalDisplay = () => {
    if (customRangeText) {
      standardDurations?.map((item) => {
        if (item?.value === customRangeText) {
          setExternalDateDisplay(item?.key);
          return;
        }
      });
      setInputWidth(205);
    } else {
      let formattedString = "";
      if (internalStartDate) {
        formattedString += formatDateToDDMMYYString(internalStartDate);
      }
      if (internalEndDate) {
        formattedString += ` - ${formatDateToDDMMYYString(internalEndDate)}`;
      }
      setInputWidth(minWidth);
      setExternalDateDisplay(formattedString);
    }
  };

  const handleAppylyClick = () => {
    onDateRangeChange(internalStartDate, internalEndDate);
    handleExternalDisplay();
    setModalOpen(false);
    setSelectedDate({
      from: new Date(internalStartDate),
      to: new Date(internalEndDate),
    });
  };

  const formatAndSetInternalDate = () => {
    setInternalStartDate(formatDate(selectedDate?.from));
    setInternalEndDate(formatDate(selectedDate?.to ?? new Date()));
  };

  useEffect(() => {
    selectedDate && formatAndSetInternalDate();
  }, [selectedDate]);

  useEffect(() => {
    if (isModalOpen) {
      setModalOpen(isModalOpen);
    }
  }, [isModalOpen]);

  useEffect(() => {
    setInternalStartDate(defaultSelection?.start_date);
    setInternalEndDate(defaultSelection?.end_date);
    setCustomRangeText(defaultSelection?.value);
    setExternalDateDisplay(defaultSelection?.key);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef?.current && !modalRef?.current?.contains(event.target)) {
        setModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => {
    if (isDateCleared) {
      setInternalStartDate(defaultSelection?.start_date);
      setInternalEndDate(defaultSelection?.end_date);
      setCustomRangeText(defaultSelection?.value);
      setExternalDateDisplay(defaultSelection?.key ?? "");
      setSelectedDate(null);
    }
  }, [isDateCleared]);

  return (
    <div className="b-calender-container">
      <div ref={modalRef} className="b-calender">
        <div
          // onFocus={(e) => {
          //   setModalOpen(true);
          // }}
          onClick={() => {
            setModalOpen(!modalOpen);
          }}
          className="b-calender__input-cont"
          style={inputStyles}
        >
          {fancyPlaceholder ? (
            <TextInput
              height="30px"
              minWidth="50px"
              iconClass={`b-calender__icon ${
                isHighlighted || modalOpen ? "b-calender__icon--blue" : ""
              }`}
              icon={icon}
              showMuiInput="true"
              searchIcon={false}
              iconAlignment="right"
              value={externalDateDisplay}
              readonly={true}
              placeholder={placeholder}
              isHighlighted={isHighlighted}
              isFocusedExternal={modalOpen}
              isIconClickable={true}
              onIconClick={() => {
                setModalOpen(true);
              }}
              cursor="pointer"
            />
          ) : (
            <>
              <input
                type="text"
                placeholder={fancyPlaceholder ? "" : placeholder}
                className={`b-calender__input ${
                  isHighlighted ? "b-calender__input--highlighted" : ""
                }`}
                value={externalDateDisplay}
                readOnly
              />
              {icon && (
                <img
                  className="b-calender__icon"
                  src={isHighlighted ? calenderActive : icon}
                  alt="calender"
                />
              )}
            </>
          )}
        </div>
        {modalOpen && (
          <div
            className="b-calender__modal"
            style={calenderStyles}
            onBlur={(e) => {
              if (!e.currentTarget.contains(e.relatedTarget))
                setModalOpen(false);
            }}
          >
            <>{renderCustomDateRange()}</>
            <div className="b-calender__right">
              {mode === "range" && (
                <div className="b-calender__dates">
                  <p className="b-calender__date">
                    {formatDateToDDMMYYYYString(internalStartDate)}
                  </p>
                  <span>{`->`}</span>
                  <p className="b-calender__date">
                    {formatDateToDDMMYYYYString(internalEndDate)}
                  </p>
                </div>
              )}
              <div className="b-calender__calender">
                <DayPicker
                  numberOfMonths={numberOfMonths}
                  mode={mode}
                  selected={selectedDate}
                  onSelect={(e) => {
                    handleDateSelection(e);
                  }}
                  captionLayout="dropdown-buttons"
                  disabled={disabled}
                  fromYear={2014}
                  toDate={setFutureDate?.toDate}
                  toMonth={setFutureDate?.toMonth}
                  toYear={setFutureDate?.toYear}
                  month={useMonth}
                  onMonthChange={(e) => setUseMonth(e)}
                  modifiers={modifiers}
                  modifiersClassNames={{
                    startOfRow: "b-calender__start-of-row",
                    endOfRow: "b-calender__end-of-row",
                  }}
                />
              </div>
              {mode === "range" && (
                <div className="b-calender__actions">
                  <Button
                    buttonLabel="Cancel"
                    buttonType="secondary"
                    buttonSize="sm2"
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  />
                  <Button
                    buttonLabel="Apply"
                    buttonSize="sm2"
                    onClick={handleAppylyClick}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlunoCalender;
