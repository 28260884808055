// react imprts
import React, { useState } from "react";

// third-party imports
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// in-app imports
import SummaryDetails from "./SummaryDetails";
import SummaryInvoice from "./SummaryInvoice";
import SummaryMapIrm from "./SummaryMapIrm";
import SubmissionInfo from "./SubmissionInfo";
import Button from "../../components/Button";

import { useParams } from "react-router-dom";
import OptionsComponent from "../../components/export-bills/OptionsComponent";
import OptionDotComponent from "../../components/OptionDotComponent";

const ReconSummary = ({
  data,
  onActionClick,
  showReconSummary = false,
  setShowReconSummary,
}) => {
  const [isDownloadAllowed, setIsDownloadAllowed] = useState(false);

  const params = useParams();

  const sbId = params?.sbId;

  const onActionClick2 = (slug, action, reason) => {
    if (slug === "show_summary") {
      setShowReconSummary(!showReconSummary);
    } else onActionClick("", { slug: slug }, [sbId], reason);
  };

  return (
    <>
      <div className="recon-summary">
        <div
          className={`recon-summary__heading-v2 ${
            showReconSummary ? "recon-summary__heading--show" : ""
          }`}
        >
          {/* <h2 className="recon-summary__title">{data?.header?.title}</h2> */}

          <img
            src={data?.header?.icon}
            alt="close"
            className="r-panel__icon"
            onClick={() => {
              setShowReconSummary(false);
            }}
          />
        </div>
        {showReconSummary && (
          <div className="recon-summary__body">
            {data?.company_details && (
              <SummaryDetails
                data={data?.company_details}
                transportDetails={data?.sb_info_list}
              />
            )}
            {data?.invoice_details && (
              <SummaryInvoice
                data={{
                  table_data: data?.invoice_details,
                  title: "SB Number :",
                  sb_number: data?.sb_no,
                }}
              />
            )}
            {data?.irm_details && <SummaryMapIrm data={data?.irm_details} />}
            {data?.extra_documents && (
              <SubmissionInfo data={data?.extra_documents} />
            )}

            {data?.maker_checker_info && (
              <div className="recon-summary__footer">
                {/* <h2 className="recon-summary__footer-title">
                {data?.glossary?.title}
              </h2>
              <p className="recon-summary__footer-text">
                {data?.glossary?.text}
              </p> */}
                <div className="recon-summary__makerchecker">
                  {data?.maker_checker_info?.map((item) => {
                    return (
                      <div className="recon-summary__makerchecker-cont">
                        <div>
                          <p className="recon-summary__makerchecker-label">
                            {item?.label}
                          </p>
                          <p className="recon-summary__makerchecker-value">
                            {item?.value}
                          </p>
                        </div>
                        <span>{item?.subtext}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ReconSummary;
