// react imprts
import React, { useState } from "react";

// third-party imports

// in-app imports
import Button from "../../components/Button";

import { useParams } from "react-router-dom";
import OptionsComponent from "../../components/export-bills/OptionsComponent";
import OptionDotComponent from "../../components/OptionDotComponent";

const PreReconActionsCard = ({
  data,
  onActionClick,
  showReconSummary = false,
  setShowReconSummary,
  isReconCompleted = false,
}) => {
  const params = useParams();

  const sbId = params?.sbId;

  const onActionClick2 = (action, act, reason) => {
    if (action?.slug === "show_summary") {
      setShowReconSummary(!showReconSummary);
    } else onActionClick("", { slug: action?.slug }, [sbId], reason);
  };

  return (
    <>
      <div className="recon-summary">
        <div
          className={`recon-summary__heading ${
            showReconSummary ? "recon-summary__heading--show" : ""
          }`}
        >
          <h2 className="recon-summary__title">
            {data?.header?.title}
            <p>{data?.header?.subtext}</p>
          </h2>
          {isReconCompleted && (
            <img
              src={data?.header?.icon}
              alt="close"
              className="r-panel__icon"
              onClick={() => {
                setShowReconSummary(false);
              }}
            />
          )}
          {!isReconCompleted && data?.header?.buttons && (
            <div className="recon-summary__buttons">
              <section>
                {data?.header?.buttons?.map((action) => {
                  return (
                    action?.type === "button" && (
                      <Button
                        buttonLabel={
                          action?.slug === "show_summary"
                            ? showReconSummary
                              ? action?.alt_text
                              : action?.text
                            : action?.text
                        }
                        buttonType={action?.sub_type}
                        icon={action.icon}
                        disabled={action?.disabled}
                        iconAlignment={action?.icon_alignment}
                        onClick={() => onActionClick2(action)}
                      />
                    )
                  );
                })}
              </section>

              {data?.header?.options_modal && (
                <OptionDotComponent
                  modalData={data?.header?.options_modal}
                  onOptionsFunction={onActionClick2}
                  OptionsComponent={OptionsComponent}
                  hoverBlue={true}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PreReconActionsCard;
