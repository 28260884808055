//react imports
import React, { useEffect, useState } from "react";

//third party imports
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { sanitizeHtml } from "../../utils/sanitizeHtml";

//redux imports
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";
import endpoints from "../../api/endpoints";

//in-app-imports
import BlunoCalender from "../BlunoCalender";
import Button from "../Button";
import {
  PAYMENT_AWAITED_SLUGS,
  RECON_NUMBER_ACTION,
} from "../../utils/constants/ExportBillsConstants";
import BlunoTooltip from "../BlunoTooltip";
import { formatDateToDDMMMYYYY } from "../../utils/formatDate";

const PaymentAwaitedModal = ({
  data = null,
  setShowModal = () => {},
  section = "",
  sbId = null,
  applicationId = null,
  toggleGetApiCall = false,
  setToggleGetApiCall = () => {},
  setMapIRMData,
  setHighlightedData,
}) => {
  const [submitValues, setSubmitValues] = useState({});

  const dispatch = useDispatch();

  const handlePaymentAwaitedActionClick = async (theSlug) => {
    const bodyData = submitValues;
    const dataRes = await dispatch(
      apiRequest(
        endpoints.submitAction,
        {
          section: section,
          action: theSlug,
          data: bodyData,
        },
        `/${sbId}/data/submit?application_id=${applicationId}`
      )
    );
    if (dataRes?.success && dataRes?.data) {
      // setToggleGetApiCall(!toggleGetApiCall);
      setShowModal(false);
      setMapIRMData(null);
      setHighlightedData((prev) => {
        return {
          ...prev,
          header: {
            ...prev?.header,
            payment_awaited_info: {
              title: "Payment awaited",
              text: formatDateToDDMMMYYYY(
                submitValues?.[PAYMENT_AWAITED_SLUGS?.EXPECTED_PAYMENT_DATE]
              ),
            },
          },
        };
      });
    }
  };

  const handleButtonClick = (theSlug) => {
    switch (theSlug) {
      case "do_later":
        setShowModal(false);
        break;
      case RECON_NUMBER_ACTION?.SUBMIT_PAYMENT_AWAITED_CASE:
        handlePaymentAwaitedActionClick(theSlug);
    }
  };

  const handleDateSelection = (theDate) => {
    setSubmitValues({
      ...submitValues,
      [PAYMENT_AWAITED_SLUGS?.EXPECTED_PAYMENT_DATE]: theDate,
    });
  };

  const showErrorMsg = () => {
    const expectedPaymentDate = new Date(
      submitValues?.[PAYMENT_AWAITED_SLUGS?.EXPECTED_PAYMENT_DATE]
    );
    const overdueDateTimestamp = Date.parse(data?.overdue_info?.subtext);
    const overdueDate = new Date(overdueDateTimestamp);

    if (expectedPaymentDate instanceof Date && !isNaN(expectedPaymentDate)) {
      if (expectedPaymentDate > overdueDate) {
        return true;
      }
    }

    return false;
  };

  const renderInput = (theItem) => {
    switch (theItem?.sub_type) {
      case "date":
        return (
          <div className="r-payment-awaited__input">
            <p className="r-payment-awaited__input-label">{theItem?.title}</p>
            <BlunoCalender
              placeholder={theItem?.placeholder}
              standardDurations={theItem?.standard_durations}
              defaultSelection={theItem?.default}
              icon={theItem?.icon}
              height="30px"
              minWidth="173px"
              onSingleDateSelect={handleDateSelection}
              fancyPlaceholder={true}
              numberOfMonths={1}
              mode="single"
              // disabled={{
              //   before: theItem?.limits?.min ? new Date().toDateString() : "",
              //   after: theItem?.limits?.max
              //     ? new Date(theItem?.limits?.max)
              //     : "",
              // }}
              disabled={{
                before: new Date(new Date().setDate(new Date().getDate() + 1)),
              }}
              calenderPosition="top"
            />
          </div>
        );
    }
  };

  const handleInputChange = (theVal, theSlug) => {
    setSubmitValues({
      ...submitValues,
      [theSlug]: theVal,
    });
  };

  useEffect(() => {
    setSubmitValues({
      ...submitValues,
      [data?.remind_info?.slug]: data?.remind_info?.checked,
    });
  }, []);

  useEffect(() => {}, [submitValues]);

  return (
    <div className="m-bulk-action">
      <div className="m-bulk-action__header">
        <h2 className="m-bulk-action__title">{data?.title}</h2>
        <img
          src={data?.close_icon}
          alt="close"
          onClick={() => setShowModal(false)}
        />
      </div>
      <div className="r-payment-awaited">
        <img src={data?.icon} alt="payment awaited" />
        <div className="r-payment-awaited__middle">
          <div className="r-payment-awaited__overdue">
            <p className="r-payment-awaited__overdue-label">
              {data?.overdue_info?.label}
              <BlunoTooltip
                useChildren={true}
                text="The Regulatory cut-off date marks when the </br> shipping bill becomes overdue (9 months from the SB date)."
                position="fixed"
                setTextDangerously={true}
              >
                <img
                  src={data?.overdue_info?.icon}
                  style={{ marginTop: "2px" }}
                />
              </BlunoTooltip>
            </p>
            <p className="r-payment-awaited__overdue-value">
              {data?.overdue_info?.value}
            </p>
          </div>
          <div className="r-payment-awaited__inputs">
            {data?.inputs?.map((item) => {
              return renderInput(item);
            })}
          </div>
          <div className="r-payment-awaited__remind">
            {showErrorMsg() && (
              <div className="r-payment-awaited__error">
                <img src={data?.error_text.icon} />
                <p>{data?.error_text?.text}</p>
              </div>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checkedIcon={<CheckBoxIcon />}
                  onChange={(e) => {
                    handleInputChange(
                      e.target.checked,
                      data?.remind_info?.slug
                    );
                  }}
                  checked={
                    submitValues?.[data?.remind_info?.slug] ? true : false
                  }
                  style={{
                    padding: 0,
                  }}
                />
              }
              disabled={showErrorMsg()}
              label={
                <p
                  className="m-bulk-table-view__declaration-label"
                  dangerouslySetInnerHTML={sanitizeHtml(
                    data?.remind_info?.text,
                    ["target"]
                  )}
                />
              }
              style={{ marginLeft: 0 }}
            />
          </div>
          <div className="r-payment-awaited__buttons">
            {data?.buttons?.map((btn) => {
              return (
                <Button
                  buttonLabel={btn?.text}
                  buttonType={btn?.sub_type}
                  onClick={() => {
                    handleButtonClick(btn?.slug);
                  }}
                  disabled={
                    btn?.slug ===
                      RECON_NUMBER_ACTION?.SUBMIT_PAYMENT_AWAITED_CASE &&
                    (!submitValues?.[
                      PAYMENT_AWAITED_SLUGS?.EXPECTED_PAYMENT_DATE
                    ] ||
                      showErrorMsg())
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentAwaitedModal;
