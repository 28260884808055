export const formatDate = (date) => {
  if (!date) return null;

  const day = date?.getDate().toString().padStart(2, "0");
  const month = (date?.getMonth() + 1).toString().padStart(2, "0");
  const year = date?.getFullYear();
  return `${year}-${month}-${day}`;
};

export const formatDateToDDMMYYYYString = (dateStr) => {
  if (!dateStr) return null;

  const dateObj = new Date(dateStr);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear();

  let suffix;
  if (day >= 11 && day <= 13) {
    suffix = "th";
  } else {
    switch (day % 10) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
      default:
        suffix = "th";
    }
  }

  return `${day}${suffix} ${month}, ${year}`;
};

export const formatDateToDDMMMYYYY = (dateStr) => {
  if (!dateStr) return null;

  const dateObj = new Date(dateStr);
  const day = String(dateObj.getDate()).padStart(2, "0"); // Ensures two-digit day
  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear();

  return `${day} ${month}, ${year}`; 
};

export const formatDateToDDMMYYString = (dateStr) => {
  if (!dateStr) return null;

  const dateObj = new Date(dateStr);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear();

  return `${day} ${month}, ${year.toString().substring(2)}`;
};
