// react imports
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";
import { updateShowIrmForm, updateShowSbForm } from "../redux/common/slice";

// third party imports

// in-app imports
import BlunoTable from "../components/BlunoTable";
import Button from "../components/Button";
import BlunoPagination from "../components/BlunoPagination";
import BlunoFilter from "../components/BlunoFilter";
import useDebouncing from "../hooks/useDebouncing";

// utils
import { GetQueryParamValue } from "../utils";
import { DEBOUNCE, REFRESH_IRM_ALERT } from "../app/constants/common";
import OptionsComponent from "../components/export-bills/OptionsComponent";
import RejectRequestModal from "../components/export-bills/RejectRequestModal";
import usePrevious from "../hooks/usePrevious";
import BlunoTooltip from "../components/BlunoTooltip";
import { showToast } from "../redux/toast/slice";

const Bills = () => {
  // defined to access react based APIs
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const showSbForm = useSelector((state) => state.common.showSbForm);
  const showIrmForm = useSelector((state) => state.common.showIrmForm);

  const prevStatus = usePrevious({ showSbForm, showIrmForm });

  // local state variables
  const [apiData, setApiData] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [filterObj, setFilterObj] = useState({});
  const [rowSelectionState, setRowSelectionState] = useState({});
  const [showRejectAllModal, setShowRejectAllModal] = useState(false);
  const [showCompactFilters, setShowCompactFilters] = useState(false);
  const sortCriteria = useRef({});
  const initialLoad = useRef(true);
  const [selectedTextAndSubText, setSelectedTextAndSubText] = useState();
  const stopDebouncing = useRef(true);

  //redux variables
  const currentStage = useSelector((state) => state.decisionTask.currentStage);

  // custom hooks variables
  const debouncedFilterObj = useDebouncing(filterObj, DEBOUNCE.delay);

  // other variables
  const billType = params?.bill_type || "sb";
  const filterCategory = GetQueryParamValue("filter_category") || "";

  const tableStyles = {
    maxHeight: "643px",
    height:
      apiData?.pagination?.total_pages > 1
        ? "calc(100vh - 340px)"
        : "calc(100vh - 270px)",
  };

  const configData = {
    headers: apiData?.headers,
    data: apiData?.data,
    default_view: apiData?.default_view,
    select_all_supported: apiData?.select_all_supported,
    selection_supported: apiData?.selection_supported,
  };

  const redirectTo = (theLink) => {
    stopDebouncing.current = true;
    navigate(theLink);
  };

  const onClearFilter = () => {
    if (location.pathname.includes("irm")) navigate("/bills/irm");
    else if (location.pathname.includes("sb")) navigate("/bills/sb");
    setFilterObj({});
    setShowCompactFilters(false);
  };

  const getSbData = async (sortField, order) => {
    const filters = [
      "currency",
      "buyer_name",
      "start_date",
      "end_date",
      "sb_no",
    ];
    if (!sortField) {
      sortField = sortCriteria?.current?.field;
    }
    if (!order) {
      order = sortCriteria?.current?.order;
    }
    if (!sortField || initialLoad.current) {
      sortField = "sb_date";
    }
    if (!order || initialLoad.current) {
      order = "desc";
    }
    sortCriteria.current = { field: sortField, order: order };
    let params = filters
      .filter((key) => filterObj?.[key])
      .map((key) => `${key}=${filterObj[key]}`)
      .join("&");

    const queryString = `?filter_category=${filterCategory}&sort_field=${sortField}&order=${order}&page_no=${
      initialLoad.current ? 1 : pageNo
    }&${initialLoad.current ? "" : params}`;

    const dataRes = await dispatch(
      apiRequest(endpoints.getAllSbData, queryString)
    );
    if (dataRes?.success && dataRes?.data && billType === "sb") {
      setApiData(dataRes.data);
    }
  };

  const getIrmData = async (sortField, order) => {
    const filters = [
      "currency",
      "remitter_name",
      "start_date",
      "end_date",
      "irm_ref_no",
    ];
    if (!sortField) {
      sortField = sortCriteria?.current?.field;
    }
    if (!order) {
      order = sortCriteria?.current?.order;
    }
    if (!sortField || initialLoad.current) {
      sortField = "credit_date";
    }
    if (!order || initialLoad.current) {
      order = "desc";
    }
    sortCriteria.current = { field: sortField, order: order };

    const params = filters
      .filter((key) => filterObj?.[key])
      .map((key) => `${key}=${filterObj[key]}`)
      .join("&");

    const queryString = `?filter_category=${filterCategory}&sort_field=${sortField}&order=${order}&page_no=${
      initialLoad.current ? 1 : pageNo
    }&${initialLoad.current ? "" : params}`;

    const dataRes = await dispatch(
      apiRequest(endpoints.getAllIrmData, queryString)
    );

    if (dataRes?.success && dataRes?.data && billType === "irm") {
      setApiData(dataRes.data);
    }
  };

  const getSBRequestData = async (sortField, order) => {
    const filters = ["sb_no", "prepared_by", "start_date", "end_date"];
    if (!sortField) {
      sortField = sortCriteria?.current?.field;
    }
    if (!order) {
      order = sortCriteria?.current?.order;
    }
    if (!sortField || initialLoad.current) {
      sortField = "prepared_ts";
    }
    if (!order || initialLoad.current) {
      order = "desc";
    }
    sortCriteria.current = { field: sortField, order: order };

    let params = filters
      .filter((key) => filterObj?.[key])
      .map((key) => `${key}=${filterObj[key]}`)
      .join("&");

    const queryString = `?sort_field=${sortField}&order=${order}&page_no=${
      initialLoad.current ? 1 : pageNo
    }&${initialLoad.current ? "" : params}`;
    const dataRes = await dispatch(
      apiRequest(endpoints.getSBRequestData, queryString)
    );

    if (dataRes?.success && dataRes?.data && billType === "request") {
      setRowSelectionState({});
      setApiData(dataRes.data);
    }
  };

  const refreshIRM = async (sortField, order) => {
    // const filters = [
    //   "currency",
    //   "remitter_name",
    //   "start_date",
    //   "end_date",
    //   "irm_ref_no",
    // ];
    // if (!sortField) {
    //   sortField = sortCriteria?.current?.field;
    // }
    // if (!order) {
    //   order = sortCriteria?.current?.order;
    // }
    // if (!sortField) {
    //   sortField = "credit_date";
    // }
    // if (!order) {
    //   order = "desc";
    // }
    // sortCriteria.current = { field: sortField, order: order };

    // const params = filters
    //   .filter((key) => filterObj?.[key])
    //   .map((key) => `${key}=${filterObj[key]}`)
    //   .join("&");

    // const queryString = `?filter_category=${filterCategory}&sort_field=${sortField}&order=${order}&page_no=${pageNo}&${params}`;
    const dataRes = await dispatch(apiRequest(endpoints.refreshIRMData));

    if (dataRes?.success && dataRes?.data && billType === "irm") {
      setApiData(dataRes?.data);
      dispatch(showToast(REFRESH_IRM_ALERT));
    }
  };

  const onTableRowSelection = (id) => {
    if (!rowSelectionState[id])
      setRowSelectionState((prev) => ({ ...prev, [id]: true }));
    else
      setRowSelectionState((prev) => {
        const { [id]: _, ...rest } = prev;
        return rest;
      });
  };
  const onAllTableRowSelection = (rowSelectionState, data) => {
    if (
      Object.keys(rowSelectionState).length <= data.length &&
      Object.keys(rowSelectionState).length !== 0
    ) {
      setRowSelectionState({});
    } else {
      let rowState = {};
      data?.map((item) => {
        rowState[item?.id] = true;
      });
      setRowSelectionState(rowState);
    }
  };

  const handleSubmitClick = async (id) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.submitSbToBank, {
        ids: id,
      })
    );
    if (apiRes?.success) {
      getSBRequestData();
    }
  };

  const handleReturnSB = async (id, reason) => {
    let requestObj = {};

    if (Array.isArray(id)) {
      id.forEach((val) => {
        requestObj[val] = reason;
      });
    } else {
      requestObj[id] = reason;
    }
    const apiRes = await dispatch(
      apiRequest(endpoints.returnSbToMaker, {
        id_reason_map: requestObj,
      })
    );
    if (apiRes?.success) {
      getSBRequestData();
    }
  };

  const handleRemindSb = async (id) => {
    const apiRes = await dispatch(apiRequest(endpoints.remindSB, { ids: id }));
    if (apiRes?.success) {
      getSBRequestData();
    }
  };

  const handleCustomColumnClick = (slug, id) => {
    switch (slug) {
      case "accept":
        handleSubmitClick(id);
        break;
      case "remind":
        handleRemindSb(id);
        break;
    }
  };

  const handleRowSelectOptionClick = (e, slug) => {
    switch (slug) {
      case "clear":
        setRowSelectionState({});
        break;
      case "deselect_all":
        setRowSelectionState({});
        break;
      case "accept":
        handleSubmitClick(Object.keys(rowSelectionState));
        break;
      case "remind_all":
        handleRemindSb(Object.keys(rowSelectionState));
        break;
      case "return":
        setShowRejectAllModal(true);
        break;
    }
  };

  const handleEditClick = async (id, item) => {
    const apiRes = await dispatch(
      apiRequest(
        endpoints.editSb,
        null,
        `${id}/edit/false?application_id=${currentStage?.applicationId}`
      )
    );
    if (apiRes?.success) {
      navigate(item?.deeplink);
    }
  };

  const handleOptionsClick = (action, id, value) => {
    switch (action?.slug) {
      case "reject":
        handleReturnSB(id, value);
        break;
      case "edit":
        handleEditClick(id, value);
        break;
    }
  };

  const handleInputChange = (theValue, theSlug) => {
    switch (theSlug) {
      case "filter_category":
        navigate(`/bills/${billType}?filter_category=${theValue}`);
        break;
      default:
        setFilterObj((prev) => {
          if (theValue === "") {
            const { [theSlug]: _, ...rest } = prev;
            return rest;
          } else {
            return {
              ...filterObj,
              [theSlug]: theValue,
            };
          }
        });
    }
    setPageNo(1);
  };

  const handleDateRangeChange = (startDate, endDate) => {
    setFilterObj({
      ...filterObj,
      ["start_date"]: startDate,
      ["end_date"]: endDate,
    });
    setPageNo(1);
  };

  const onSortOrderClick = (sortCategory, sortOrder) => {
    const order = sortOrder ? (sortOrder === "asc" ? "desc" : "asc") : "desc";
    if (billType === "irm") {
      getIrmData(sortCategory, order);
    } else if (billType === "sb") {
      getSbData(sortCategory, order);
    } else {
      getSBRequestData(sortCategory, order);
    }
  };

  const handleHeaderButtonClick = (theBtn) => {
    switch (theBtn?.slug) {
      case "add_sb":
        dispatch(updateShowSbForm(true));
        break;
      case "add_irm":
        dispatch(updateShowIrmForm(true));
        break;
      case "generate_bulk_ebrc_info":
        navigate(theBtn?.deeplink, {
          state: { text: "Export Bills", link: "/bills/sb" },
        });
        break;
      case "refresh_irm":
        refreshIRM();
        break;
    }
  };

  const fetchApiData = async () => {
    if (billType === "irm") {
      await getIrmData();
    } else if (billType === "sb") {
      await getSbData();
    } else {
      await getSBRequestData();
    }
  };

  const onChangeBillType = async () => {
    initialLoad.current = true;
    await fetchApiData();
    setShowCompactFilters(false);
    setFilterObj({});
    setPageNo(1);
    setTimeout(() => {
      initialLoad.current = false;
    }, [DEBOUNCE.delay * 2]);
  };

  useEffect(() => {
    if (stopDebouncing.current) {
      stopDebouncing.current = false;
    } else if (!initialLoad.current) {
      fetchApiData();
    }
  }, [debouncedFilterObj, filterCategory, pageNo]);

  useEffect(() => {
    onChangeBillType();
  }, [billType]);

  useEffect(() => {
    setSelectedTextAndSubText({
      text:
        billType === "sb"
          ? apiData?.bill_types?.[0]?.total_count
          : billType === "irm"
          ? apiData?.bill_types?.[1]?.total_count
          : apiData?.bill_types?.[2]?.total_count,
      subText:
        billType === "sb"
          ? apiData?.bill_types?.[0]?.sub_text
          : billType === "irm"
          ? apiData?.bill_types?.[1]?.sub_text
          : apiData?.bill_types?.[2]?.sub_text,
    });
  }, [apiData]);

  useEffect(() => {
    if (Object.keys(rowSelectionState).length === 0)
      setShowRejectAllModal(false);
  }, [rowSelectionState]);

  useEffect(() => {
    if (
      (!showIrmForm && prevStatus?.showIrmForm) ||
      (!showSbForm && prevStatus?.showSbForm)
    ) {
      fetchApiData();
    }
  }, [showIrmForm, showSbForm]);

  return (
    <>
      <div className="bills">
        <div className="bills__type">
          <div className="bills__type-type">
            {apiData?.bill_types?.map((obj, idx) => {
              return (
                <div
                  className={`bills__single-container ${
                    obj?.selected && "bills__single-container--selected"
                  }`}
                >
                  <div
                    className={`bills__single ${
                      obj?.selected && "bills__focused"
                    }`}
                    onClick={() => {
                      redirectTo(obj?.deeplink);
                    }}
                    key={"Bills" + idx}
                  >
                    <p className="bills__typetag">
                      {obj?.text}
                      {/* <span className="bills__typetag-total">
                      {obj?.total_count}
                    </span>
                    {obj?.sub_text} */}
                    </p>
                    <p className="bills__typetag--bolder">{obj?.text}</p>
                  </div>
                  {obj?.alert_text && (
                    <p
                      className={`bills__typetag-alert ${
                        obj?.selected && "bills__typetag-alert--selected"
                      }`}
                    >
                      {obj?.alert_text}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
          {apiData?.right_panel && (
            <div className="bills__type-add">
              {apiData?.buttons?.map((btn, idx) => {
                return (
                  <>
                    <Button
                      buttonLabel={btn?.text}
                      buttonType={btn?.sub_type}
                      icon={btn?.icon}
                      hoverIcon={btn?.hover_icon}
                      iconAlignment={btn?.icon_alignment}
                      onClick={() => handleHeaderButtonClick(btn)}
                      key={"Bills Buttons" + idx}
                      tooltipText={btn?.tooltip_text}
                      tooltipPosition={{ bottom: "20px", left: "-40px" }}
                      buttonsSize="md"
                      iconExtraClass="home__add-sb--extra"
                      variant={btn?.button_variant}
                      disabled={btn?.disabled}
                    />
                    {btn?.alt_text && (
                      <p className="bills__type-add-text">{btn?.alt_text}</p>
                    )}
                  </>
                );
              })}
            </div>
          )}
        </div>
        {apiData?.filters && (
          <div>
            {Object.keys(rowSelectionState).length === 0 ? (
              <BlunoFilter
                data={apiData?.filters}
                filterObj={filterObj}
                handleInputChange={handleInputChange}
                handleDateRangeChange={handleDateRangeChange}
                onClearFilter={onClearFilter}
                filterCategory={filterCategory}
                selectedText={selectedTextAndSubText?.text}
                selectedSubText={selectedTextAndSubText?.subText}
                showCompactFilters={showCompactFilters}
                setShowCompactFilters={setShowCompactFilters}
              />
            ) : (
              <div className="bills__request-select">
                {apiData?.selected_options?.options?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`bills__request-select__option ${
                        idx === 0 && item?.slug === "deselect_all"
                          ? "bills__request-select__option--select"
                          : ""
                      }`}
                      onClick={(e) => {
                        idx !== 0 && handleRowSelectOptionClick(e, item?.slug);
                      }}
                    >
                      {item?.icon && (
                        <img
                          src={item?.icon}
                          className={`bills__request-select__option-img ${
                            item?.slug === "accept"
                              ? "bills__request-select__option-img--send"
                              : ""
                          }`}
                          style={{
                            padding: item?.slug === "deselect_all" ? "3px" : "",
                          }}
                        />
                      )}
                      {item?.slug === "deselect_all" ? (
                        <p className="s-select-filter__selected-text">
                          {Object.keys(rowSelectionState).length}{" "}
                          <span>
                            of {apiData?.pagination?.total_results} Selected
                          </span>
                        </p>
                      ) : (
                        <p className={`bills__request-select__option-text`}>
                          {item?.text}
                        </p>
                      )}

                      {/* {idx === 0 && item?.slug === "deselect_all" && (
                        <BlunoTooltip useChildren={true} text="Clear all">
                          <img
                            src="https://static.bluno.in/miscl/clear-filter.png"
                            className="bills__request-select__option-img bills__request-select__option-img--clear"
                            alt=""
                            onClick={(e) => {
                              handleRowSelectOptionClick(e, item?.slug);
                            }}
                          />
                        </BlunoTooltip>
                      )} */}
                      {item?.slug === "return" && showRejectAllModal && (
                        <div className="bills__request-select__reject-modal">
                          <RejectRequestModal
                            data={apiData?.selected_options?.reject_modal_data}
                            id={Object.keys(rowSelectionState)}
                            setShowRejectModal={setShowRejectAllModal}
                            onOptionsFunction={handleOptionsClick}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
        <div
          className={`bills__table ${
            apiData?.pagination?.total_pages > 1 && "bills__table--border"
          }`}
          style={tableStyles}
        >
          <BlunoTable
            data={configData}
            onSortOrderClick={onSortOrderClick}
            OptionsComponent={OptionsComponent}
            onOptionsFunction={handleOptionsClick}
            onTableRowSelection={onTableRowSelection}
            onAllTableRowSelection={onAllTableRowSelection}
            rowSelectionState={rowSelectionState}
            showHoverOnSelect={true}
            specialColumnFunction={handleCustomColumnClick}
            showHoverBlueRow={billType !== "request"}
          />
        </div>
        {apiData?.pagination?.total_pages > 1 && (
          <div className="bills__pagination">
            <BlunoPagination
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalResults={apiData?.pagination?.total_results}
              resultCount={apiData?.data?.length}
              perPageLimit={apiData?.per_page_limit}
              totalPages={apiData?.pagination?.total_pages}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Bills;
