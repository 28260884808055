import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";
import { showToast } from "../redux/toast/slice";

import circleLoader from "../assets/media/gifs/loader.gif";
import { sanitizeHtml } from "../utils/sanitizeHtml";
import Task from "./Task";
import BlunoTooltip from "./BlunoTooltip";

export default function Notifications({ data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [isNotificationClear, setIsNotificationClear] = useState(false);
  const offset = useRef(0);
  const popupRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setShowNotifications(false);
    }
  };

  const clearNotifications = async () => {
    const dataRes = await dispatch(
      apiRequest(endpoints.deleteNotifications, {
        notification_ids: notificationData?.notifications?.map((item, idx) => {
          return item?.id;
        }),
      })
    );
    if (dataRes?.success && dataRes?.data) {
      setIsNotificationClear(true);
    }
  };

  const deleteNotifications = async (notificationIdList) => {
    const dataRes = await dispatch(
      apiRequest(endpoints.deleteNotifications, {
        notification_ids: notificationIdList,
      })
    );
    setNotificationData((prev) => ({
      ...prev,
      notifications: prev?.notifications?.filter(
        (notification) => !notificationIdList.includes(notification.id)
      ),
    }));
  };

  const fetchNotifications = async () => {
    setShowLoader(true);
    const dataRes = await dispatch(
      apiRequest(
        endpoints.notifications,
        `?limit=${data?.limit ?? 4}&offset=${offset?.current ?? 0}`
      )
    );
    if (dataRes?.success && dataRes?.data) {
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);
      let newNotifications = [];
      if (!notificationData?.notifications) {
        newNotifications = [...dataRes?.data?.notifications];
      } else {
        newNotifications = [
          ...notificationData?.notifications,
          ...dataRes?.data?.notifications,
        ];
      }
      setNotificationData({
        ...dataRes?.data,
        notifications: newNotifications,
      });
      offset.current = newNotifications?.length;
    } else {
      dispatch(showToast(dataRes?.data?.toast));
    }
  };

  useEffect(() => {
    if (!showNotifications) {
      offset.current = 0;
      setNotificationData({});
    } else {
      fetchNotifications();
    }
  }, [showNotifications]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div onClick={() => setShowNotifications(!showNotifications)}>
        <BlunoTooltip text="Notifications" useChildren={true}>
          <img
            className={`s-notifications__notification-icon ${
              showNotifications
                ? "s-notifications__notification-icon--selected"
                : ""
            }`}
            src={!showNotifications ? data?.icon : data?.selected_icon}
            alt=""
          />
        </BlunoTooltip>
        {data?.new_notifications_count > 0 && !isNotificationClear && (
          <div
            className={`s-notifications__notification-icon-available ${
              showNotifications
                ? "s-notifications__notification-icon-available--selected"
                : ""
            }`}
          >
            {data?.new_notifications_count}
          </div>
        )}
      </div>
      {showNotifications && (
        <div
          className="s-notifications__popup"
          ref={notificationData?.notifications ? popupRef : null}
        >
          <div className="s-notifications__popup-heading">
            <p className="s-notifications__popup-title">{data?.title}</p>
            {notificationData?.notifications?.length > 0 && (
              <p
                className="s-notifications__popup-clear"
                onClick={() => {
                  clearNotifications();
                  setShowNotifications(false);
                }}
              >
                {notificationData?.clear_text}
              </p>
            )}
          </div>
          <div className="s-notifications__content">
            {!notificationData?.default_view &&
            !showLoader &&
            notificationData?.notifications
              ? notificationData?.notifications?.map((item, idx) => {
                  return (
                    <div
                      className={`s-notifications__notification ${
                        !item?.read ? "s-notifications__notification--new" : ""
                      }`}
                    >
                      <Task
                        id={item?.id}
                        icon={item?.icon}
                        text={item?.text}
                        modalIcon={item?.modal_icon}
                        buttons={item?.buttons}
                        duration={item?.duration}
                        modalData={item?.modal_data}
                        deleteTask={deleteNotifications}
                        flow="notification"
                      />
                    </div>
                  );
                })
              : !showLoader && (
                  <div className="home__no-alert">
                    <img
                      className="home__no-alert-img"
                      src={notificationData?.default_view?.img}
                      alt="no alerts"
                    />
                    <p
                      className="home__no-alert-text"
                      dangerouslySetInnerHTML={sanitizeHtml(
                        notificationData?.default_view?.text
                      )}
                    />
                  </div>
                )}
            {showLoader && (
              <img
                src={circleLoader}
                className="s-notifications__popup-loader"
              ></img>
            )}
            {notificationData?.view_more && (
              <p
                className="s-notifications__popup-clear"
                onClick={() => {
                  fetchNotifications();
                }}
              >
                {notificationData?.view_more}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
}
