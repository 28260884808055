// react imports
import React, { useEffect, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { setCurrentStage } from "../../redux/decision_task/slice";
import { removeLoader, showLoader } from "../../redux/loader/slice";

// in-app imports
import Static from "../../components/Onboarding/Static";
import Progress from "../../components/Onboarding/Progress";
import { showToast } from "../../redux/toast/slice";
import TopPanel from "./TopPanel";
import { AUTHORIZED_SIG_SLUGS } from "../../utils/constants/OnboardingConstants";
import { STAGE } from "../../utils/constants/UserConstants";

const VerifyIEC = lazy(() => import("./VerifyIEC"));
const CompanyDetailsOld = lazy(() => import("./CompanyDetailsOld"));
const DirectorDetails = lazy(() => import("./DirectorDetails"));
const BankDetails = lazy(() => import("./BankDetails"));

// new flow
const CompanyDetailsTask = lazy(() => import("./Tasks/CompanyDetailsTask"));
const BankDetailsTask = lazy(() => import("./Tasks/BankDetailsTask"));
const AuthorizedSigDetailsTask = lazy(() =>
  import("./Tasks/AuthorizedSigDetailsTask")
);
const Completed = lazy(() => import("./Completed"));

export default function CompanyData() {
  // defined to access react based apis
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // redux state variables
  const currentStage = useSelector((state) => state.decisionTask.currentStage);

  // local states
  const [onboardingTaskData, setOnboardingTaskData] = useState();

  const getChild = (task, topPanelData, progressData, content) => {
    switch (task) {
      case "email_iec":
        return (
          <>
            <div className="o-company-data__body o-company-data__body--equal">
              <VerifyIEC
                data={content}
                submitOnboardingTask={submitOnboardingTask}
              />
            </div>
          </>
        );
      case "company_verification":
        return (
          <>
            <div className="o-company-data__body">
              <Progress data={progressData} />
              <CompanyDetailsOld
                data={content}
                submitOnboardingTask={submitOnboardingTask}
              />
            </div>
          </>
        );
      case "authorized_sig_kyc":
        return (
          <>
            <div className="o-company-data__body">
              <Progress data={progressData} />
              <DirectorDetails
                data={content}
                submitOnboardingTask={submitOnboardingTask}
              />
            </div>
          </>
        );
      case "link_bank":
        return (
          <>
            <div className="o-company-data__body">
              <Progress data={progressData} />
              <BankDetails
                data={content}
                submitOnboardingTask={submitOnboardingTask}
              />
            </div>
          </>
        );
      case "welcome_congratulations":
        return (
          <>
            <div className="o-company-data__body o-company-data__body--equal">
              <Completed
                data={content}
                submitOnboardingTask={submitOnboardingTask}
                getOnboardingData={getOnboardingData}
              />
            </div>
          </>
        );
      case "iec_company_verification":
        return (
          <>
            <div className="o-company-data__container">
              {/* <TopPanel
                data={topPanelData}
                onBackOnboardingTask={onBackOnboardingTask}
              /> */}
              <div className="o-company-data__body o-company-data__body--equal">
                {/* <Progress data={progressData} /> */}
                <CompanyDetailsTask
                  data={content}
                  submitOnboardingTask={submitOnboardingTask}
                  getOnboardingData={getOnboardingData}
                  onBackOnboardingTask={onBackOnboardingTask}
                  logoutOfApp={logoutOfApp}
                />
              </div>
            </div>
          </>
        );
      case "bank_verification":
        return (
          <>
            <div className="o-company-data__container">
              {/* <TopPanel
                data={topPanelData}
                onBackOnboardingTask={onBackOnboardingTask}
              /> */}
              <div className="o-company-data__body o-company-data__body--equal">
                {/* <Progress data={progressData} /> */}
                <BankDetailsTask
                  data={content}
                  submitOnboardingTask={submitOnboardingTask}
                  getOnboardingData={getOnboardingData}
                  onBackOnboardingTask={onBackOnboardingTask}
                  logoutOfApp={logoutOfApp}
                />
              </div>
            </div>
          </>
        );
      case "individual_kyc":
        return (
          <>
            <div className="o-company-data__container">
              {/* <TopPanel
                data={topPanelData}
                onBackOnboardingTask={onBackOnboardingTask}
              /> */}
              <div className="o-company-data__body o-company-data__body--equal">
                {/* <Progress data={progressData} /> */}
                <AuthorizedSigDetailsTask
                  data={content}
                  submitOnboardingTask={submitOnboardingTask}
                  onBackOnboardingTask={onBackOnboardingTask}
                  logoutOfApp={logoutOfApp}
                  currentSubTask={currentStage?.currentSubTask}
                />
              </div>
            </div>
          </>
        );
    }
  };

  const logoutOfApp = async () => {
    const logout = await dispatch(apiRequest(endpoints.logout));
    if (logout?.success) {
      localStorage.clear();
      navigate("/onboarding/login");
    }
  };

  const onBackOnboardingTask = () => {
    const backTask = async () => {
      const onboardingRes = await dispatch(
        apiRequest(
          endpoints.fetchOnboardingTask,
          `?application_id=${currentStage?.applicationId}&task=${currentStage?.previousTask}`
        )
      );
      if (onboardingRes?.success && onboardingRes?.data) {
        dispatch(
          setCurrentStage({
            ...currentStage,
            previousTask: onboardingRes?.data?.application_info?.previous_task,
            currentTask: onboardingRes?.data?.application_info?.current_task,
          })
        );
        setOnboardingTaskData(onboardingRes?.data);
      }
    };
    backTask();
  };

  const submitOnboardingTask = async (
    action,
    payload,
    callGetApiAfterSuccess = false,
    getApiSubTAsk = ""
  ) => {
    const submitTask = async () => {
      if (
        currentStage?.currentTask === "individual_kyc" &&
        (currentStage?.currentSubTask === "aadhaar_validation" ||
          currentStage?.currentSubTask === "aadhaar_esign_ton_validation")
      ) {
        dispatch(
          showLoader({
            message: "Re-directing to external link for verification",
          })
        );
        await new Promise((resolve) => setTimeout(resolve, 2000)); // 2-second delay
      }

      const submitTaskRes = await dispatch(
        apiRequest(
          endpoints.submitOnboardingTask,
          payload,
          `?application_id=${currentStage?.applicationId}&task=${
            currentStage.currentTask
          }${
            currentStage?.currentSubTask
              ? `&sub_task=${currentStage?.currentSubTask}`
              : ""
          }`
        )
      );

      if (
        currentStage?.currentSubTask === "aadhaar_validation" ||
        currentStage?.currentSubTask === "aadhaar_esign_ton_validation"
      ) {
        dispatch(removeLoader());
      }
      if (submitTaskRes?.success && submitTaskRes?.data) {
        navigate(submitTaskRes?.data?.deeplink);

        // if (
        //   (action?.slug === AUTHORIZED_SIG_SLUGS?.verifyEsignTon ||
        //     action?.slug === AUTHORIZED_SIG_SLUGS?.verifyNameMismatchESign) &&
        //   submitTaskRes?.data?.task_data?.start_kyc_url
        // ) {
        //   window.open(submitTaskRes?.data?.task_data?.start_kyc_url, "_self");
        // }

        if (
          (action?.slug === AUTHORIZED_SIG_SLUGS?.verifyAadhaar ||
            action?.slug ===
              AUTHORIZED_SIG_SLUGS?.verifyNameMismatchAadhaarESign) &&
          submitTaskRes?.data?.task_data?.digilocker_consent_url
        ) {
          window.open(
            submitTaskRes?.data?.task_data?.digilocker_consent_url,
            "_self"
          );
        }

        if (callGetApiAfterSuccess) {
          const prevCurrentSatge = {
            ...currentStage,
            currentSubTask:
              submitTaskRes?.data?.application_info?.current_subtask,
          };
          getOnboardingData(prevCurrentSatge);
        } else {
          if (
            submitTaskRes?.data?.application_info?.screen === STAGE?.ONBOARDING
          )
            sessionStorage.setItem(
              "application_id",
              submitTaskRes?.data?.application_info?.application_id
            );
          else {
            localStorage.setItem(
              "application_id",
              submitTaskRes?.data?.application_info?.application_id
            );
            localStorage.setItem("access", sessionStorage.getItem("access"));
            localStorage.setItem("refresh", sessionStorage.getItem("refresh"));
          }
          dispatch(
            setCurrentStage({
              applicationId:
                submitTaskRes?.data?.application_info?.application_id,
              screen: submitTaskRes?.data?.application_info?.screen,
              currentTask: submitTaskRes?.data?.application_info?.current_task,
              currentSubTask:
                submitTaskRes?.data?.application_info?.current_subtask,
              previousTask:
                submitTaskRes?.data?.next_task_data?.application_info
                  ?.previous_task,
            })
          );
          setOnboardingTaskData(submitTaskRes?.data?.next_task_data);
        }
      } else {
        // error toast message
        if (submitTaskRes?.data?.toast) {
          dispatch(showToast(submitTaskRes?.data?.toast));
        } else if (submitTaskRes?.data?.slug_message_map)
          return submitTaskRes?.data?.slug_message_map;
      }
    };
    return await submitTask();
  };

  const getOnboardingData = async (prevCurrentSatge) => {
    const onboardingRes = await dispatch(
      apiRequest(
        endpoints.fetchOnboardingTask,
        `?application_id=${currentStage?.applicationId}&task=${currentStage.currentTask}`
      )
    );
    if (onboardingRes?.success && onboardingRes?.data) {
      dispatch(
        setCurrentStage(
          prevCurrentSatge
            ? {
                ...prevCurrentSatge,
                currentTask:
                  onboardingRes?.data?.application_info?.current_task,
                previousTask:
                  onboardingRes?.data?.application_info?.previous_task,
              }
            : {
                ...currentStage,
                currentTask:
                  onboardingRes?.data?.application_info?.current_task,
                previousTask:
                  onboardingRes?.data?.application_info?.previous_task,
              }
        )
      );
      setOnboardingTaskData(onboardingRes?.data);
    } else {
      if (onboardingRes?.data?.toast) {
        dispatch(showToast(onboardingRes?.data?.toast));
      }
    }
  };

  useEffect(() => {
    getOnboardingData();
  }, []);

  return (
    <Suspense fallback={null}>
      <>
        <div className="o-company-data">
          {onboardingTaskData?.static_response && (
            <Static data={onboardingTaskData?.static_response} />
          )}
          {onboardingTaskData?.content &&
            getChild(
              currentStage?.currentTask,
              onboardingTaskData?.top_panel,
              onboardingTaskData?.progress,
              onboardingTaskData?.content
            )}
        </div>
      </>
    </Suspense>
  );
}
