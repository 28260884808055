// react imports
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// third-party imports

// in-app imports
import Tag from "../Tag";

import dropUpIcon from "../../assets/media/exportBills/DropUp-Blue.png";
import dropdownIcon from "../../assets/media/exportBills/dropdown.png";
import Button from "../Button";
import BlunoTooltip from "../BlunoTooltip";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import CopyToClipboard from "../CopyToClipboard";
import BlunoIcon from "../BlunoIcon";

const IrmHeader = ({
  isDropdown,
  isSBDetailView,
  onDropdownClick,
  data,
  onActionClick,
  onDocAction,
  fetchBankChargesInfo,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [copyHover, setCopyHover] = useState(false);

  const redirectTo = (e, theLink) => {
    e.stopPropagation();
    navigate(theLink, { state: { irm: true } });
  };

  return (
    <>
      <div
        className={`s-sbHeader ${
          !isSBDetailView ? "s-sbHeader--hideSBDetail" : null
        }`}
        onClick={onDropdownClick}
      >
        <div className="s-sbHeader__cont">
          <div className="s-sbHeader__content">
            <div className="s-irmHeader__top">
              {data?.back_info && (
                <BlunoTooltip
                  icon={data?.back_info?.icon}
                  text={location?.state?.text ?? data?.back_info?.hover_text}
                  iconWidth="16px"
                  iconHeight="16px"
                  left="28px"
                  useChildren={true}
                >
                  <div
                    href={data?.back_info?.deeplink}
                    onClick={(e) => {
                      if (location?.state?.link)
                        redirectTo(e, location?.state?.link);
                      else redirectTo(e, data?.back_info?.deeplink);
                    }}
                    className="s-sbHeader__back s-sbHeader__back-background"
                  >
                    <img
                      src={data?.back_info?.icon}
                      className="s-sbHeader__back-img"
                    />
                  </div>
                </BlunoTooltip>
              )}
              <div className="s-irmHeader__texts">
                <h2 className="s-irmHeader__text1">{data?.text1}</h2>
                <p
                  className="s-irmHeader__text2"
                  onMouseEnter={() => {
                    setCopyHover(true);
                  }}
                  onMouseLeave={() => {
                    setCopyHover(false);
                  }}
                >
                  <span>{data?.text2}</span>
                  {copyHover && (
                    <CopyToClipboard
                      text={data?.text2}
                      textPosition={"above"}
                    />
                  )}
                </p>
                {data?.tag_info && data?.tag_info?.sub_type === "overdue" && (
                  <Tag
                    text={data?.tag_info?.text}
                    type={data?.tag_info?.sub_type}
                  />
                )}
              </div>

              {data?.sb_doc && (
                <img
                  src={data?.sb_doc?.download_icon}
                  alt=""
                  className="s-sbHeader__sb-download"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDocAction(
                      null,
                      data?.sb_doc,
                      data?.sb_doc?.doc_id,
                      data?.sb_doc?.doc_name
                    );
                  }}
                />
              )}
            </div>
            <div className="s-irmHeader__bottom">
              <p
                className="s-irmHeader__text3"
                dangerouslySetInnerHTML={sanitizeHtml(data?.text3)}
              />
            </div>
          </div>
          <div className="s-sbHeader__summary">
            <div className="s-sbHeader__summary--left">
              {data?.summary?.left?.map((obj) => {
                return (
                  <div className="v2-irm-header__left">
                    <p className="v2-irm-header__text">{obj?.title}</p>
                    <span className="v2-irm-header__text">{obj?.sub_text}</span>
                    <span className="v2-irm-header__number-cont">
                      <h3
                        className="v2-irm-header__number"
                        dangerouslySetInnerHTML={sanitizeHtml(obj?.text)}
                      ></h3>
                      {obj?.icon && (
                        <BlunoIcon
                          src={obj?.icon}
                          alt="Edit"
                          onClick={(e) => {
                            if (obj?.slug === "add_other_charges") {
                              e.stopPropagation();
                              fetchBankChargesInfo();
                            }
                          }}
                          iconWidth="16px"
                          iconHeight="16px"
                          iconFilterVariant="primary-blue"
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="s-sbHeader__summary--right">
              {data?.summary?.right?.map((obj) => {
                return (
                  <>
                    {obj?.type === "button" ? (
                      <div className="s-sbHeader__box-right">
                        <Button
                          buttonLabel={obj?.text}
                          onClick={() => onActionClick(null, obj)}
                        />
                      </div>
                    ) : (
                      <div className="v2-irm-header__left">
                        <p className="v2-irm-header__text">{obj?.title}</p>
                        <span className="v2-irm-header__text">
                          {obj?.sub_text}
                        </span>
                        <span className="v2-irm-header__number-cont">
                          <h3
                            className="v2-irm-header__number"
                            style={obj?.color && { color: obj.color }}
                            dangerouslySetInnerHTML={sanitizeHtml(obj?.text)}
                          ></h3>
                        </span>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>

        {isDropdown && (
          <div className="s-sbHeader__dropdown" onClick={onDropdownClick}>
            <p>{!isSBDetailView ? "Show Details" : "Hide Details"}</p>
            <img
              src={dropUpIcon}
              alt="Dropdown Icon"
              className={`doc-summary__dropdown-icon ${
                !isSBDetailView ? "doc-summary__dropdown--rotate" : ""
              }`}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default IrmHeader;
