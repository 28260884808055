export const getSelectedSlug = (pathname) => {
  let selectedSlug = null;
  switch (true) {
    case pathname === "/":
      selectedSlug = "home";
      break;
    case pathname.startsWith("/bills"):
      selectedSlug = "export_bills";
      break;
    case pathname.startsWith("/buyers"):
      selectedSlug = "buyers";
      break;
    case pathname.startsWith("/support"):
      selectedSlug = "support";
      break;
    case pathname.startsWith("/pricing"):
      selectedSlug = "pricing";
      break;
    case pathname.startsWith("/refer"):
      selectedSlug = "refer";
      break;
    case pathname.startsWith("/settings"):
      selectedSlug = "settings";
      break;
    case pathname.startsWith("/setup"):
      selectedSlug = "home";
      break;
    case pathname.startsWith("/tickets"):
      selectedSlug = "support";
      break;
    case pathname.startsWith("/document-management"):
      selectedSlug = "document_management";
      break;
    case pathname.startsWith("/bulk-info"):
      selectedSlug = "bulk_information";
      break;
  }
  return selectedSlug;
};

export const updateSelectedState = (sidebarData, selectedSlug, location) => {
  return {
    ...sidebarData,
    navigation: sidebarData?.navigation?.map((mapItem) => {
      if (mapItem?.type === "dropdown") {
        const isOptionSelected = mapItem?.options?.some(
          (option) =>
            option.deeplink && location.pathname.startsWith(option.deeplink)
        );
        return {
          ...mapItem,
          is_selected: false,
          is_dropdown_open: isOptionSelected,
          options: mapItem?.options?.map((option) => ({
            ...option,
            is_selected: location.pathname.startsWith(option?.deeplink),
          })),
        };
      }
      return {
        ...mapItem,
        is_selected: mapItem?.slug === selectedSlug,
        is_dropdown_open: false, // Close all dropdowns by default
      };
    }),
  };
};
