// react imports
import React, { useEffect, useState } from "react";

// redux imports
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";
import { showToast } from "../../redux/toast/slice";
import { useDispatch } from "react-redux";

// third-party imports

// in-app imports
import UploadFileCard from "../UploadFile/UploadFile";
import MuiTextInput from "../blunoInputs/MuiTextInputs";
import { UPLOAD_DOCUMENT_FLOW } from "../../utils/constants/TableConstants";
import { DOCUMENT_ACTION } from "../../utils/constants/DocumentConstants";
import { INPUT_TYPE } from "../../utils/constants/CommonConstants";
import { downloadDocumentUtility } from "../../utils/downloadDocument";

const BulkInputElements = ({ inputs, inputValues, setInputValues }) => {
  const dispatch = useDispatch();
  const [docId, setDocId] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [uploadEnabled, setUploadEnabled] = useState();
  const formData = new FormData();

  const INPUT_SLUGS = {
    REASON: "reason",
    DOCUMENT: "document_id",
  };

  const handleInputChange = (theValue, theSlug) => {
    setInputValues({
      ...inputValues,
      [theSlug]: theValue,
    });
  };

  const uploadSingleFile = (selectedFiles, doc_type) => {
    let metadata = {
      flow: UPLOAD_DOCUMENT_FLOW?.BULK_BL,
    };
    formData.append("file", selectedFiles[0]);
    formData.append("doc_type", doc_type);
    formData.append("metadata", JSON.stringify(metadata));

    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );

      if (dataRes?.success && dataRes?.data) {
        setUploadEnabled(false);
        setUploadedDocuments(
          dataRes?.data?.uploaded_document_info?.[0]?.uploaded_documents
        );
        setDocId(dataRes?.data?.document_id);
        handleInputChange(dataRes?.data?.document_id, INPUT_SLUGS?.DOCUMENT);
      } else {
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    };

    uploadApiCall();
  };

  const onDocAction = (section, actionBtn, documentId, fileName, docType) => {
    let metadata = {
      flow: UPLOAD_DOCUMENT_FLOW?.BULK_BL,
    };

    const docAction = async (
      section,
      actionBtn,
      documentId,
      fileName,
      docType
    ) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          section: section,
          action: actionBtn?.action,
          document_id: documentId,
          document_type: docType,
          metadata: JSON.stringify(metadata),
        })
      );

      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn?.action === DOCUMENT_ACTION?.VIEW) {
            window.open(dataRes?.data?.document_link, "_blank");
          } else if (actionBtn?.action === DOCUMENT_ACTION?.DOWNLOAD) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        }
        if (actionBtn?.action === "detach") {
          setUploadedDocuments([]);
          setUploadEnabled(true);
          setDocId(null);
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    };

    docAction(section, actionBtn, documentId, fileName, docType);
  };

  const getInputElementByType = (item, idx) => {
    switch (item?.type) {
      case INPUT_TYPE?.TEXT_AREA:
        return (
          <MuiTextInput
            multiline={true}
            height="138px"
            minWidth="436px"
            rows="4"
            maxLength={item?.limits?.max}
            placeholder={item?.placeholder}
            key={idx}
            value={inputValues?.[INPUT_SLUGS?.REASON]}
            onChange={(e) => {
              handleInputChange(e.target.value, INPUT_SLUGS?.REASON);
            }}
          />
        );
      case INPUT_TYPE?.UPLOAD_BOX:
        return (
          <div className="">
            <UploadFileCard
              upload_enabled={uploadEnabled}
              documents={uploadedDocuments}
              doc_type={item?.slug}
              uploadSingleFile={uploadSingleFile}
              docId={docId}
              onDocAction={onDocAction}
            />
          </div>
        );
    }
  };

  useEffect(() => {
    setUploadEnabled(true);
  }, []);

  return (
    <>
      {inputs &&
        inputs?.map((item, idx) => {
          return getInputElementByType(item, idx);
        })}
    </>
  );
};

export default BulkInputElements;
