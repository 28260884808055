export const HEADER_SLUG = {
  tag: "tag",
  action: "action",
  imageWithLink: "image_with_link",
  bankFlow: "bank_flow",
  dropdown: "dropdown",
  accordion: "accordion",
  options: "options",
  imageWithFunction: "image_with_function",
  textWithIcon: "text_with_icon",
  switch: "toggle",
  textBox: "text_box",
  actionRequest: "action_request",
  centerEllipsis: "center_ellipsis",
  ellipsis: "ellipsis",
  sbDocAction: "doc_action",
  progressBar: "progress_bar",
  sbStatus: "sb_status",
  ebrcDownload: "ebrc_download",
};

export const MODAL_STATE = {
  completed: "completed",
  pending: "pending",
};

export const DOCUMENT_TYPE = {
  invoiceDoc: "COMMERCIAL_INVOICE",
  transportDoc: "TRANSPORT_DOC",
  miscellaneous: "MISCELLANEOUS",
};

export const UPLOAD_DOCUMENT_FLOW = {
  SB_TABLE: "sb_table",
  BULK_BL: "bulk_bl",
  IRM_DOC_CHANGES: "irm_bank_charges",
};

export const SUB_MODAL_TYPE = {
  success: "success",
  pending: "pending",
  invisible: "invisible",
};
