//react imports
import React, { useEffect, useState } from "react";

//in app imports
import { SETTINGS_API_RESPONSE_2 } from "../../modules/landing/constants";
import HealthCheckCard from "../../modules/Settings/HealthCheckCard";

const HealthCheck = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(SETTINGS_API_RESPONSE_2?.data);
  }, []);

  return (
    <div>
      <HealthCheckCard data={data?.health_checks} />
    </div>
  );
};

export default HealthCheck;
