import React from "react";
import Input from "../Input";

const InputGroup = (props) => {
  const { item, value = "", onChange, disabled } = props;

  return (
    <>
      <div className="df-group">
        {item?.title && <p>{item?.title}</p>}
        <div className="df-group__input">
          <Input
            name={item?.name}
            type={
              item?.name === "text_box_loc_vs_export_value"
                ? "number"
                : item?.sub_type
            }
            value={value}
            placeholder={item?.metadata?.placeholder}
            onChange={onChange}
            disabled={disabled}
            height="38px"
          />
        </div>
      </div>
    </>
  );
};

export default InputGroup;
