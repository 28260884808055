import React, { useState } from "react";

import DropdownGroup from "./DropdownGroup";
import CheckboxGroup from "./CheckboxGroup";
import RadioGroup from "./RadioGroup";
import InputGroup from "./InputGroup";
import UploadFormGroup from "./UploadFormGroup";

export const DynamicForm = {
  dropdown: (item, value, handleChange, isFormSubmitted) => {
    return (
      <DropdownGroup
        item={item}
        value={value}
        onChange={(e) => {
          handleChange(item?.element_id, e.value);
        }}
        disabled={isFormSubmitted ? isFormSubmitted : false}
      />
    );
  },
  radio: (item, value, onChange, isFormSubmitted) => {
    return (
      <RadioGroup
        item={item}
        value={value}
        onChange={(e) => {
          onChange(item?.element_id, e.target.value);
        }}
        disabled={isFormSubmitted ? isFormSubmitted : false}
      />
    );
  },
  checkbox: (item, value, onToggle, isFormSubmitted, filledForm) => {
    return (
      <CheckboxGroup
        item={item}
        value={value}
        onChange={() => {
          const isChecked = value === "yes" ? null : "yes";
          onToggle(item?.element_id, isChecked);
        }}
        disabled={checkIfCheckboxDisabled(item, isFormSubmitted, filledForm)}
      />
    );
  },
  text_box: (item, value, onChange, isFormSubmitted) => {
    return (
      <InputGroup
        item={item}
        value={value}
        onChange={(e) => {
          onChange(item?.element_id, e.target.value);
        }}
        disabled={isFormSubmitted ? isFormSubmitted : false}
      />
    );
  },
  upload_box: (
    item,
    value,
    setFormData,
    isFormSubmitted,
    onDocAction,
    section,
    setUploadedDocumentIds
  ) => {
    return (
      <UploadFormGroup
        item={item}
        value={value}
        setFormData={setFormData}
        disabled={isFormSubmitted ? isFormSubmitted : false}
        section={section}
        onDocAction={onDocAction}
        setUploadedDocumentIds={setUploadedDocumentIds}
      />
    );
  },
};

const checkIfCheckboxDisabled = (item, isFormSubmitted, filledForm) => {
  if (item?.name === "checkbox_declaration_sfd") {
    return filledForm ? (isFormSubmitted ? isFormSubmitted : false) : true;
  } else if (item?.name === "checkbox_cash_discount_deviation_reason") {
    return !filledForm;
  }
  return false;
};
