// react imports
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// redux import
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

// third-party imports

// in-app imports
import { GetQueryParamValue } from "../../utils";
import ModalV2 from "../../components/ModalV2";
import BulkActionModal from "../../components/bulkInfo/BulkActionModal";
import BulkSummary from "../../components/bulkInfo/BulkSummary";
import BlunoTable from "../../components/BlunoTable";
import Button from "../../components/Button";
import {
  BULK_ACTIONS,
  BULK_MODAL_SCREEN_TYPE,
} from "../../utils/constants/BulkConstants";

const BulkBLDocs = () => {
  // defined to access react based apis
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bulkBLId } = useParams();
  const bankName = GetQueryParamValue("bank_name");

  // local states
  const [apiData, setApiData] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalScreenType, setModalScreenType] = useState();
  const [showInfiniteScrollLoader, setShowInfiniteScrollLoader] =
    useState(false);

  // refs
  const infiniteScrollLock = useRef(false);

  const onBLDocsButtonClick = (action) => {
    switch (action?.slug) {
      case "proceed":
      case "back":
        navigate("/bulk-info", {
          state: {
            bank: bankName,
            show_bank_popup: true,
            action_type: BULK_ACTIONS?.BILL_LODGEMENT,
          },
        });
        break;
    }
  };

  const onTableActionClick = (action, sbId) => {
    switch (action?.slug) {
      case "upload_pending_doc":
        fetchBulkBLPendingDocs(sbId);
    }
  };

  const fetchBulkBLPendingDocs = async (sbId) => {
    const pathQueryString = `?sb_id=${sbId}&bulk_id=${bulkBLId}`;
    const dataRes = await dispatch(
      apiRequest(endpoints.fetchBulkBLSingleSBDocs, pathQueryString)
    );
    if (dataRes?.success && dataRes?.data) {
      setModalData(dataRes?.data);
      setModalScreenType(BULK_MODAL_SCREEN_TYPE?.BL_PENDING_DOCS);
      setShowModal(true);
    }
  };

  const handleInfiniteScroll = async () => {
    if (
      apiData?.table_data?.pagination?.has_next &&
      !infiniteScrollLock.current
    ) {
      infiniteScrollLock.current = true;
      setShowInfiniteScrollLoader(true);
      await fetchMoreBulkBLDocsData();
      infiniteScrollLock.current = false;
      setShowInfiniteScrollLoader(false);
    }
  };

  const fetchBulkBLDocsData = async () => {
    const pathQueryString = `/${bulkBLId}?action_type=export_bill_lodgement&page_no=${pageNo}&bank=${bankName}`;
    const dataRes = await dispatch(
      apiRequest(endpoints.fetchBulkBLDocs, pathQueryString)
    );
    if (dataRes?.success && dataRes?.data) {
      if (dataRes?.data?.status === "empty") {
        onBLDocsButtonClick({ slug: "proceed" });
      } else {
        setApiData(dataRes.data);
        setPageNo(dataRes?.data?.table_data?.pagination?.current_page);
      }
    }
  };

  const fetchMoreBulkBLDocsData = async () => {
    const pathQueryString = `/${bulkBLId}?action_type=export_bill_lodgement&page_no=${
      pageNo + 1
    }&bank=${bankName}`;
    const dataRes = await dispatch(
      apiRequest(endpoints.fetchMoreBulkBLDocs, pathQueryString)
    );
    if (dataRes?.success && dataRes?.data) {
      setApiData((prev) => ({
        ...prev,
        table_data: {
          ...prev?.table_data,
          data: [...prev?.table_data?.data, ...dataRes?.data?.table_data?.data],
          pagination: dataRes?.data?.table_data?.pagination,
        },
      }));
      setPageNo(dataRes?.data?.table_data?.pagination?.current_page);
    }
  };

  useEffect(() => {
    fetchBulkBLDocsData();
  }, []);

  return (
    <>
      {showModal && (
        <div className="manage-settings__add-user-modal-bg">
          <ModalV2 slug={"standard"}>
            <BulkActionModal
              data={modalData}
              setShowModal={setShowModal}
              setModalData={setModalData}
              modalScreenType={modalScreenType}
              setModalScreenType={setModalScreenType}
              refreshPageData={fetchBulkBLDocsData}
            />
          </ModalV2>
        </div>
      )}
      <div className="bulk-bl-docs">
        <div className="bulk-bl__summary">
          <BulkSummary
            data={apiData?.header}
            onSummaryButtonClick={onBLDocsButtonClick}
            externalBack={true}
          />
        </div>
        <div className="bulk-bl__table">
          {apiData?.table_data && (
            <BlunoTable
              showHoverBlueRow={false}
              data={apiData?.table_data}
              infiniteScroll={true}
              onInfiniteScrollReachBottom={handleInfiniteScroll}
              showInfiniteScrollLoader={showInfiniteScrollLoader}
              onActionClick={onTableActionClick}
            />
          )}
          {apiData?.buttons && (
            <div className="bulk-bl__buttons">
              {apiData?.buttons?.map((item, idx) => {
                return (
                  <Button
                    key={idx}
                    icon={item?.icon}
                    buttonType={item?.sub_type}
                    buttonLabel={item?.text}
                    iconAlignment={item?.icon_alignment}
                    onClick={() => {
                      onBLDocsButtonClick(item);
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BulkBLDocs;
