// react imports
import React from "react";

// third-party imports

// in-app imports
import { getKeyValueFormat } from "../../utils";
import BlunoTable from "../../components/BlunoTable";

const SummaryInvoice = ({ data }) => {
  return (
    // <>
    //   <div className="recon-summary__port">
    //     {getKeyValueFormat(
    //       data?.port?.start?.key,
    //       data?.port?.start?.value,
    //       true,
    //       false
    //     )}
    //     <img src={data?.port?.icon} alt="" />
    //     {getKeyValueFormat(
    //       data?.port?.end?.key,
    //       data?.port?.end?.value,
    //       true,
    //       false
    //     )}
    //   </div>
    //   {data?.invoices && (
    //     <div className="recon-summary__invoice">
    //       <div className="recon-summary__invoice-header">
    //         <div className="recon-summary__invoice-header-left">
    //           <h2 className="recon-summary__section-title">
    //             {data?.invoices?.title}
    //           </h2>
    //           <p className="recon-summary__section-count">
    //             {data?.invoices?.count}
    //           </p>
    //         </div>
    //         <div className="recon-summary__invoice-header-right">
    //           {getKeyValueFormat(
    //             data?.invoices?.doc_info?.key,
    //             data?.invoices?.doc_info?.value,
    //             true,
    //             false,
    //             148
    //           )}
    //         </div>
    //       </div>
    //       <div className="recon-summary__invoice-body">
    //         {data?.invoices?.documents?.map((item) => {
    //           return <InvoiceCard data={item} />;
    //         })}
    //       </div>
    //     </div>
    //   )}
    //   {data?.transport && (
    //     <div className="recon-summary__transport">
    //       <div className="recon-summary__transport-header">
    //         <h2 className="recon-summary__section-title">
    //           {data?.transport?.title}
    //         </h2>
    //         <p className="recon-summary__section-count">
    //           {data?.transport?.count}
    //         </p>
    //       </div>
    //       {data?.transport?.doc_info?.value && (
    //         <div className="recon-summary__transport-mode">
    //           {getKeyValueFormat(
    //             data?.transport?.doc_info?.key,
    //             data?.transport?.doc_info?.value,
    //             true,
    //             false
    //           )}
    //         </div>
    //       )}
    //       <div className="recon-summary__transport-body">
    //         <div className="recon-summary__transport-docs">
    //           {data?.transport?.documents?.[0]?.info_list?.map(
    //             (item, index) => {
    //               return getKeyValueFormat(
    //                 item?.key,
    //                 item?.value,
    //                 index % 2 === 0,
    //                 false,
    //                 null,
    //                 item?.icon
    //               );
    //             }
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </>

    <>
      <div className="recon-summary__mapirm">
        <div className="recon-summary__mapirm-header">
          <h2 className="recon-summary__section-title">{data?.title}</h2>
          <p className="recon-summary__sb-number">{data?.sb_number}</p>
        </div>
        <BlunoTable data={data?.table_data} dataHeight="100%" />
      </div>
    </>
  );
};

// export const InvoiceCard = ({ data }) => {
//   return (
//     <>
//       <div className="recon-summary__invoice-invoice">
//         <div className="recon-summary__invoice-info">
//           {data?.info_list?.map((item) => {
//             return getKeyValueFormat(item?.key, item?.value, true, false, 110);
//           })}
//         </div>
//         {data?.breakup_data && (
//           <div className="recon-summary__invoice-toggle">
//             {data?.breakup_data?.map((item) => {
//               return (
//                 <>
//                   {item?.type === "icon" ? (
//                     <div className="recon-summary__invoice-box">
//                       <img
//                         className="recon-summary__invoice-img"
//                         src={item?.icon}
//                         alt="addition"
//                       />
//                     </div>
//                   ) : (
//                     <div className="recon-summary__invoice-box">
//                       <h3 className="recon-summary__invoice-title">
//                         {item?.title}
//                       </h3>
//                       <p className="recon-summary__invoice-number">
//                         {item?.text}{" "}
//                         <span className="recon-summary__invoice-currency">
//                           {data?.currency}
//                         </span>
//                       </p>
//                     </div>
//                   )}
//                 </>
//               );
//             })}
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

export default SummaryInvoice;
