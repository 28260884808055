// react imports
import React, { useState, useEffect, useRef } from "react";

//redux imports
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

// in-app imports
import Button from "../Button";
import { DynamicForm } from "../DynamicForm";
import { FormValidator } from "../DynamicForm/formValidation/FormValidator";
import { FormErrorMessage } from "../DynamicForm/formValidation/FormErrorMesage";

import dropUpIcon from "../../assets/media/exportBills/DropUp-Blue.png";
import BlunoTooltip from "../BlunoTooltip";
import UploadFileV2 from "../UploadFileV2/UploadFileV2";
import { downloadDocumentUtility } from "../../utils/downloadDocument";
import ReconSummary from "../../modules/ReconSummary/ReconSummary";
import ModalV2 from "../ModalV2";

const ReconcillationForm = (props) => {
  const {
    data,
    onActionClick,
    onDocAction,
    onEditSection,
    uploadedDocumentIds,
    setUploadedDocumentIds,
  } = props;

  // redux state variables
  const currentStage = useSelector((state) => state.decisionTask.currentStage);
  const dispatch = useDispatch();

  const [open, setOpen] = useState();
  const [initData, setInitData] = useState();
  const isFormSubmitted = data?.form_data?.is_form_submitted;
  const [formData, setFormData] = useState({});
  const [pendingDocData, setPendingDocData] = useState({});
  const [files, setFiles] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [elementsToApi, setElementsToApi] = useState([]);
  const [isReconFormValidated, setIsReconFormValidated] = useState(false);
  const [filledForm, setFilledForm] = useState(true);
  const [showGlobalError, setShowGlobalError] = useState(false);
  const [showReconSummary, setShowReconSummary] = useState(false);

  // refs
  const checkboxid = useRef("");
  const modalRef = useRef(null);

  const checkExtraInputValidation = (elementId, value) => {
    switch (elementId) {
      case "ae0bc2b4-dab3-47b6-9038-31b914b0c404":
        // LOC amount validation
        const decimalRegex = /^\d*(\.\d{0,2})?$/;
        if (!decimalRegex.test(value)) {
          return false;
        }
        break;
    }

    return true;
  };

  const onChange = (elementId, value) => {
    if (checkExtraInputValidation(elementId, value)) {
      setFormData((prev) => ({ ...prev, [elementId]: value }));
      resetChildElements(elementId);
      setShowGlobalError(false);
    }
  };

  const fetchApplicationInfoAfterSubmit = async () => {
    const decisionRes = await dispatch(apiRequest(endpoints.decisionApi));
    if (decisionRes?.success && decisionRes?.data) {
      if (decisionRes?.data?.task_status === "completed") {
        setAllowSubmit(true);
      } else {
        setAllowSubmit(false);
      }
    }
  };

  const resetChildElements = (parentElementId) => {
    const resetChild = (parentId) => {
      initData?.forEach((item) => {
        if (
          item?.render_condition?.ref_element_id === parentId &&
          formData[item?.element_id] !== null &&
          formData[item?.element_id] !== item?.metadata?.value
        ) {
          setFormData((prev) => ({
            ...prev,
            [item?.element_id]: null,
          }));
          resetChild(item?.element_id);
        }
      });
    };
    resetChild(parentElementId);
  };

  const checkRenderCondition = (therenderCondition) => {
    if (therenderCondition?.condition?.type === "reqd") {
      return !formData?.[therenderCondition?.ref_element_id] ? false : true;
    }
    if (therenderCondition?.condition?.type === "not_equal_to") {
      return (
        formData?.[therenderCondition?.ref_element_id] !==
        therenderCondition?.condition?.value
      );
    }

    return (
      formData?.[therenderCondition?.ref_element_id] ===
      therenderCondition?.condition?.value
    );
  };

  const checkForValidation = (theArr) => {
    let isValidated = true;

    theArr?.forEach((obj) => {
      if (obj?.validation) {
        const isValid = FormValidator?.[obj?.validation?.type]?.(
          obj?.value,
          obj?.validation
        );
        if (isValid === false) {
          isValidated = false;
          return;
        }
      }
    });

    return isValidated;
  };

  useEffect(() => {
    const inputValidation = () => {
      const elementsToValidate = [];
      const tempArr = [];
      const elementsToBeFilled = [];
      var checkboxid = "";
      initData?.map((item) => {
        if (
          !item?.render_condition ||
          checkRenderCondition(item?.render_condition)
        ) {
          elementsToValidate.push({
            element_id: item?.element_id,
            value: formData[item?.element_id],
            validation: item?.validation,
          });

          tempArr.push({
            element_id: item?.element_id,
            value: formData[item?.element_id],
          });

          if (item?.name !== "checkbox_declaration_sfd") {
            elementsToBeFilled.push({
              element_id: item?.element_id,
              value: formData[item?.element_id],
              validation: item?.validation,
            });
          } else {
            checkboxid = item?.element_id;
          }
        }
      });
      setIsReconFormValidated(checkForValidation(elementsToValidate));
      if (!checkForValidation(elementsToBeFilled)) {
        setFilledForm(false);
      } else {
        setFilledForm(true);
      }
      setElementsToApi(tempArr);
    };

    inputValidation();
  }, [formData, initData]);

  const setInitialValue = (theItem, currentValue) => {
    if (theItem?.type === "upload_box") {
      if (theItem?.document?.uploaded_documents?.length === 0) {
        if (uploadedDocumentIds?.currentValue) return currentValue;
        else return null;
      } else {
        return theItem?.document?.uploaded_documents[0]?.id;
      }
    }
    if (theItem?.metadata?.value) {
      if (theItem?.type === "checkbox" && theItem.metadata.value === "no") {
        return null;
      } else {
        return theItem.metadata.value;
      }
    } else if (theItem?.submitted_value) {
      return theItem?.submitted_value;
    } else {
      return currentValue;
    }
  };

  const onDynamicFormSubmitClick = (action) => {
    if (action?.slug === "save_as_draft") {
      let isFormContains = elementsToApi.some((item) => !!item?.value);
      if (!isFormContains) {
        setShowGlobalError(true);
      } else {
        onActionClick(data?.slug, action, null, elementsToApi);
      }
    } else if (action?.slug === "preview") {
      setShowReconSummary(true);
    } else if (isReconFormValidated) {
      onActionClick(data?.slug, action, null, elementsToApi);
    }
  };

  useEffect(() => {
    initData?.map((item) => {
      if (item?.name === "checkbox_declaration_sfd")
        checkboxid.current = item?.element_id;
      const initialValue = setInitialValue(item, formData?.[item?.element_id]);
      setFormData((prev) => ({
        ...prev,
        [item?.element_id]: initialValue,
      }));
    });
  }, [initData]);

  useEffect(() => {
    if (data?.is_dropdown_open !== null) {
      setOpen(data?.is_dropdown_open);
    }
  }, [data?.is_dropdown_open]);

  useEffect(() => {
    if (!filledForm) {
      setFormData((prev) => ({ ...prev, [checkboxid.current]: null }));
    }
  }, [filledForm]);

  useEffect(() => {
    setInitData(
      data?.form_data?.elements?.map((item) => {
        if (uploadedDocumentIds?.[item?.element_id]) {
          return uploadedDocumentIds?.[item?.element_id];
        } else {
          return item;
        }
      })
    );

    setPendingDocData(data?.pending_documents?.content?.success);
  }, [data]);

  useEffect(() => {
    fetchApplicationInfoAfterSubmit();
  }, [files]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef?.current && !modalRef?.current?.contains(event.target)) {
        setShowReconSummary(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <>
      {showReconSummary && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={
              <div
                className="post-recon__summary post-recon__summary--modal"
                ref={modalRef}
              >
                <ReconSummary
                  data={data?.summary_response}
                  showReconSummary={true}
                  setShowReconSummary={setShowReconSummary}
                  isReconCompleted={data?.is_recon_completed}
                />
              </div>
            }
          />
        </div>
      )}
      <div className={`doc-summary `}>
        <div className="doc-summary__container" onClick={() => setOpen(!open)}>
          <div className="doc-summary__title">
            {data?.status_icon && (
              <img
                src={data?.status_icon}
                alt="step-done"
                className="doc-summary__title-stepdone"
              />
            )}
            <h2 className="doc-summary__title-title">{data?.title}</h2>
            {data?.is_editable && data?.edit_info && (
              <img
                src={data?.edit_info?.icon}
                alt={data?.edit_info?.action}
                className="doc-summary__title-edit"
                onClick={(e) => {
                  e.stopPropagation();

                  onEditSection(
                    data?.slug,
                    data?.edit_info?.action,
                    data?.edit_info?.alert_id
                  );
                }}
              />
            )}
          </div>
          {data?.is_dropdown && (
            <div
              className="doc-summary__dropdown"
              onClick={() => setOpen(!open)}
            >
              <p className="doc-summary__dropdown-text">
                {open ? "Hide Details" : "Show Details"}
              </p>
              <img
                src={dropUpIcon}
                alt="Dropdown Icon"
                className={`doc-summary__dropdown-icon ${
                  !open ? "doc-summary__dropdown--rotate" : ""
                }`}
              />
            </div>
          )}
        </div>
        {open && (
          <div className="doc-summary__content">
            <div className="doc-summary__docinfo">
              {initData?.map((item) => {
                if (!checkRenderCondition(item?.render_condition)) {
                  return null;
                }
                return (
                  <div key={`${item?.element_id}`} style={{ width: "100%" }}>
                    {DynamicForm?.[item?.type]?.(
                      item,
                      item?.submitted_value
                        ? item.submitted_value
                        : formData?.[item?.element_id],
                      item?.type === "upload_box" ? setFormData : onChange,
                      isFormSubmitted,
                      item?.type === "upload_box"
                        ? onDocAction
                        : item?.name === "checkbox_declaration_sfd"
                        ? filledForm
                        : item?.name ===
                          "checkbox_cash_discount_deviation_reason"
                        ? !!formData["c2ab8d8b-a06d-4245-914c-f68cb4d23cdb"]
                        : null,
                      data?.slug,
                      item?.type === "upload_box"
                        ? setUploadedDocumentIds
                        : null
                    )}
                  </div>
                );
              })}
            </div>

            {pendingDocData && (
              <div className="doc-summary__pending">
                <p
                  className={`doc-summary__pending-title  ${
                    !allowSubmit && "doc-summary__pending-title--red"
                  }`}
                >
                  {!allowSubmit && (
                    <img
                      src="https://static.bluno.in/miscl/query-raised-icon.png"
                      alt="warning"
                    />
                  )}
                  <span>
                    Complete your KYC to proceed with your request for
                    submission to the bank.
                  </span>
                </p>
                {!allowSubmit && (
                  <p className="doc-summary__pending-message">
                    Upload pending documents
                  </p>
                )}
                <div className="doc-summary__pending-list">
                  {pendingDocData?.documents?.map((item, idx) => {
                    return (
                      <div className={`doc-summary__pending-document`}>
                        <p className="doc-summary__pending-text">
                          <span>{item?.title?.title}</span>
                          <BlunoTooltip
                            icon={item?.title?.back_info?.icon}
                            text={item?.title?.back_info?.hover_text}
                            iconWidth="16px"
                            iconHeight="16px"
                            left="50px"
                            top="-50px"
                            width="400px"
                            height="45px"
                          />
                        </p>
                        <div className="doc-summary__pending-content">
                          <UploadFileV2
                            upload_enabled={item?.document_info?.upload_enabled}
                            multiple_files={item?.document_info?.multiple_files}
                            icon={item?.document_info?.icon}
                            primary_text={item?.document_info?.primary_text}
                            extraClass="doc-summary__pending-box"
                            flow={"onboarding"}
                            slug={item?.document_info?.slug}
                            onFileChange={setFiles}
                            allowed_doc_types={
                              item?.document_info?.allowed_doc_types
                            }
                            uploaded_documents={
                              item?.document_info?.uploaded_documents
                            }
                            extraMetadata={{
                              application_id: currentStage?.applicationId,
                              task: "welcome_congratulations",
                              sub_task: item?.document_info?.sub_title,
                            }}
                            getApi={() => {}}
                            callGetApi={true}
                            singleUploadAllowed={true}
                          />
                          <div className="doc-summary__pending-button">
                            <Button
                              buttonType={item?.sample?.sub_type}
                              buttonLabel={item?.sample?.text}
                              icon={item?.sample?.icon}
                              iconAlignment={item?.sample?.icon_alignment}
                              iconExtraClass="o-completed__blueicon"
                              iconParDiv={true}
                              iconParDivClass="o-completed__blueicon-par"
                              extraClass="o-completed__hyperlink"
                              onClick={() => {
                                downloadDocumentUtility(
                                  item?.sample?.deeplink,
                                  "br_form"
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {showGlobalError && (
              <div className="doc-summary__form-error">
                <p className="doc-summary__form-error-message">
                  {FormErrorMessage?.EMPTY_INPUT?.text}
                </p>
              </div>
            )}
            <div className="mapirm-summary__submit">
              {data?.buttons?.map((btn) => {
                return (
                  <Button
                    buttonLabel={btn?.text}
                    buttonType={btn?.sub_type}
                    onClick={() => {
                      onDynamicFormSubmitClick(btn);
                    }}
                    disabled={
                      btn?.slug === "submit" ||
                      // btn?.slug === "preview" ||
                      btn?.slug === "notify_admin"
                        ? !(isReconFormValidated && allowSubmit)
                        : btn?.disabled
                    }
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReconcillationForm;
