//react imports
import React, { useState } from "react";

//redux imports
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";

//in app imports
import Button from "../../components/Button";
import MuiTextInput from "../../components/blunoInputs/MuiTextInputs";
import UploadFileCard from "../../components/UploadFile/UploadFile";
import endpoints from "../../api/endpoints";
import Logo from "../../assets/media/onboarding/Bluno Logo_S 1.png";

const RaiseTicketModal = ({
  data,
  setShowModal,
  newTicketId = null,
  setNewTicketId = () => {},
  uploadSingleFile,
  rowId,
  setIsCallGetApi,
  docId = null,
  uploadedDocuments = [],
  setUploadedDocuments,
  uploadEnabled = true,
  setUploadEnabled,
  onDocAction,
}) => {
  const popUpData = {
    icon: Logo,
    text: "Your ticket has been initiated . Your ticket ID is",
  };

  const [submitValues, setSubmitValues] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const dispatch = useDispatch();

  const handleInputChange = (value, slug) => {
    setSubmitValues({
      ...submitValues,
      [slug]: value,
    });
  };

  const handleSubmit = async () => {
    const apiRes = await dispatch(
      apiRequest(endpoints.createUserTicker, {
        source: "dashboard",
        description: submitValues?.reason_to_raise_ticket,
        document_id: docId,
      })
    );
    if (apiRes?.success && apiRes?.data) {
      window.history.replaceState({}, "");
      setNewTicketId(apiRes?.data?.ticket_id);
      setUploadedDocuments([]);
      setUploadEnabled(true);
    }
  };

  const handleTicketAction = async (theAction, theDescription) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.userTicketAction, {
        id: rowId,
        description: theDescription,
        ticket_action: theAction,
      })
    );
    if (apiRes?.success && apiRes?.data) {
      setIsCallGetApi(true);
      setShowModal(false);
    }
  };

  const handleButtonClick = (theItem) => {
    switch (theItem?.slug) {
      case "submit":
        handleSubmit();
        break;
      case "reopen_ticket":
        handleTicketAction("open", submitValues?.reopen_reason);
        break;
      case "withdraw_ticket":
        handleTicketAction("close", submitValues?.withdraw_reason);
        break;
    }
  };

  const renderInputs = (theItem, index) => {
    switch (theItem?.type) {
      case "text_area":
        return (
          <div className="b-tickets__modal-input">
            <p className="b-tickets__modal-label">{theItem?.title}</p>
            <MuiTextInput
              multiline={true}
              rows="3"
              height="80px"
              minWidth="280px"
              onChange={(e) => handleInputChange(e.target.value, theItem?.slug)}
              placeholder={theItem?.placeholder}
              value={submitValues?.[theItem?.slug]}
              errorMessage={errorMessage?.[theItem?.slug]}
              maxLength={theItem?.limits?.max}
            />
          </div>
        );
      case "upload_box":
        return (
          <div className="b-tickets__modal-input">
            <p className="b-tickets__modal-label">
              {theItem?.upload_box?.title}
            </p>
            <UploadFileCard
              upload_enabled={uploadEnabled}
              documents={uploadedDocuments}
              doc_type={"MISCELLANEOUS"}
              uploadSingleFile={uploadSingleFile}
              section="tickets"
              docId={docId}
              allowedDocTypes={theItem?.upload_box?.allowed_doc_types}
              onDocAction={onDocAction}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      <div className="b-tickets__modal">
        <img
          className="o-gethelp-close"
          src={data?.icon}
          onClick={() => {
            window.history.replaceState({}, "");
            setNewTicketId(null);
            setShowModal(false);
          }}
        />
        {!newTicketId ? (
          <>
            <p className="b-tickets__modal-title">{data?.title} </p>
            <div className="b-tickets__modal-body">
              {data?.inputs?.map((item, index) => {
                return (
                  <div className="o-login__input">
                    {renderInputs(item, index)}
                  </div>
                );
              })}
              {data?.buttons?.map((item, index) => {
                return (
                  <Button
                    buttonType={item?.sub_type}
                    buttonLabel={item?.text}
                    onClick={() => handleButtonClick(item)}
                    fontSize="13px"
                    height="40px"
                  />
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div className="o-gethelp-submitted">
              <img
                src={popUpData?.icon}
                className="o-gethelp-submitted__logo"
              />
              <p className="o-gethelp-submitted-text">
                {popUpData?.text}
                <span className="o-gethelp-submitted-text--bold">
                  {newTicketId}
                </span>
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default RaiseTicketModal;
