// react imports
import React from "react";

// third party imports

// in-app imports
import Profile from "./Profile";
import comingSoon from "../assets/media/Coming Soon Search Bar.png";

import bellIcon from "../assets/media/header/bell-3x.png";
import Notifications from "./Notifications";
import BlunoTooltip from "./BlunoTooltip";
import { useDispatch } from "react-redux";
import { updateShowSupportForm } from "../redux/common/slice";

const Header = ({ data, notificationData }) => {
  const dispatch = useDispatch();

  const openSupportModal = () => {
    dispatch(updateShowSupportForm(true));
  };
  return (
    <>
      <div className="s-header">
        <div className="s-header__group">
          <div className="s-header__left">
            <div className="s-header__search">
              <input
                className="search"
                type="search"
                placeholder="Search..."
                disabled
              />
            </div>
            <div className="s-header__banner">
              <img src={comingSoon} className="s-header__banner-coming-soon" />
            </div>
          </div>
          <div className="s-header__middle">
            <div className="s-header__item">
              {data?.help_text && (
                <p
                  className="s-header__item-help"
                  onClick={(e) => {
                    openSupportModal();
                  }}
                >
                  {data?.help_text}
                  <img
                    src={data?.help_icon}
                    className="s-header__item-help-icon"
                    alt=""
                  />
                </p>
              )}
            </div>
            <div className="s-header__item">
              <Notifications data={notificationData} />
            </div>
          </div>
        </div>
        <div className="s-header__right">
          <div className="s-header__item">
            <Profile user={data} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
