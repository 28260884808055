// react imports
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// in-app imports
import Button from "../Button";
import BlunoTooltip from "../BlunoTooltip";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import CopyToClipboard from "../CopyToClipboard";
import { downloadDocumentUtility } from "../../utils/downloadDocument";

const BulkSummary = ({
  data,
  onSummaryButtonClick = () => {},
  buttonDisabled,
  externalBack = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showCopySlug, setShowCopySlug] = useState();

  return (
    <>
      <div className="bl-bulk-summary">
        <div className="bl-bulk-summary__header">
          <div className="bl-bulk-summary__back">
            {data?.back_info && (
              <BlunoTooltip
                icon={data?.back_info?.icon}
                text={data?.back_info?.hover_text}
                iconWidth="16px"
                iconHeight="16px"
                left="28px"
                useChildren={true}
              >
                <div
                  onClick={(e) => {
                    if (externalBack) onSummaryButtonClick(data?.back_info);
                    else if (location?.state?.link)
                      navigate(location?.state?.link);
                    else navigate(data?.back_info?.deeplink);
                  }}
                  className="s-sbHeader__back s-sbHeader__back-background"
                >
                  <img
                    src={data?.back_info?.icon}
                    className="s-sbHeader__back-img"
                  />
                </div>
              </BlunoTooltip>
            )}
          </div>
          <div className="bl-bulk-summary__left">
            <div className="bl-bulk-summary__bulkid">
              <span className="bl-bulk-summary__bulkid-label">
                {data?.bulk_detail?.label}
              </span>
              <span
                className="bl-bulk-summary__bulkid-value"
                onMouseEnter={() => setShowCopySlug("bulkid")}
                onMouseLeave={() => setShowCopySlug(null)}
              >
                {data?.bulk_detail?.value}
                <div style={{ width: "20px", height: "16px" }}>
                  {data?.bulk_detail?.enable_copy &&
                    showCopySlug === "bulkid" && (
                      <CopyToClipboard text={data?.bulk_detail?.value} />
                    )}
                </div>
              </span>
            </div>
            <div className="bl-bulk-summary__bank">
              <span className="bl-bulk-summary__bank-label">
                {data?.bank_detail?.text}
              </span>
              <img src={data?.bank_detail?.bank_logo} alt="" />
              <span
                className="bl-bulk-summary__bank-value"
                onMouseEnter={() => setShowCopySlug("adcode")}
                onMouseLeave={() => setShowCopySlug(null)}
              >
                {data?.bank_detail?.subtext}
                <div style={{ width: "20px", height: "16px" }}>
                  {data?.bank_detail?.enable_copy &&
                    showCopySlug === "adcode" && (
                      <CopyToClipboard text={data?.bank_detail?.subtext} />
                    )}
                </div>
              </span>
            </div>
            {data?.maker_info && (
              <div className="bl-bulk-summary__maker-info">
                <span className="bl-bulk-summary__maker-info-label">
                  {data?.maker_info?.label}
                </span>
                <span className="bl-bulk-summary__maker-info-value">
                  {data?.maker_info?.value}
                </span>
              </div>
            )}
            {data?.checker_info && (
              <div className="bl-bulk-summary__checker-info">
                <span className="bl-bulk-summary__checker-info-label">
                  {data?.checker_info?.label}
                </span>
                <span className="bl-bulk-summary__checker-info-value">
                  {data?.checker_info?.value}
                </span>
              </div>
            )}
          </div>
          <div className="bl-bulk-summary__right">
            {data?.sb_detail && (
              <div className="bl-bulk-summary__sb-count">
                <span className="bl-bulk-summary__sb-count-label">
                  {data?.sb_detail?.label}
                </span>
                <span className="bl-bulk-summary__sb-count-value">
                  {data?.sb_detail?.value}
                </span>
              </div>
            )}
            {data?.invoice_detail && (
              <div className="bl-bulk-summary__invoice">
                <span className="bl-bulk-summary__invoice-label">
                  {data?.invoice_detail?.label}
                </span>
                <span className="bl-bulk-summary__invoice-value">
                  {data?.invoice_detail?.value}
                </span>
              </div>
            )}
            {data?.bulk_info_status && (
              <div className="bl-bulk-summary__progress">
                <p className="bl-bulk-summary__progress-content">
                  <span
                    className="bl-bulk-summary__progress-title"
                    dangerouslySetInnerHTML={sanitizeHtml(
                      data?.bulk_info_status?.text
                    )}
                  />
                  <span
                    className="bl-bulk-summary__progress-text"
                    dangerouslySetInnerHTML={sanitizeHtml(
                      data?.bulk_info_status?.subtext
                    )}
                  />
                </p>
                {data?.bulk_info_status?.progress_details && (
                  <div
                    className="s-table__table-progress-bar"
                    style={{
                      backgroundColor:
                        data?.bulk_info_status?.progress_details
                          ?.secondary_color,
                    }}
                  >
                    <span
                      style={{
                        width:
                          data?.bulk_info_status?.progress_details
                            ?.progress_percent,
                        backgroundColor:
                          data?.bulk_info_status?.progress_details
                            ?.primary_color,
                      }}
                    ></span>
                  </div>
                )}
              </div>
            )}
            {data?.buttons && (
              <div className="bl-bulk-summary__buttons">
                {data?.buttons?.map((item, idx) => {
                  return (
                    <Button
                      key={idx}
                      icon={item?.icon}
                      buttonType={item?.sub_type}
                      buttonLabel={item?.text}
                      iconAlignment={item?.icon_alignment}
                      onClick={() => {
                        onSummaryButtonClick(item);
                      }}
                      disabled={buttonDisabled}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {data?.reason_info && (
          <div className="bl-bulk-summary__reason">
            {data?.reason_info?.reason && (
              <p
                className="bl-bulk-summary__reason-text"
                dangerouslySetInnerHTML={sanitizeHtml(
                  data?.reason_info?.reason
                )}
              />
            )}
            {data?.reason_info?.document && (
              <p className="bl-bulk-summary__reason-document">
                <span className="bl-bulk-summary__reason-document-label">
                  {data?.reason_info?.document?.label}
                </span>
                <span
                  className="bl-bulk-summary__reason-document-value"
                  onClick={() => {
                    window.open(
                      data?.reason_info?.document?.deeplink,
                      "_blank"
                    );
                    downloadDocumentUtility(
                      data?.reason_info?.document?.deeplink,
                      data?.reason_info?.document?.value
                    );
                  }}
                >
                  {data?.reason_info?.document?.value}
                </span>
              </p>
            )}
          </div>
        )}
        {data?.sent_invoices && data?.failed_invoices && (
          <div className="bl-bulk-summary__instant">
            {data?.sent_invoices && (
              <div className="bl-bulk-summary__instant-success">
                <span className="bl-bulk-summary__instant-success-label">
                  {data?.sent_invoices?.label}
                </span>
                <span className="bl-bulk-summary__instant-success-value">
                  {data?.sent_invoices?.value}
                </span>
              </div>
            )}
            {data?.failed_invoices && (
              <div className="bl-bulk-summary__instant-failed">
                <span className="bl-bulk-summary__instant-failed-label">
                  {data?.failed_invoices?.label}
                </span>
                <span className="bl-bulk-summary__instant-failed-value">
                  {data?.failed_invoices?.value}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BulkSummary;
