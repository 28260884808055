import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";
import {
  DOCUMENT_MANAGEMENT_TYPE,
  PARAM_TYPE,
} from "../utils/constants/DocumentManagementConstants";
import { useNavigate, useParams } from "react-router-dom";
import DocManagementCard from "../components/DocumentManagement/DocManagementCard";
import BlunoFilter from "../components/BlunoFilter";
import BlunoTable from "../components/BlunoTable";
import BlunoPagination from "../components/BlunoPagination";
import { DEBOUNCE } from "../app/constants/common";
import useDebouncing from "../hooks/useDebouncing";
import { DOCUMENT_ACTION } from "../utils/constants/DocumentConstants";
import { downloadDocumentUtility } from "../utils/downloadDocument";
import BlunoSelectFilter from "../components/BlunoSelectFilter";
import DocManagementMore from "../components/DocumentManagement/DocManagementMore";
import { showLoader } from "../redux/loader/slice";
import { showToast } from "../redux/toast/slice";
import ModalV2 from "../components/ModalV2";
import SbSummary from "../modules/SingleSB/SbSummary";
import { closeAlert, resetAlertData, showAlert } from "../redux/alert/slice";
import EbrcSummary from "../components/DocumentManagement/EbrcSummary";

export default function DocumentManagement() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [modalData, setModalData] = useState({});
  const [detailsId, setDetailsId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [orderByColumn, setOrderByColumn] = useState({});
  const [rowSelectionState, setRowSelectionState] = useState({});
  const [showCompactFilters, setShowCompactFilters] = useState(false);
  const dataType = useRef();
  const originalData = useRef();

  const docType = params?.doc_type || "sb";

  const debouncedFilterObj = useDebouncing(filter, DEBOUNCE.delay);

  const initialLoad = useRef(true);

  const currentStage = useSelector((state) => state.decisionTask.currentStage);

  const handleDateRangeChange = (startDate, endDate) => {
    setFilter({
      ...filter,
      ["start_date"]: startDate,
      ["end_date"]: endDate,
    });
    setPageNo(1);
  };

  const getDisableActionsData = () => {
    const clonedData = JSON.parse(JSON.stringify(data));

    clonedData?.table_data?.data?.forEach((item) => {
      // Disable "doc_action" icons
      item?.doc_action?.icons?.forEach((icon) => {
        icon.disabled = true;
      });

      // Disable "options" modal_data
      item.options.disabled = true;
    });

    return clonedData;
  };

  const handleInputChange = (theValue, theSlug) => {
    setFilter((prev) => {
      if (theValue === "") {
        const { [theSlug]: _, ...rest } = prev;
        return rest;
      } else {
        return {
          ...filter,
          [theSlug]: theValue,
        };
      }
    });
    setPageNo(1);
  };

  const onSortOrderClick = (slug, sortOrder) => {
    const order = sortOrder ? (sortOrder === "asc" ? "desc" : "asc") : "desc";
    setOrderByColumn({
      sort_by_col: slug,
      sort_order: order,
    });
  };

  const onTableRowSelection = (id) => {
    if (!rowSelectionState[id]) {
      setData(getDisableActionsData());
      setRowSelectionState((prev) => ({ ...prev, [id]: true }));
    } else {
      setRowSelectionState((prev) => {
        const { [id]: _, ...rest } = prev;
        if (Object.keys(rest).length === 0) {
          setData(originalData.current);
        }
        return rest;
      });
    }
  };

  const onAllTableRowSelection = (rowSelectionState, data) => {
    if (
      Object.keys(rowSelectionState).length <= data.length &&
      Object.keys(rowSelectionState).length !== 0
    ) {
      setRowSelectionState({});
      setData(originalData.current);
    } else {
      let rowState = {};
      data?.map((item) => {
        rowState[item?.id] = true;
      });
      setData(getDisableActionsData());
      setRowSelectionState(rowState);
    }
  };

  const bulkDownload = async () => {
    let selectedRows = Object.keys(rowSelectionState);
    const apiRes = await dispatch(
      apiRequest(endpoints.bulkDownloadDocManagement, {
        ids: selectedRows,
        document_management_type: dataType.current,
      })
    );

    if (apiRes?.success) {
      //todo: uncommnet and use this instead of download document (for later)

      // dispatch(
      //   showLoader({
      //     loaderSuccess: true,
      //     showLogo: true,
      //     message: apiRes?.data?.popup_text,
      //   })
      // );

      downloadDocumentUtility(
        apiRes?.data?.document_link,
        apiRes?.data?.filename
      );
    } else {
      dispatch(showToast(apiRes?.data?.toast));
    }
  };

  const downloadRelatedDocuments = async (id) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.downloadRelatedDocuments, {
        id: id,
        document_management_type: dataType.current,
      })
    );
    if (apiRes?.success) {
      downloadDocumentUtility(
        apiRes?.data?.document_link,
        apiRes?.data?.filename
      );
    } else {
      dispatch(showToast(apiRes?.data?.toast));
    }
  };

  const downloadExcelSummary = async (ids) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.downloadExcelSummary, {
        ids: ids,
        document_management_type: dataType.current,
      })
    );
    if (apiRes?.success) {
      downloadDocumentUtility(
        apiRes?.data?.document_link,
        apiRes?.data?.filename
      );
    } else {
      dispatch(showToast(apiRes?.data?.toast));
    }
  };

  const onSelectFilterAction = (item) => {
    switch (item?.slug) {
      case "clear":
        setRowSelectionState({});
        setData(originalData.current);
        break;
      case "download":
        bulkDownload();
        break;
      case "export":
        downloadExcelSummary(Object.keys(rowSelectionState));
        break;
    }
  };

  const onOptionsFunction = (e, action, id) => {
    switch (action?.slug) {
      case "view_details":
        getDetailsData(id);
        break;
      case "download_related":
        downloadRelatedDocuments(id);
        break;
      case "excel_download":
        downloadExcelSummary([id]);
        break;
    }
  };

  const docAction = async (action, sbId, docId, filename) => {
    const apiRes = await dispatch(
      apiRequest(endpoints.docAction, {
        action: action,
        document_id: docId,
        sb_id: sbId,
        application_id: currentStage?.applicationId,
      })
    );
    if (apiRes?.data?.document_link) {
      if (action === DOCUMENT_ACTION?.["VIEW"]) {
        window.open(apiRes?.data?.document_link, "_blank");
      } else if (action === DOCUMENT_ACTION?.["DOWNLOAD"]) {
        downloadDocumentUtility(
          apiRes?.data?.document_link,
          apiRes?.data?.filename ?? filename
        );
      }
    }
  };

  const onSBDocAction = (
    section,
    actionBtn,
    documentId,
    fileName,
    element_id = ""
  ) => {
    const docAction = async (
      section,
      actionBtn,
      documentId,
      fileName,
      element_id
    ) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          section: section,
          action: actionBtn?.action,
          document_id: documentId,
          sb_id: detailsId,
          application_id: currentStage?.applicationId,
        })
      );
      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn?.action === DOCUMENT_ACTION?.["VIEW"]) {
            window.open(dataRes?.data?.document_link, "_blank");
          } else if (actionBtn?.action === DOCUMENT_ACTION?.["DOWNLOAD"]) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        }
        if (actionBtn?.action === "detach") {
          // toast message
          if (dataRes?.data?.toast) {
            dispatch(showToast(dataRes?.data?.toast));
          }
          // Highlighted Data
          if (dataRes?.data?.summary_data?.highlighted_data_v2) {
            setModalData((prev) => ({
              ...prev,
              details_data: {
                ...prev?.details_data,
                highlighted_data_v2:
                  dataRes?.data?.summary_data?.highlighted_data_v2,
              },
            }));
          }
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
      dispatch(closeAlert());
      setTimeout(() => {
        dispatch(resetAlertData());
      }, [100]);
    };
    if (actionBtn || documentId) {
      docAction(section, actionBtn, documentId, fileName, element_id);
    }

    return docAction;
  };

  const uploadSBSingleFile = (
    selectedFiles,
    doc_type,
    invoiceId,
    metadataTransport = {}
  ) => {
    const metadata = {
      ...metadataTransport,
      flow: "reconciliation",
      sb_id: detailsId,
      invoice_id: invoiceId,
    };
    const formData = new FormData();
    formData.append("file", selectedFiles[0]);
    formData.append("doc_type", doc_type);
    formData.append("metadata", JSON.stringify(metadata));

    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );
      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.summary_data?.highlighted_data_v2) {
          // Highlighted Data
          setModalData((prev) => ({
            ...prev,
            details_data: {
              ...prev?.details_data,
              highlighted_data_v2:
                dataRes?.data?.summary_data?.highlighted_data_v2,
            },
          }));
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    };
    uploadApiCall();
  };

  const getDetailsData = async (id) => {
    const apiRes = await dispatch(
      apiRequest(
        endpoints.getDocumentManagementDetails,
        `?doc_type=${dataType.current}&id=${id}`
      )
    );
    if (apiRes?.success) {
      setModalData(apiRes?.data);
      setShowModal(true);
      setDetailsId(id);
    }
  };

  const getDetailsComponent = () => {
    switch (dataType.current) {
      case "shipping_bill":
        return (
          <SbSummary
            closeModal={() => setShowModal(false)}
            highlightedData={modalData?.details_data?.highlighted_data_v2}
            isSBDetailView={true}
            addScrollEventListeners={false}
            setIsSBDetailView={() => {}}
            documentManagement={true}
            sbType={modalData?.details_data?.sb_type}
            onDocAction={onSBDocAction}
            uploadSingleFile={uploadSBSingleFile}
            closeIcon={modalData?.details_data?.close_icon}
          />
        );
      case "ebrc":
        return (
          <EbrcSummary
            data={modalData?.details_data}
            setShowModal={setShowModal}
          />
        );
    }
  };

  const getDocumentData = async () => {
    const tempFilter = initialLoad.current ? {} : filter;
    const apiRes = await dispatch(
      apiRequest(
        endpoints.getDocumentManagementData,
        `?doc_type=${
          DOCUMENT_MANAGEMENT_TYPE?.[docType]
        }&filters=${JSON.stringify({
          ...tempFilter,
          page: initialLoad.current ? 1 : pageNo ?? 1,
          sort_by_col: initialLoad.current ? null : orderByColumn?.sort_by_col,
          sort_order: initialLoad.current ? null : orderByColumn?.sort_order,
          limit: 50,
        })}`
      )
    );
    if (apiRes?.success) {
      if (docType === PARAM_TYPE[apiRes?.data?.type]) {
        setData(apiRes?.data);
        originalData.current = apiRes?.data;
        dataType.current = apiRes?.data?.type;
      }
    }
    if (initialLoad.current) {
      setPageNo(1);
      setOrderByColumn({});
      setRowSelectionState({});
      setFilter({});
      setShowCompactFilters(false);
    }
    setTimeout(() => {
      initialLoad.current = false;
    }, [DEBOUNCE.delay]);
  };

  useEffect(() => {
    if (!initialLoad.current) {
      getDocumentData();
    }
  }, [debouncedFilterObj, pageNo, orderByColumn]);

  useEffect(() => {
    getDocumentData();
  }, [docType]);

  return (
    <div className="document-management">
      {showModal && (
        <div className="document-management__details-popup-container">
          <ModalV2 slug={"standard"}>{getDetailsComponent()}</ModalV2>
        </div>
      )}
      <p className="document-management__heading">
        {data?.heading?.text}
        <span>{data?.heading?.sub_text}</span>
      </p>
      <div className="document-management__card-container">
        {data?.cards?.map((item, idx) => {
          return (
            <DocManagementCard
              key={idx}
              data={item}
              onClick={(link) => {
                initialLoad.current = true;
                navigate(link);
              }}
            />
          );
        })}
      </div>
      <div className="document-management__data">
        {data?.table_data &&
          (Object.keys(rowSelectionState)?.length === 0 ? (
            <BlunoFilter
              data={data?.table_data?.filters}
              filterObj={filter}
              onClearFilter={() => {
                setFilter({});
                setPageNo(1);
                setShowCompactFilters(false);
              }}
              handleInputChange={handleInputChange}
              handleDateRangeChange={handleDateRangeChange}
              selectedText={
                String(
                  data?.cards?.filter((item) => item?.state === "active")?.[0]
                    ?.text
                ) !== String(data?.table_data?.pagination?.total_results)
                  ? data?.table_data?.pagination?.total_results
                  : ""
              }
              selectedSubText={
                data?.cards?.filter((item) => item?.state === "active")?.[0]
                  ?.hz_text
              }
              showCompactFilters={showCompactFilters}
              setShowCompactFilters={setShowCompactFilters}
            />
          ) : (
            <BlunoSelectFilter
              selected={Object.keys(rowSelectionState)?.length}
              total={data?.table_data?.pagination?.total_results}
              data={data?.table_data?.selected_filter_data}
              onActionFunction={onSelectFilterAction}
            />
          ))}
        {data?.table_data && (
          <div className="document-management__table">
            <BlunoTable
              showHoverBlueRow={false}
              data={data?.table_data}
              onSortOrderClick={onSortOrderClick}
              OptionsComponent={DocManagementMore}
              specialColumnFunction={docAction}
              onTableRowSelection={onTableRowSelection}
              onAllTableRowSelection={onAllTableRowSelection}
              rowSelectionState={rowSelectionState}
              onOptionsFunction={onOptionsFunction}
            />
          </div>
        )}
        {data?.table_data && (
          <div className="bills__pagination">
            <BlunoPagination
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalResults={data?.table_data?.pagination?.total_results}
              resultCount={data?.table_data?.data?.length}
              perPageLimit={data?.table_data?.per_page_limit}
              totalPages={data?.table_data?.pagination?.total_pages}
            />
          </div>
        )}
      </div>
    </div>
  );
}
