//react imports
import React, { useEffect, useRef, useState } from "react";

//redux imports
import { apiRequest } from "../../redux/common/actions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import endpoints from "../../api/endpoints";
import { showToast } from "../../redux/toast/slice";
import { updateShowSupportForm } from "../../redux/common/slice";

//in app imports
import Button from "../../components/Button";
import BlunoTable from "../../components/BlunoTable";
import BlunoFilter from "../../components/BlunoFilter";
import ModalV2 from "../../components/ModalV2";
import RaiseTicketModal from "./RaiseTicketModal";
import BlunoPagination from "../../components/BlunoPagination";
import { DOCUMENT_ACTION } from "../../utils/constants/DocumentConstants";
import { downloadDocumentUtility } from "../../utils/downloadDocument";

const Tickets = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [apiData, setApiData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [filterObj, setFilterObj] = useState({});
  const [newTicketId, setNewTicketId] = useState(null);
  const sortCriteria = useRef({});
  const [isCallGetApi, setIsCallGetApi] = useState(false);
  const [docId, setDocId] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [uploadEnabled, setUploadEnabled] = useState();
  const [showCompactFilters, setShowCompactFilters] = useState(false);

  const handleInputChange = (theValue, theSlug) => {
    setFilterObj((prev) => {
      if (theValue === "") {
        const { [theSlug]: _, ...rest } = prev;
        return rest;
      } else {
        return {
          ...filterObj,
          [theSlug]: theValue,
        };
      }
    });

    setPageNo(1);
  };

  const handleDateRangeChange = (startDate, endDate) => {
    setFilterObj({
      ...filterObj,
      ["start_date"]: startDate,
      ["end_date"]: endDate,
    });
    setPageNo(1);
  };

  const fetchUserTickets = async (sortField, order) => {
    const filters = [
      "ticket_number",
      "ticket_status",
      "start_date",
      "end_date",
    ];

    if (!sortField) {
      sortField = sortCriteria?.current?.field;
    }
    if (!order) {
      order = sortCriteria?.current?.order;
    }
    if (!sortField) {
      sortField = "issue_date";
    }
    if (!order) {
      order = "desc";
    }
    sortCriteria.current = { field: sortField, order: order };

    const params = filters
      .filter((key) => filterObj?.[key])
      .map((key) => `${key}=${filterObj[key]}`)
      .join("&");

    const queryString = `?sort_field=${sortField}&order=${order}&page_no=${pageNo}&${params}`;

    const apiRes = await dispatch(
      apiRequest(endpoints.fetchUserTickets, queryString)
    );
    if (apiRes?.success && apiRes?.data) {
      setApiData(apiRes?.data);
    }
  };

  const onSortOrderClick = (sortCategory, sortOrder) => {
    const order = sortOrder ? (sortOrder === "asc" ? "desc" : "asc") : "desc";
    fetchUserTickets(sortCategory, order);
  };

  const onClearFilter = () => {
    setFilterObj({});
    setShowCompactFilters(false);
  };

  const configData = {
    headers: apiData?.ticket_table_view?.headers,
    data: apiData?.ticket_table_view?.data,
    default_view: apiData?.ticket_table_view?.default_view,
    select_all_supported: apiData?.ticket_table_view?.select_all_supported,
    selection_supported: apiData?.ticket_table_view?.selection_supported,
  };

  const handleButtonClick = (theSlug) => {
    switch (theSlug) {
      case "raise_ticket":
        setShowModal(true);
        break;
      case "need_help":
        dispatch(updateShowSupportForm(true));
        break;
    }
  };

  const uploadSingleFile = (
    selectedFiles,
    doc_type,
    invoiceId = null,
    files = null
  ) => {
    const metadata = {
      flow: "dashboard_ticket",
    };

    const formData = new FormData();
    formData.append("file", selectedFiles?.[0]);
    formData.append("doc_type", doc_type);
    formData.append("metadata", JSON.stringify(metadata));

    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );

      if (dataRes?.success && dataRes?.data) {
        setUploadEnabled(false);

        setUploadedDocuments(
          dataRes?.data?.uploaded_document_info?.[0]?.uploaded_documents
        );
        setDocId(dataRes?.data?.document_id);
      }
    };

    uploadApiCall();
  };

  const onDocAction = (section, actionBtn, documentId, fileName, docType) => {
    const metadata = {
      flow: "dashboard_ticket",
    };

    const docAction = async (
      section,
      actionBtn,
      documentId,
      fileName,
      docType
    ) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          section: section,
          action: actionBtn?.action,
          document_id: documentId,
          document_type: docType,
          metadata: JSON.stringify(metadata),
        })
      );

      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn?.action === DOCUMENT_ACTION?.VIEW) {
            window.open(dataRes?.data?.document_link, "_blank");
          } else if (actionBtn?.action === DOCUMENT_ACTION?.DOWNLOAD) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        }
        if (actionBtn?.action === "detach") {
          setUploadedDocuments([]);
          setUploadEnabled(true);
          setDocId(null);
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    };

    docAction(section, actionBtn, documentId, fileName, docType);
  };

  useEffect(() => {
    sortCriteria.current = {};
    fetchUserTickets();
  }, [filterObj, pageNo, newTicketId, isCallGetApi]);

  useEffect(() => {
    setUploadEnabled(true);
  }, []);

  useEffect(() => {
    if (location?.state?.show_raise_ticket_modal) {
      setShowModal(location?.state?.show_raise_ticket_modal);
    }
    window.history.replaceState({}, "");
  }, [location?.state]);

  return (
    <>
      <div className="b-tickets">
        <div className="b-tickets__top">
          <div className="b-tickets__bar">
            <h1 className="s-sbHeader__text2">{apiData?.title}</h1>
            <div className="b-tickets__buttons">
              {apiData?.buttons?.map((btn) => {
                return (
                  <Button
                    buttonLabel={btn?.text}
                    buttonType={btn?.sub_type}
                    onClick={() => {
                      handleButtonClick(btn?.slug);
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div className="b-tickets__summary">
            {apiData?.ticket_summary?.map((item) => {
              return (
                <div className="b-tickets__summary-card">
                  <img
                    className="b-tickets__summary-icon"
                    src={item?.icon}
                    alt={item?.text}
                  />
                  <div className="b-tickets__summary-right">
                    <p className="b-tickets__summary-count">{item?.count}</p>
                    <hr className="b-tickets__summary-hr" />
                    <p className="b-tickets__summary-text">{item?.text}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="b-tickets__table-cont">
          {apiData?.ticket_table_view?.filters && (
            <div className="b-tickets__filter">
              <BlunoFilter
                data={apiData?.ticket_table_view?.filters}
                filterObj={filterObj}
                handleInputChange={handleInputChange}
                handleDateRangeChange={handleDateRangeChange}
                onClearFilter={onClearFilter}
                // selectedText={
                //   billType === "sb"
                //     ? apiData?.bill_types?.[0]?.total_count
                //     : billType === "irm"
                //     ? apiData?.bill_types?.[1]?.total_count
                //     : apiData?.bill_types?.[2]?.total_count
                // }
                // selectedSubText={
                //   billType === "sb"
                //     ? apiData?.bill_types?.[0]?.sub_text
                //     : billType === "irm"
                //     ? apiData?.bill_types?.[1]?.sub_text
                //     : apiData?.bill_types?.[2]?.sub_text
                // }
                showCompactFilters={showCompactFilters}
                setShowCompactFilters={setShowCompactFilters}
              />
            </div>
          )}
          <div
            className={`b-tickets__table ${
              apiData?.ticket_table_view?.pagination?.total_pages > 1 &&
              "bills__table--border b-tickets__table--pagination"
            }`}
          >
            <BlunoTable
              data={configData}
              onSortOrderClick={onSortOrderClick}
              setGenericState={setIsCallGetApi}
              // OptionsComponent={OptionsComponent}
              // onOptionsFunction={handleOptionsClick}
              // onTableRowSelection={onTableRowSelection}
              // onAllTableRowSelection={onAllTableRowSelection}
              // rowSelectionState={rowSelectionState}
              // showHoverOnSelect={true}
              // specialColumnFunction={handleCustomColumnClick}
              // showHoverBlueRow={billType !== "request"}
            />
          </div>
          {apiData?.ticket_table_view?.pagination?.total_pages > 1 && (
            <div className="bills__pagination">
              <BlunoPagination
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalResults={
                  apiData?.ticket_table_view?.pagination?.total_results
                }
                resultCount={apiData?.ticket_table_view?.data?.length}
                perPageLimit={apiData?.ticket_table_view?.per_page_limit}
                totalPages={apiData?.ticket_table_view?.pagination?.total_pages}
              />
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={
              <RaiseTicketModal
                setShowModal={setShowModal}
                data={apiData?.raise_ticket_model}
                newTicketId={newTicketId}
                setNewTicketId={setNewTicketId}
                uploadSingleFile={uploadSingleFile}
                docId={docId}
                uploadedDocuments={uploadedDocuments}
                setUploadedDocuments={setUploadedDocuments}
                uploadEnabled={uploadEnabled}
                setUploadEnabled={setUploadEnabled}
                onDocAction={onDocAction}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default Tickets;
