import React, { useState } from "react";

import BlunoTooltip from "./BlunoTooltip";

const BlunoIcon = ({
  iconWidth = "24px",
  iconHeight = "24px",
  iconFilterVariant = "",
  showBgOnHOver = true,
  bgWidth = "32px",
  bgHeight = "32px",
  bgColor = "#d6ecff",
  src = "",
  extraClass = "",
  hoverText = "",
  alt = "",
  onClick = () => {},
}) => {
  const [backgroundColor, setBackgroundColor] = useState("");

  const bgStyles = {
    width: bgWidth,
    height: bgHeight,
    background: backgroundColor,
  };

  const imgStyles = {
    width: iconWidth,
    height: iconHeight,
  };

  return (
    <div className={`s-bluno-icon ${extraClass}`}>
      <BlunoTooltip useChildren={true} text={hoverText}>
        <div
          onClick={onClick}
          style={bgStyles}
          className="s-bluno-icon__highlight"
          onMouseEnter={() => {
            showBgOnHOver && setBackgroundColor(bgColor);
          }}
          onMouseLeave={() => {
            showBgOnHOver && setBackgroundColor("");
          }}
        >
          <img
            style={imgStyles}
            className={`s-bluno-icon__icon s-bluno-icon__icon--${iconFilterVariant}`}
            src={src}
            alt={alt}
          />
        </div>
      </BlunoTooltip>
    </div>
  );
};

export default BlunoIcon;
