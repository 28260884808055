// react imports
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../../redux/common/actions";
import endpoints from "../../api/endpoints";

// third-party imports

// in-app imports
import BlunoTable from "../../components/BlunoTable";
import BulkInfoAction from "../../components/bulkInfo/BulkInfoAction";
import BlunoPagination from "../../components/BlunoPagination";
import BlunoSelectFilter from "../../components/BlunoSelectFilter";
import BlunoFilter from "../../components/BlunoFilter";
import DocManagementMore from "../../components/DocumentManagement/DocManagementMore";
import ModalV2 from "../../components/ModalV2";
import BulkActionModal from "../../components/bulkInfo/BulkActionModal";
import useDebouncing from "../../hooks/useDebouncing";
import { DEBOUNCE } from "../../app/constants/common";
import {
  BULK_ACTIONS,
  BULK_MODAL_SCREEN_TYPE,
  BULK_TABLE_ROW_ACTION,
} from "../../utils/constants/BulkConstants";

const BulkInformation = () => {
  // defined to access react based apis
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // local states
  const [apiData, setApiData] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [filterObj, setFilterObj] = useState({});
  const [showCompactFilters, setShowCompactFilters] = useState(false);
  const [rowSelectionState, setRowSelectionState] = useState({});

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [modalScreenType, setModalScreenType] = useState();
  const [bank, setBank] = useState();
  const [bulkId, setBulkId] = useState();
  const initialAPILock = useRef(true);

  // custom hooks
  const debouncedFilterObj = useDebouncing(filterObj, DEBOUNCE.delay);

  // refs
  const sortCriteria = useRef({});

  const handleInputChange = (theValue, theSlug) => {
    setFilterObj((prev) => {
      if (theValue === "") {
        const { [theSlug]: _, ...rest } = prev;
        return rest;
      } else {
        return {
          ...filterObj,
          [theSlug]: theValue,
        };
      }
    });

    setPageNo(1);
  };

  const handleDateRangeChange = (startDate, endDate) => {
    setFilterObj({
      ...filterObj,
      ["start_date"]: startDate,
      ["end_date"]: endDate,
    });
    setPageNo(1);
  };

  const onSortOrderClick = (sortCategory, sortOrder) => {
    const order = sortOrder ? (sortOrder === "asc" ? "desc" : "asc") : "desc";
    fetchBulkInformation(sortCategory, order);
  };

  const fetchBulkAction = async (actionType) => {
    const queryString = `?action_type=${actionType}`;
    const dataRes = await dispatch(
      apiRequest(endpoints.fetchBulkAction, queryString)
    );
    if (dataRes?.success && dataRes?.data) {
      if (location?.state?.show_bank_popup) {
        setModalScreenType(BULK_MODAL_SCREEN_TYPE?.SELECTED_BANK_ACTIONS);
        setModalData(dataRes?.data?.bulk_bank_actions?.[location?.state?.bank]);
        setShowModal(true);
      } else {
        setModalScreenType(BULK_MODAL_SCREEN_TYPE?.BILL_LODGEMENT);
        setModalData(dataRes?.data);
        setShowModal(true);
      }
    }
  };

  const fetchBulkBillLodgementDataSummary = async (bulkId, bankName) => {
    const pathQueryString = `/${bulkId}?action_type=export_bill_lodgement&bank=${bankName}&show_table_data=false`;
    setBank(bankName);
    setBulkId(bulkId);
    const dataRes = await dispatch(
      apiRequest(endpoints.fetchBulkBillLodgement, pathQueryString)
    );
    if (dataRes?.success && dataRes?.data) {
      setModalData(dataRes?.data?.bulk_action_declaration);
      setModalScreenType(BULK_MODAL_SCREEN_TYPE?.BULK_SUMMARY_VIEW);
    }
  };

  const onSubmitActionClick = (action) => {
    let selectedIds = Object.keys(rowSelectionState);
    const onAction = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.submitBulkAction, {
          action_type: action?.slug,
          selected_ids: selectedIds,
          bank_name: bank,
          id: bulkId,
        })
      );
      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.success_modal) {
          setModalScreenType(BULK_MODAL_SCREEN_TYPE?.SUCCESS_POPUP);
          setModalData(dataRes?.data?.success_modal);
          setShowModal(true);
        }
      }
    };
    onAction();
  };

  const fetchBulkRemindTrail = async () => {
    const dataRes = await dispatch(apiRequest(endpoints.getBulkRemindTrail));
    if (dataRes?.success && dataRes?.data) {
      setModalScreenType(BULK_MODAL_SCREEN_TYPE?.REMINDER_HISTORY);
      setModalData(dataRes?.data);
      setShowModal(true);
    }
  };

  const onBulkActionClick = (action) => {
    switch (action?.slug) {
      case BULK_ACTIONS?.BILL_LODGEMENT:
        fetchBulkAction(action?.slug);
        break;
    }
  };

  const onOptionsFunction = (e, action, id, inputData) => {
    const onMakerCheckerAction = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.userPostBulkAction, {
          id: id,
          action: action?.slug,
          reason: inputData?.["reason"],
          document_id: inputData?.["document_id"],
        })
      );

      if (dataRes?.success) {
        fetchBulkInformation();
      }
    };

    switch (action?.slug) {
      case "view_details":
        navigate(action?.deeplink);
        break;
      case BULK_TABLE_ROW_ACTION?.WITHDRAW:
      case BULK_TABLE_ROW_ACTION?.REMIND:
      case BULK_TABLE_ROW_ACTION?.ACCEPT:
      case BULK_TABLE_ROW_ACTION?.REJECT:
        onMakerCheckerAction();
        break;
      case BULK_TABLE_ROW_ACTION?.DOWNLOAD:
        break;
      case BULK_TABLE_ROW_ACTION?.REMINDER_HISTORY:
        fetchBulkRemindTrail();
        break;
    }
  };

  const onTableActionButtonClick = async (
    slug,
    sbId,
    docId,
    filename,
    popupData
  ) => {
    switch (slug) {
      case BULK_TABLE_ROW_ACTION?.VIEW:
        setModalScreenType(BULK_MODAL_SCREEN_TYPE?.BULK_SUMMARY_VIEW);
        setModalData(popupData?.[slug]);
        setShowModal(true);
        break;
    }
  };

  const fetchBulkInformation = async (sortField, order) => {
    const filters = ["bulk_id", "request_type", "start_date", "end_date"];
    if (!sortField) {
      sortField = sortCriteria?.current?.field;
    }
    if (!order) {
      order = sortCriteria?.current?.order;
    }
    if (!sortField) {
      sortField = "request_date";
    }
    if (!order) {
      order = "desc";
    }
    sortCriteria.current = { field: sortField, order: order };

    const params = filters
      .filter((key) => filterObj?.[key])
      .map((key) => `${key}=${filterObj[key]}`)
      .join("&");

    const queryString = `?sort_field=${sortField}&order=${order}&page_no=${pageNo}&${params}`;

    const dataRes = await dispatch(
      apiRequest(endpoints.fetchBulkInfo, queryString)
    );
    if (dataRes?.success && dataRes?.data) {
      setApiData(dataRes.data);
    }
  };

  const initialAPI = async () => {
    await fetchBulkInformation();
    if (location?.state?.slug) {
      await fetchBulkAction(location?.state?.slug);
    }
    initialAPILock.current = false;
  };

  useEffect(() => {
    if (!initialAPILock.current) fetchBulkInformation();
  }, [debouncedFilterObj, pageNo]);

  useEffect(() => {
    if (location?.state?.show_bank_popup) {
      fetchBulkAction(location?.state?.action_type);
    }
    window.history.replaceState({}, "");
  }, [location?.state]);

  useEffect(() => {
    initialAPI();
  }, []);

  return (
    <>
      {showModal && (
        <div className="manage-settings__add-user-modal-bg">
          <ModalV2 slug={"standard"}>
            <BulkActionModal
              data={modalData}
              setShowModal={setShowModal}
              setModalData={setModalData}
              modalScreenType={modalScreenType}
              setModalScreenType={setModalScreenType}
              onActionClick={onSubmitActionClick}
              fetchBulkAction={fetchBulkAction}
              fetchBulkBillLodgementDataSummary={
                fetchBulkBillLodgementDataSummary
              }
              refreshPageData={fetchBulkInformation}
            />
          </ModalV2>
        </div>
      )}
      <div className="bulk-info">
        <div className="bulk-info__action">
          <BulkInfoAction
            data={apiData?.bulk_actions}
            onBulkActionClick={onBulkActionClick}
          />
        </div>
        <div className="bulk-info__table-container">
          {apiData?.table_data &&
            (Object.keys(rowSelectionState)?.length === 0 ? (
              <BlunoFilter
                data={apiData?.table_data?.filters}
                filterObj={filterObj}
                onClearFilter={() => {
                  setFilterObj({});
                  setPageNo(1);
                  setShowCompactFilters(false);
                }}
                handleInputChange={handleInputChange}
                handleDateRangeChange={handleDateRangeChange}
                selectedText={apiData?.table_data?.pagination?.total_results}
                // selectedSubText={
                //   data?.cards?.filter((item) => item?.state === "active")?.[0]
                //     ?.hz_text
                // }
                showCompactFilters={showCompactFilters}
                setShowCompactFilters={setShowCompactFilters}
              />
            ) : (
              <BlunoSelectFilter
                selected={Object.keys(rowSelectionState)?.length}
                total={apiData?.table_data?.pagination?.total_results}
                data={apiData?.table_data?.selected_filter_data}
              />
            ))}
          {apiData?.table_data && (
            <div className="bulk-info__table">
              <BlunoTable
                showHoverBlueRow={false}
                data={apiData?.table_data}
                onSortOrderClick={onSortOrderClick}
                rowSelectionState={rowSelectionState}
                specialColumnFunction={onTableActionButtonClick}
                onOptionsFunction={onOptionsFunction}
                OptionsComponent={DocManagementMore}
              />
            </div>
          )}
          {apiData?.table_data && (
            <div className="bills__pagination">
              <BlunoPagination
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalResults={apiData?.table_data?.pagination?.total_results}
                resultCount={apiData?.table_data?.data?.length}
                perPageLimit={apiData?.table_data?.per_page_limit}
                totalPages={apiData?.table_data?.pagination?.total_pages}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BulkInformation;
