// react imports
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

// redux imports
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";
import { showToast } from "../redux/toast/slice";
import { showAlert, closeAlert, resetAlertData } from "../redux/alert/slice";

// third-party imports

// in-app imports
import SbSummary from "../modules/SingleSB/SbSummary";
import DocSummary from "../components/DocSummary";
import ReconcillationForm from "../components/ReconcillationForm";
import MapIRMSummary from "../components/MapIRMSummary";
import TimelineCard from "../components/Timeline";
import { showLoader } from "../redux/loader/slice";
import { loaderConfig } from "../app/constants/LoaderConfig";
import { downloadDocumentUtility } from "../utils/downloadDocument";
import { DOCUMENT_ACTION } from "../utils/constants/DocumentConstants";
import PostRecon from "../components/PostRecon";
import { elements } from "chart.js";
import RejectSummary from "../modules/SingleSB/RejectSummary";
import circular_cross from "../assets/media/common/circular_cross.png";
import BlunoLogo from "../assets/media/onboarding/Bluno Logo_S 1.png";
import ModalV2 from "../components/ModalV2";
import { FormErrorMessage } from "../components/DynamicForm/formValidation/FormErrorMesage";
import useDebouncing from "../hooks/useDebouncing";
import { REFRESH_IRM_ALERT } from "../app/constants/common";
import {
  BILL_LODGEMENT_DUMMY,
  BILL_LODGEMENT_IN_PROGRESS_DUMMY,
} from "../modules/landing/constants";
import BillLodgementStage from "../components/BillLodgementStage";
import PaymentAwaitedModal from "../components/ReconcillationForm/PaymentAwaitedModal";
import { RECON_ACTION } from "../utils/constants/ExportBillsConstants";

const NewRecon = () => {
  // defined to access react based APIs
  const dispatch = useDispatch();
  const { sbId } = useParams();

  // redux state variables
  const onAlertProceed = useSelector((state) => state.alert.isAlertOk);
  const alertPropsList = useSelector((state) => state.alert.alertProps);
  const currentStage = useSelector((state) => state.decisionTask.currentStage);

  // Section wise local state variables
  const [highlightedData, setHighlightedData] = useState();
  const [timelineData, setTimelineData] = useState();
  const [sbDocs, setSbDocs] = useState();
  const [billLodgementData, setBillLodgementData] = useState();
  const [mapIRMData, setMapIRMData] = useState();
  const [reconciliationData, setReconciliationData] = useState();
  const [postReconData, setPostReconData] = useState();
  const [docId, setDocId] = useState();
  const [uploadedDocumentIds, setUploadedDocumentIds] = useState({});
  const [rejectData, setRejectData] = useState();
  const [isSBDetailView, setIsSBDetailView] = useState(false);
  const [summaryErrorMessage, setSummaryErrorMessage] = useState({});
  const [sbType, setSbType] = useState("");
  const [rowFormData, setRowFormData] = useState({});
  const [undebouncedRowFormData, setUndebouncedRowFormData] = useState({});
  const [showOtherIrms, setShowOtherIrms] = useState(false);
  const [irmFilter, setIrmFilter] = useState("all_irms");
  const [selectedIRMs, setSelectedIRMs] = useState({});
  const [blSubmitValues, setBlSubmitValues] = useState([]);

  // Alert State Variables
  const [alertInfoMap, setAlertInfoMap] = useState();
  const [showModal, setShowModal] = useState();
  const [paymentAwaitedModal, setPaymentAwaitedModal] = useState();
  const [modalData, setModalData] = useState();
  const [isIrmAddedManually, setIsIrmAddedManually] = useState(false);
  const [toggleGetApiCall, setToggleGetApiCall] = useState(false);

  // other variables
  const newReconRef = useRef(null);
  const newReconSummaryRef = useRef(null);
  const formData = new FormData();
  const enableMapIRMRowFormDataDebouncing = useRef(false);
  const debouncedMapIRMRow = useDebouncing(rowFormData, 2000);
  const previousMapIrmRowData = useRef({});
  const undebouncedRowFormDataRef = useRef({});
  const countUpdateIRMs = useRef(0);
  const changedIRMBankChargesIdKeys = useRef([]);

  const getReconData = async (maintainSelectedIRMState = false) => {
    const dataRes = await dispatch(
      apiRequest(
        endpoints.getNewReconData,
        `/${sbId}/data?application_id=${currentStage?.applicationId}&show_other_irms=${showOtherIrms}&show_filtered_other_irms=${showOtherIrms}`
      )
    );

    if (dataRes?.success && dataRes?.data) {
      // SB Type
      if (dataRes?.data?.sb_type) {
        setSbType(dataRes?.data?.sb_type);
      }
      // Highlighted data
      if (dataRes?.data?.highlighted_data_v2) {
        setHighlightedData(dataRes?.data?.highlighted_data_v2);
      }
      //Reject Data
      if (dataRes?.data?.reject_data) {
        setRejectData(dataRes?.data?.reject_data);
      }
      // Timeline Data
      if (dataRes?.data?.recon_stages) {
        setTimelineData(dataRes?.data?.recon_stages);
      }
      // SB Docs Data
      if (dataRes?.data?.recon_section?.sb_docs) {
        setSbDocs(dataRes?.data?.recon_section?.sb_docs);
      }

      // Bill Lodgement Data
      if (dataRes?.data?.recon_section?.bill_lodgement) {
        setBillLodgementData(dataRes?.data?.recon_section?.bill_lodgement);
      }

      // MapIRM Data
      if (dataRes?.data?.recon_section?.map_irms) {
        if (maintainSelectedIRMState) {
          let updatedIrmData =
            dataRes?.data?.recon_section?.map_irms?.irms?.data?.map((item) => {
              let isSelected = selectedIRMs?.irms?.some(
                (selectedIrm) => selectedIrm?.id === item?.id
              );
              return {
                ...item,
                is_selected: isSelected,
              };
            });
          setMapIRMData({
            ...dataRes?.data?.recon_section?.map_irms,
            irms: {
              ...dataRes?.data?.recon_section?.map_irms?.irms,
              data: updatedIrmData,
            },
          });
        } else {
          setMapIRMData(dataRes?.data?.recon_section?.map_irms);
        }
        let tempRowFormData = {};
        let selectedIRMList = [];
        dataRes?.data?.recon_section?.map_irms?.irms?.data?.forEach(
          (item, idx) => {
            for (const [key, value] of Object.entries(item)) {
              if (value.type === "text_box") {
                tempRowFormData[item?.id] = tempRowFormData[item?.id] || {};
                tempRowFormData[item?.id][value?.slug] = {
                  value: value?.default_value,
                  currency: value?.title,
                };
              }
            }
            if (item?.is_selected) {
              selectedIRMList = [
                ...selectedIRMList,
                {
                  id: item?.id,
                  attached_amount: item?.fcy_attached_amount?.text,
                  currency: item?.fcy_currency?.text,
                },
              ];
            }
          }
        );
        if (showOtherIrms) {
          setIrmFilter("show_filtered_other_irms");
        }

        if (!showOtherIrms) {
          setSelectedIRMs({
            irms: selectedIRMList,
          });
        }

        previousMapIrmRowData.current = tempRowFormData;
        enableMapIRMRowFormDataDebouncing.current = "updating";
        setRowFormData(tempRowFormData);
      }
      // Reconciliation Data
      if (dataRes?.data?.recon_section?.reconciliation) {
        setReconciliationData(dataRes?.data?.recon_section?.reconciliation);
      }
      // Post Recon Data
      if (dataRes?.data?.post_reconciliation_data) {
        setPostReconData(dataRes?.data?.post_reconciliation_data);
      }
      // Alert Info Map
      if (dataRes?.data?.alert_info) {
        setAlertInfoMap(dataRes?.data?.alert_info);
      }
    }
  };

  const uploadSingleFile = (
    selectedFiles,
    doc_type,
    invoiceId,
    metadataTransport = {}
  ) => {
    const metadata = {
      ...metadataTransport,
      flow: "reconciliation",
      sb_id: sbId,
      invoice_id: invoiceId,
    };
    formData.append("file", selectedFiles[0]);
    formData.append("doc_type", doc_type);
    formData.append("metadata", JSON.stringify(metadata));

    const uploadApiCall = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.uploadFile, formData, null, {
          headers: { "Content-Type": "multipart/form-data" },
        })
      );
      if (dataRes?.success && dataRes?.data) {
        if (invoiceId) {
          setSummaryErrorMessage((prev) => {
            let { [invoiceId]: leave, ...temp } = prev;
            return temp;
          });
        } else if (doc_type === "TRANSPORT_DOC") {
          setSummaryErrorMessage((prev) => {
            let { [doc_type]: leave, ...temp } = prev;
            return temp;
          });
        }
        if (dataRes?.data?.summary_data?.highlighted_data_v2) {
          // Highlighted Data
          setHighlightedData(dataRes?.data?.summary_data?.highlighted_data_v2);
        }
        //Reject Data
        if (dataRes?.data?.summary_data?.reject_data) {
          setRejectData(dataRes?.data?.summary_data?.reject_data);
        }
        // Timeline Data
        if (dataRes?.data?.summary_data?.recon_stages) {
          setTimelineData(dataRes?.data?.summary_data?.recon_stages);
        }
        // SB Docs Data
        if (dataRes?.data?.summary_data?.recon_section?.sb_docs) {
          setSbDocs(dataRes?.data?.summary_data?.recon_section?.sb_docs);
        }

        // Bill Lodgement Data
        if (dataRes?.data?.summary_data?.recon_section?.bill_lodgement) {
          setBillLodgementData(
            dataRes?.data?.summary_data?.recon_section?.bill_lodgement
          );
        }

        // MapIRM Data
        if (dataRes?.data?.summary_data?.recon_section?.map_irms) {
          setMapIRMData(dataRes?.data?.summary_data?.recon_section?.map_irms);
          let tempRowFormData = {};
          dataRes?.data?.summary_data?.recon_section?.map_irms?.irms?.data?.forEach(
            (item, idx) => {
              for (const [key, value] of Object.entries(item)) {
                if (value.type === "text_box") {
                  tempRowFormData[item?.id] = tempRowFormData[item?.id] || {};
                  tempRowFormData[item?.id][value?.slug] = {
                    value: value?.default_value,
                    currency: value?.title,
                  };
                }
              }
            }
          );
          previousMapIrmRowData.current = tempRowFormData;
          enableMapIRMRowFormDataDebouncing.current = "updating";
          setRowFormData(tempRowFormData);
        }
        // Reconciliation Data
        if (dataRes?.data?.summary_data?.recon_section?.reconciliation) {
          setReconciliationData(
            dataRes?.data?.summary_data?.recon_section?.reconciliation
          );
        }
        if (dataRes?.data?.summary_data?.post_reconciliation_data) {
          setPostReconData(
            dataRes?.data?.summary_data?.post_reconciliation_data
          );
        }
        if (dataRes?.data?.summary_data?.alert_info) {
          // Alert Info Map
          setAlertInfoMap((prev) => ({
            ...prev,
            ...dataRes?.data?.summary_data?.alert_info,
          }));
        }
        // Uploaded Document Id
        if (dataRes?.data?.document_id) {
          setDocId(dataRes?.data?.document_id);
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
    };
    uploadApiCall();
  };

  const onDocAction = (
    section,
    actionBtn,
    documentId,
    fileName,
    element_id = ""
  ) => {
    const docAction = async (
      section,
      actionBtn,
      documentId,
      fileName,
      element_id
    ) => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          section: section,
          action: actionBtn?.action,
          document_id: documentId,
          sb_id: sbId,
          application_id: currentStage?.applicationId,
        })
      );
      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          if (actionBtn?.action === DOCUMENT_ACTION?.["VIEW"]) {
            window.open(dataRes?.data?.document_link, "_blank");
          } else if (actionBtn?.action === DOCUMENT_ACTION?.["DOWNLOAD"]) {
            downloadDocumentUtility(dataRes?.data?.document_link, fileName);
          }
        }
        if (actionBtn?.action === "detach") {
          setUploadedDocumentIds((prev) => {
            delete prev?.[element_id];
            return prev;
          });
          // toast message
          if (dataRes?.data?.toast) {
            dispatch(showToast(dataRes?.data?.toast));
          }
          // Highlighted Data
          if (dataRes?.data?.summary_data?.highlighted_data_v2) {
            setHighlightedData(
              dataRes?.data?.summary_data?.highlighted_data_v2
            );
          }
          //Reject Data
          if (dataRes?.data?.summary_data?.reject_data) {
            setRejectData(dataRes?.data?.summary_data?.reject_data);
          }
          // Timeline Data
          if (dataRes?.data?.summary_data?.recon_stages) {
            setTimelineData(dataRes?.data?.summary_data?.recon_stages);
          }
          // SB Docs Data
          if (dataRes?.data?.summary_data?.recon_section?.sb_docs) {
            setSbDocs(dataRes?.data?.summary_data?.recon_section?.sb_docs);
          }

          // Bill Lodgement Data
          if (dataRes?.data?.summary_data?.recon_section?.bill_lodgement) {
            setBillLodgementData(
              dataRes?.data?.summary_data?.recon_section?.bill_lodgement
            );
          }

          // MapIRM Data
          if (dataRes?.data?.summary_data?.recon_section?.map_irms) {
            setMapIRMData(dataRes?.data?.summary_data?.recon_section?.map_irms);
            let tempRowFormData = {};
            dataRes?.data?.summary_data?.recon_section?.map_irms?.irms?.data?.forEach(
              (item, idx) => {
                for (const [key, value] of Object.entries(item)) {
                  if (value.type === "text_box") {
                    tempRowFormData[item?.id] = tempRowFormData[item?.id] || {};
                    tempRowFormData[item?.id][value?.slug] = {
                      value: value?.default_value,
                      currency: value?.title,
                    };
                  }
                }
              }
            );
            previousMapIrmRowData.current = tempRowFormData;
            enableMapIRMRowFormDataDebouncing.current = "updating";
            setRowFormData(tempRowFormData);
          }
          // Reconciliation Data
          if (dataRes?.data?.summary_data?.recon_section?.reconciliation) {
            setReconciliationData(
              dataRes?.data?.summary_data?.recon_section?.reconciliation
            );
          }
          if (dataRes?.data?.summary_data?.post_reconciliation_data) {
            setPostReconData(
              dataRes?.data?.summary_data?.post_reconciliation_data
            );
          }
          // Alert Info Map
          if (dataRes?.data?.summary_data?.alert_info) {
            setAlertInfoMap((prev) => ({
              ...prev,
              ...dataRes?.data?.summary_data?.alert_info,
            }));
          }
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
      dispatch(closeAlert());
      setTimeout(() => {
        dispatch(resetAlertData());
      }, [100]);
    };
    if (actionBtn?.alert_id) {
      dispatch(
        showAlert({
          items: alertInfoMap?.[actionBtn?.alert_id],
          props: [
            "doc-action",
            section,
            actionBtn,
            documentId,
            fileName,
            element_id,
          ],
        })
      );
    } else if (actionBtn || documentId) {
      docAction(section, actionBtn, documentId, fileName, element_id);
    }

    return docAction;
  };

  const onEditSection = (section, action, alertId) => {
    const onEdit = async (section, action) => {
      let dataRes;
      if (action?.slug === "edit_post_recon_docs") {
        dataRes = await dispatch(
          apiRequest(
            endpoints.editPostReconDocs,
            {
              section: section,
              action: action,
            },
            `/${sbId}/data/post-recon/edit?allow_edit=true`
          )
        );
      } else {
        dataRes = await dispatch(
          apiRequest(
            endpoints.editReconSection,
            {
              section: section,
              action: action,
            },
            `/${sbId}/data/enable-edit`
          )
        );
      }
      if (dataRes?.success && dataRes?.data) {
        // toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
        // Highlighted Data
        if (dataRes?.data?.summary_data?.highlighted_data_v2) {
          setHighlightedData(dataRes?.data?.summary_data?.highlighted_data_v2);
        } else {
          setHighlightedData(null);
        }
        //Reject Data
        if (dataRes?.data?.summary_data?.reject_data) {
          setRejectData(dataRes?.data?.summary_data?.reject_data);
        } else {
          setRejectData(null);
        }
        // Timeline Data
        if (dataRes?.data?.summary_data?.recon_stages) {
          setTimelineData(dataRes?.data?.summary_data?.recon_stages);
        } else {
          setTimelineData(null);
        }
        // SB Docs Data
        if (dataRes?.data?.summary_data?.recon_section?.sb_docs) {
          setSbDocs(dataRes?.data?.summary_data?.recon_section?.sb_docs);
        } else {
          setSbDocs(null);
        }

        // Bill Lodgement Data
        if (dataRes?.data?.summary_data?.recon_section?.bill_lodgement) {
          setBillLodgementData(
            dataRes?.data?.summary_data?.recon_section?.bill_lodgement
          );
        }

        // MapIRM Data
        if (dataRes?.data?.summary_data?.recon_section?.map_irms) {
          setMapIRMData(dataRes?.data?.summary_data?.recon_section?.map_irms);
          let tempRowFormData = {};
          dataRes?.data?.summary_data?.recon_section?.map_irms?.irms?.data?.forEach(
            (item, idx) => {
              for (const [key, value] of Object.entries(item)) {
                if (value.type === "text_box") {
                  tempRowFormData[item?.id] = tempRowFormData[item?.id] || {};
                  tempRowFormData[item?.id][value?.slug] = {
                    value: value?.default_value,
                    currency: value?.title,
                  };
                }
              }
            }
          );
          previousMapIrmRowData.current = tempRowFormData;
          enableMapIRMRowFormDataDebouncing.current = "updating";
          setRowFormData(tempRowFormData);
        } else {
          setMapIRMData(null);
        }
        // Reconciliation Data
        if (dataRes?.data?.summary_data?.recon_section?.reconciliation) {
          setReconciliationData(
            dataRes?.data?.summary_data?.recon_section?.reconciliation
          );
        } else {
          setReconciliationData(null);
        }
        if (dataRes?.data?.summary_data?.post_reconciliation_data) {
          setPostReconData(
            dataRes?.data?.summary_data?.post_reconciliation_data
          );
        } else {
          setPostReconData(null);
        }
        // Alert Info Map
        if (dataRes?.data?.summary_data?.alert_info) {
          setAlertInfoMap((prev) => ({
            ...prev,
            ...dataRes?.data?.summary_data?.alert_info,
          }));
        } else {
          setAlertInfoMap(null);
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
      dispatch(closeAlert());
      setTimeout(() => {
        dispatch(resetAlertData());
      }, [100]);
    };
    if (alertId) {
      dispatch(
        showAlert({
          items: alertInfoMap?.[alertId],
          props: ["edit-section", section, action],
        })
      );
    } else if (section || action?.slug === "edit_post_recon_docs") {
      onEdit(section, action);
    }

    return onEdit;
  };

  const onActionClick = async (section, action, row, elementsToApi) => {
    if (
      action?.slug === "fetch_irms" ||
      action?.slug === "fetch_eligible_irms"
    ) {
      dispatch(showLoader(loaderConfig.fetchIRM));
    }
    if (action?.slug === "submit") {
      let pendingDocs = false;
      let errors = {};
      highlightedData?.invoice_details?.invoices?.forEach((item) => {
        item?.container_data?.forEach((item1) => {
          if (item1?.data?.document?.uploaded_documents?.length === 0) {
            pendingDocs = true;
            errors[item1?.data?.id] = FormErrorMessage?.EMPTY_INPUT?.text;
          }
        });
      });
      highlightedData?.transport_details?.data?.forEach((item) => {
        if (item?.document?.uploaded_documents?.length === 0) {
          pendingDocs = true;
          errors[item?.document?.slug] = FormErrorMessage?.EMPTY_INPUT?.text;
        }
      });
      setSummaryErrorMessage(errors);
      if (pendingDocs) {
        if (newReconSummaryRef?.current?.style?.position !== undefined)
          newReconSummaryRef.current.style.position = "relative";
        await newReconSummaryRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        setTimeout(() => {
          setIsSBDetailView(true);
        }, [200]);
        return;
      }
      dispatch(showLoader(loaderConfig.submitToBank));
    }
    let bodyData;
    switch (section) {
      case "sb_docs":
        bodyData = {};
        break;
      case "map_irms":
        //payment awaited case
        if (action?.slug === RECON_ACTION?.STANDARD_SB_PAYMENT_AWAITED) {
          setPaymentAwaitedModal(true);
          return;
        }
        //other cases in map_irms
        else {
          const selectedIRMs = mapIRMData?.irms?.data
            ?.map((item) => {
              if (action?.slug === "simulation") {
                return (item?.is_selected && item?.id !== row?.id) ||
                  (item?.id === row?.id && !row?.is_selected)
                  ? {
                      id: item?.id,
                      attached_amount: item?.fcy_attached_amount?.text,
                      currency: item?.fcy_currency?.text,
                    }
                  : null;
              } else {
                return item?.is_selected
                  ? {
                      id: item?.id,
                      attached_amount: item?.fcy_attached_amount?.text,
                      currency: item?.fcy_currency?.text,
                    }
                  : null;
              }
            })
            .filter((item) => item !== null);

          bodyData = {
            irms: selectedIRMs,
          };

          if (action?.slug === "simulation") {
            setSelectedIRMs(bodyData);
          }
        }
        break;
      case "reconciliation":
        bodyData = {
          user_input: elementsToApi,
        };
        break;
      case "bill_lodgement":
        bodyData = {
          user_input: blSubmitValues,
          bill_lodgement_state: billLodgementData?.bill_lodgement_state,
        };
    }

    const onAction = async (section, action, bodyData) => {
      let dataRes = undefined;
      if (action?.slug === "notify_admin") {
        dataRes = await dispatch(
          apiRequest(
            endpoints.notifyAdminSbClosure,
            { section: section, action: "save_as_draft", data: bodyData },
            `/${sbId}/notify`
          )
        );
      } else if (action?.slug === "remind") {
        dataRes = await dispatch(
          apiRequest(endpoints.remindSB, { ids: [sbId] })
        );
      } else if (action?.slug === "send_to_bank") {
        dataRes = await dispatch(
          apiRequest(endpoints.submitSbToBank, {
            ids: [sbId],
            send_data: true,
          })
        );
      } else if (action?.slug === "edit") {
        dataRes = await dispatch(
          apiRequest(
            endpoints.editSb,
            null,
            `${sbId}/edit/true?application_id=${currentStage?.applicationId}`
          )
        );
      } else if (action?.slug === "reject") {
        dataRes = await dispatch(
          apiRequest(endpoints.returnSbToMaker, {
            id_reason_map: { [sbId]: elementsToApi },
            send_data: true,
          })
        );
      } else if (action?.slug === "fetch_sb_recon_status") {
        dataRes = await dispatch(
          apiRequest(endpoints.fetchSBReconStatus, `/${sbId}/status/check`)
        );
      } else if (action?.slug === "refresh_irm") {
        dataRes = await dispatch(apiRequest(endpoints.refreshIRMData));
        if (dataRes?.success) {
          dispatch(showToast(REFRESH_IRM_ALERT));
        }
      } else if (action?.slug === "generate_req_letter") {
        dataRes = await dispatch(
          apiRequest(
            endpoints.editPostReconDocs,
            {
              section: section,
              action: action,
            },
            `/${sbId}/data/post-recon/edit?allow_edit=false`
          )
        );
      } else if (action?.slug === "initiate_bill_lodgement") {
        dataRes = await dispatch(
          apiRequest(
            endpoints.initiateBillLodgement,
            {},
            `/${sbId}/bill-lodgement/initiate`
          )
        );
      } else {
        dataRes = await dispatch(
          apiRequest(
            endpoints.submitAction,
            {
              section: section,
              action:
                action?.slug === "irm_filter_change"
                  ? "simulation"
                  : action?.slug,
              data:
                action?.slug === "irm_filter_change" ? selectedIRMs : bodyData,
            },
            `/${sbId}/data/submit?application_id=${
              currentStage?.applicationId
            }&show_other_irms=${showOtherIrms}&show_filtered_other_irms=${
              action?.irm_filter === "show_filtered_other_irms" ? true : false
            }&show_filtered_eligible_irms=${
              action?.irm_filter === "show_filtered_eligible_irms"
                ? true
                : false
            }`
          )
        );
      }
      if (dataRes?.success && dataRes?.data) {
        // toast message
        if (dataRes?.data?.toast) {
          if (action?.slug !== "submit") {
            setModalData({
              text: dataRes?.data?.toast?.data?.center?.text,
              icon: BlunoLogo,
            });
            setShowModal(true);
          }
        }

        // Highlighted Data
        if (dataRes?.data?.summary_data?.highlighted_data_v2) {
          setHighlightedData(dataRes?.data?.summary_data?.highlighted_data_v2);
        } else if (
          dataRes?.data?.summary_data?.components_to_remove?.highlighted_data_v2
        ) {
          setHighlightedData(null);
        }

        //Reject Data
        if (dataRes?.data?.summary_data?.reject_data) {
          setRejectData(dataRes?.data?.summary_data?.reject_data);
        } else if (
          dataRes?.data?.summary_data?.components_to_remove?.reject_data
        ) {
          setRejectData(null);
        }

        // Timeline Data
        if (dataRes?.data?.summary_data?.recon_stages) {
          setTimelineData(dataRes?.data?.summary_data?.recon_stages);
        } else if (
          dataRes?.data?.summary_data?.components_to_remove?.recon_stages
        ) {
          setTimelineData(null);
        }

        // SB Docs Data
        if (dataRes?.data?.summary_data?.recon_section?.sb_docs) {
          setSbDocs(dataRes?.data?.summary_data?.recon_section?.sb_docs);
        } else if (dataRes?.data?.summary_data?.components_to_remove?.sb_docs) {
          setSbDocs(null);
        }

        // Bill Lodgement Data
        if (dataRes?.data?.summary_data?.recon_section?.bill_lodgement) {
          setBillLodgementData(
            dataRes?.data?.summary_data?.recon_section?.bill_lodgement
          );
        }

        // MapIRM Data
        if (dataRes?.data?.summary_data?.recon_section?.map_irms) {
          setMapIRMData(dataRes?.data?.summary_data?.recon_section?.map_irms);
          let tempRowFormData = {};
          let selectedIRMList = [];
          dataRes?.data?.summary_data?.recon_section?.map_irms?.irms?.data?.forEach(
            (item, idx) => {
              for (const [key, value] of Object.entries(item)) {
                if (value.type === "text_box") {
                  tempRowFormData[item?.id] = tempRowFormData[item?.id] || {};
                  tempRowFormData[item?.id][value?.slug] = {
                    value: value?.default_value,
                    currency: value?.title,
                  };
                }
              }
              if (item?.is_selected) {
                selectedIRMList = [
                  ...selectedIRMList,
                  {
                    id: item?.id,
                    attached_amount: item?.fcy_attached_amount?.text,
                    currency: item?.fcy_currency?.text,
                  },
                ];
              }
            }
          );
          setSelectedIRMs({
            irms: selectedIRMList,
          });
          previousMapIrmRowData.current = tempRowFormData;
          enableMapIRMRowFormDataDebouncing.current = "updating";
          setRowFormData(tempRowFormData);
        } else if (
          dataRes?.data?.summary_data?.components_to_remove?.map_irms
        ) {
          setMapIRMData(null);
        }

        // Reconciliation Data
        if (dataRes?.data?.summary_data?.recon_section?.reconciliation) {
          setReconciliationData(
            dataRes?.data?.summary_data?.recon_section?.reconciliation
          );
        } else if (
          dataRes?.data?.summary_data?.components_to_remove?.reconciliation
        ) {
          setReconciliationData(null);
        }
        if (dataRes?.data?.summary_data?.post_reconciliation_data) {
          setPostReconData(
            dataRes?.data?.summary_data?.post_reconciliation_data
          );
        } else if (
          dataRes?.data?.summary_data?.components_to_remove
            ?.post_reconciliation_data
        ) {
          setPostReconData(null);
        }

        // Alert Info Map
        if (dataRes?.data?.summary_data?.alert_info) {
          setAlertInfoMap((prev) => ({
            ...prev,
            ...dataRes?.data?.summary_data?.alert_info,
          }));
        } else if (
          dataRes?.data?.summary_data?.components_to_remove?.alert_info
        ) {
          setAlertInfoMap(null);
        }
        if (
          action?.slug === "fetch_irms" ||
          action?.slug === "fetch_eligible_irms"
        ) {
          setIsSBDetailView(false);
        }
      } else {
        // error toast message
        if (dataRes?.data?.toast) {
          dispatch(showToast(dataRes?.data?.toast));
        }
      }
      dispatch(closeAlert());
      setTimeout(() => {
        dispatch(resetAlertData());
      }, [100]);
    };

    if (action?.alert_id) {
      dispatch(
        showAlert({
          items: alertInfoMap?.[action?.alert_id],
          props: ["submit-action", section, action, bodyData],
        })
      );
    } else if (section || action) {
      onAction(section, action, bodyData);
    }

    return onAction;
  };

  const updateCharges = async () => {
    countUpdateIRMs.current++;
    let changed = false;
    for (const [id, item] of Object.entries(debouncedMapIRMRow)) {
      for (const [key, value] of Object.entries(item)) {
        if (
          JSON.stringify(value) !==
          JSON.stringify(previousMapIrmRowData.current[id][key])
        ) {
          undebouncedRowFormDataRef.current = {
            ...undebouncedRowFormDataRef.current,
            [id]: {
              ...undebouncedRowFormDataRef.current[id],
              [key]: { secondary_icon: true, disabled: true },
            },
          };
          setUndebouncedRowFormData(undebouncedRowFormDataRef.current);
          const dataRes = await dispatch(
            apiRequest(endpoints.updateIRMCharges, value, `/${id}`)
          );
          changed = true;
          if (dataRes?.success) {
            changedIRMBankChargesIdKeys.current.push([id, key]);
            previousMapIrmRowData.current[id][key] = value;
          } else {
            undebouncedRowFormDataRef.current = {
              ...undebouncedRowFormDataRef.current,
              [id]: {
                ...undebouncedRowFormDataRef.current[id],
                [key]: {
                  error: true,
                  error_text:
                    dataRes?.data?.toast?.data?.center?.text +
                    dataRes?.data?.toast?.metadata?.amount +
                    " " +
                    dataRes?.data?.toast?.metadata?.currency,
                },
              },
            };
            setUndebouncedRowFormData(undebouncedRowFormDataRef.current);
          }
        }
      }
    }
    if (changed && countUpdateIRMs.current === 1) {
      countUpdateIRMs.current -= 1;
      //refresh list
      await getReconData();
      let updatedUndebouncedRowFormData = JSON.parse(
        JSON.stringify(undebouncedRowFormDataRef.current)
      );
      for (const [id, key] of changedIRMBankChargesIdKeys.current) {
        updatedUndebouncedRowFormData = {
          ...updatedUndebouncedRowFormData,
          [id]: {
            ...updatedUndebouncedRowFormData[id],
            [key]: { primary_icon: true, disabled: false },
          },
        };
      }
      changedIRMBankChargesIdKeys.current = [];
      undebouncedRowFormDataRef.current = updatedUndebouncedRowFormData;
      setUndebouncedRowFormData(updatedUndebouncedRowFormData);
    } else {
      countUpdateIRMs.current--;
    }
  };

  useEffect(() => {
    if (onAlertProceed) {
      const [action, ...alertProps] = alertPropsList;
      let onAction;

      switch (action) {
        case "doc-action":
          onAction = onDocAction();
          break;
        case "edit-section":
          onAction = onEditSection();
          break;
        default:
          onAction = onActionClick();
      }

      onAction(...alertProps);
    }
  }, [onAlertProceed]);

  useEffect(() => {
    getReconData();
  }, [showOtherIrms, isIrmAddedManually, toggleGetApiCall]);

  useEffect(() => {
    if (enableMapIRMRowFormDataDebouncing.current === "updating") {
      enableMapIRMRowFormDataDebouncing.current = true;
      return;
    }
    if (enableMapIRMRowFormDataDebouncing.current) {
      updateCharges();
    }
  }, [debouncedMapIRMRow]);

  return (
    <>
      <div className="new-recon" ref={newReconRef}>
        <div className="new-recon__sbsummary" ref={newReconSummaryRef}>
          <SbSummary
            highlightedData={highlightedData}
            onActionClick={onActionClick}
            onDocAction={onDocAction}
            uploadSingleFile={uploadSingleFile}
            newReconRef={newReconRef}
            newReconSummaryRef={newReconSummaryRef}
            isSBDetailView={isSBDetailView}
            setIsSBDetailView={setIsSBDetailView}
            summaryErrorMessage={summaryErrorMessage}
            setSummaryErrorMessage={setSummaryErrorMessage}
            sbType={sbType}
          />
        </div>

        <div className="new-recon__all-stages">
          {rejectData && <RejectSummary data={rejectData} />}

          {/* //todo: dummy data to integrate */}
          {billLodgementData && (
            <div className="new-recon__lodgement-stage">
              <BillLodgementStage
                data={billLodgementData}
                onActionClick={onActionClick}
                blSubmitValues={blSubmitValues}
                setBlSubmitValues={setBlSubmitValues}
              />
            </div>
          )}

          {mapIRMData && (
            <div className="new-recon__mapirm-stage">
              <MapIRMSummary
                data={mapIRMData}
                onActionClick={onActionClick}
                onEditSection={onEditSection}
                sbBalanceAmount={
                  highlightedData?.header?.summary?.right[
                    highlightedData?.header?.summary?.right.length - 1
                  ]?.text
                }
                rowFormData={rowFormData}
                undebouncedRowFormData={undebouncedRowFormData}
                setRowFormData={setRowFormData}
                showOtherIrms={showOtherIrms}
                setShowOtherIrms={setShowOtherIrms}
                isIrmAddedManually={isIrmAddedManually}
                setIsIrmAddedManually={setIsIrmAddedManually}
                irmFilter={irmFilter}
                setIrmFilter={setIrmFilter}
                getReconData={getReconData}
              />
            </div>
          )}
          <div className="new-recon__doc-stage">
            {reconciliationData && (
              <ReconcillationForm
                data={reconciliationData}
                onActionClick={onActionClick}
                onDocAction={onDocAction}
                onEditSection={onEditSection}
                uploadedDocumentIds={uploadedDocumentIds}
                setUploadedDocumentIds={setUploadedDocumentIds}
              />
            )}
          </div>
          {postReconData && (
            <PostRecon
              data={postReconData}
              onActionClick={onActionClick}
              onEditSection={onEditSection}
              onDocAction={onDocAction}
              uploadSingleFile={uploadSingleFile}
            />
          )}
        </div>
      </div>
      {showModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={
              <div className="o-gethelp" style={{ maxWidth: "360px" }}>
                <img
                  className="o-gethelp-close"
                  src="https://static.bluno.in/miscl/cross-thin.png"
                  onClick={() => {
                    setShowModal(false);
                  }}
                ></img>
                <div
                  className="o-gethelp-submitted"
                  style={{
                    minWidth: "400px",
                    gap: "10px",
                    padding: "20px 10px 30px 10px",
                  }}
                >
                  <div>
                    <span className="o-gethelp-submitted-title">
                      {modalData?.title}
                    </span>
                  </div>
                  <div
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      border: "2px solid #00D28A",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={modalData?.icon}
                      className="o-gethelp-submitted__logo"
                      style={{ position: "relative", left: "-3px", top: "2px" }}
                    />
                  </div>
                  <p className="o-gethelp-submitted-text">
                    <span>{modalData?.text}</span>
                    <span>{modalData?.text2}</span>
                  </p>
                </div>
              </div>
            }
          />
        </div>
      )}
      {paymentAwaitedModal && (
        <div className="a-buyers__modal-bg">
          <ModalV2
            slug={"standard"}
            children={
              <PaymentAwaitedModal
                data={
                  mapIRMData?.modal_data?.[
                    RECON_ACTION?.STANDARD_SB_PAYMENT_AWAITED
                  ]
                }
                setShowModal={setPaymentAwaitedModal}
                section="map_irms"
                sbId={sbId}
                applicationId={currentStage?.applicationId}
                toggleGetApiCall={toggleGetApiCall}
                setToggleGetApiCall={setToggleGetApiCall}
                setMapIRMData={setMapIRMData}
                setHighlightedData={setHighlightedData}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default NewRecon;
