import React from "react";

import { sanitizeHtml } from "../../utils/sanitizeHtml";
import BlunoTable from "../BlunoTable";
import BlunoTooltip from "../BlunoTooltip";
import BlunoIcon from "../BlunoIcon";

const IrmDetailsV2 = ({
  isSBDetailView = false,
  middleData,
  sbData,
  defaultView,
  fetchBankChargesInfo,
}) => {
  const tabledata = {
    headers: sbData?.table_data?.headers,
    data: sbData?.table_data?.data,
    default_view: defaultView,
  };

  return (
    <div className={`v2-irm ${!isSBDetailView && "v2-irm--hidden"}`}>
      <div className="v2-irm__middle">
        <div className="v2-irm__codes">
          <div className="v2-irm__codes-child">
            <div className="v2-irm__codes-top">
              <div className="v2-irm__codes-code">
                <p className="v2-irm__codes-purpose-label">
                  {middleData?.codes?.purpose_code?.label}
                </p>
                <p className="v2-irm__codes-purpose-value">
                  {middleData?.codes?.purpose_code?.value}
                </p>
              </div>

              <div className="v2-irm__codes-code">
                <p className="v2-irm__codes-ad-label">
                  {middleData?.codes?.ad_code?.label}
                </p>
                {middleData?.codes?.ad_code?.icon && (
                  <img
                    className="v2-irm__codes-ad-icon"
                    src={middleData?.codes?.ad_code?.icon}
                    alt="Bank"
                  />
                )}
                <p className="v2-irm__codes-ad-value">
                  {middleData?.codes?.ad_code?.value}
                </p>
              </div>
            </div>
            <p className="v2-irm__codes-note">{middleData?.codes?.note}</p>
          </div>
        </div>

        <div className="v2-irm__details">
          {middleData?.details?.map((item) => {
            return (
              <div className="v2-irm__details-detail">
                <p className="v2-irm__details-label">{item?.text}</p>
                {item?.icon && (
                  <BlunoTooltip
                    useChildren={true}
                    position="fixed"
                    text={item?.hover_text}
                    left="5px"
                  >
                    <img
                      className="v2-irm__details-icon"
                      src={item?.icon}
                      alt="Country"
                    />
                  </BlunoTooltip>
                )}
                <p
                  className="v2-irm__details-value"
                  dangerouslySetInnerHTML={sanitizeHtml(item?.value)}
                />
              </div>
            );
          })}
        </div>
        {middleData?.bank_charges && (
          <div className="v2-irm__bank-charges">
            <p className="v2-irm__bank-charges-title">
              {middleData?.bank_charges?.title}
              {middleData?.bank_charges?.icon && (
                // <img
                //   className="v2-irm__bank-charges-edit"
                //   src={middleData?.bank_charges?.icon}
                //   alt="Edit"
                //   onClick={() => fetchBankChargesInfo()}
                // />
                <BlunoIcon
                  src={middleData?.bank_charges?.icon}
                  alt="Edit"
                  onClick={() => fetchBankChargesInfo()}
                  iconWidth="16px"
                  iconHeight="16px"
                  iconFilterVariant="primary-blue"
                />
              )}
            </p>
            <div
              className={`v2-irm__bank-charges-card ${
                middleData?.bank_charges?.list?.length > 8
                  ? "v2-irm__bank-charges-card--newgap"
                  : ""
              }`}
            >
              {middleData?.bank_charges?.list?.map((item, idx) => {
                return (
                  <div className="v2-irm__bank-charges-element">
                    {item?.label && (
                      <p className={"v2-irm__bank-charges-label"}>
                        {item?.label}
                      </p>
                    )}
                    {item?.icon && (
                      <img
                        className="v2-irm__bank-charges-icon"
                        src={item?.icon}
                        alt="operator"
                      />
                    )}
                    {item?.value && (
                      <p
                        className={
                          item?.slug === "total_charges"
                            ? "v2-irm__bank-charges-value--total"
                            : "v2-irm__bank-charges-value"
                        }
                        dangerouslySetInnerHTML={sanitizeHtml(item?.value)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="v2-irm__sbDetails">
        <div className="v2-irm__sbDetails-child">
          {sbData && (
            <div className="v2-irm__sbDetails-top">
              <p className="v2-irm__bank-charges-title">{sbData?.title}</p>
              {sbData?.total && (
                <p className="v2-irm__sbDetails-count">{sbData?.total}</p>
              )}
            </div>
          )}
          <div className="v2-irm__table">
            <BlunoTable data={tabledata} />
          </div>
          {sbData && (
            <div className="v2-irm__sbDetails-bottom">
              <p
                className="v2-irm__sbDetails-bottom-text"
                dangerouslySetInnerHTML={sanitizeHtml(sbData?.total_value)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IrmDetailsV2;
