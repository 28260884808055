import React from "react";

export default function BlunoSelectFilter({
  selected,
  total,
  data,
  onActionFunction,
}) {
  return (
    <div className="s-select-filter">
      <p className="s-select-filter__selected-text">
        {selected} <span>of {total} Selected</span>
      </p>
      <div className="s-select-filter__partition" />
      {data?.map((item, idx) => {
        return (
          <div
            key={idx}
            className="s-select-filter__item"
            onClick={() => onActionFunction(item)}
          >
            <img src={item?.icon} className="s-select-filter__item-icon" />
            <p className="s-select-filter__item-text">{item?.text}</p>
          </div>
        );
      })}
    </div>
  );
}
