// react imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// third-party imports
import { Checkbox, FormControlLabel } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// in-app imports
import Button from "../Button";
import {
  BANK_SELECTED_POPUP_SLUGS,
  BL_DOCS_POPUP_SLUG,
  BULK_COMMON_SLUGS,
  BULK_MODAL_SCREEN_TYPE,
  BULK_SUMMARY_VIEW_POPUP_SLUGS,
  BULK_TABLE_ROW_ACTION,
  SUCCESS_POPUP_SLUGS,
} from "../../utils/constants/BulkConstants";
import { INPUT_TYPES } from "../../utils/constants/SettingsConstants";
import { sanitizeHtml } from "../../utils/sanitizeHtml";
import UploadDocCard from "../BlunoTableComponents/UploadDocCard";
import { UPLOAD_DOCUMENT_FLOW } from "../../utils/constants/TableConstants";
import CopyToClipboard from "../CopyToClipboard";
import { updateShowSbForm } from "../../redux/common/slice";
import { useDispatch } from "react-redux";
import BulkInputElements from "./BulkInputElements";

const BulkActionModal = ({
  data,
  setShowModal,
  setModalData,
  modalScreenType,
  setModalScreenType,
  fetchBulkAction = () => {},
  onActionClick = () => {},
  fetchBulkBillLodgementDataSummary = () => {},
  refreshPageData = () => {},
  singleBulk = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hoveredBankItem, setHoveredBankItem] = useState(-1);
  const [bulkPopupData, setBulkPopupData] = useState();
  const [bank, setBank] = useState();
  const [bankSelectionPopupData, setBankSelectionPopupData] = useState();
  const [inputValues, setInputValues] = useState({});
  const [showCopyIndex, setShowCopyIndex] = useState();

  const handleInputChange = (inputValue, inputSlug) => {
    setInputValues({
      ...inputValues,
      [inputSlug]: inputValue,
    });
  };

  const onPopupClose = (refresh = false) => {
    setShowModal(false);
    setModalData({});
    if (refresh) refreshPageData();
  };

  const getModalWidthByScreenType = (modalScreenType) => {
    switch (modalScreenType) {
      case BULK_MODAL_SCREEN_TYPE?.BILL_LODGEMENT:
        if (bulkPopupData?.default_view) return "360px";
        return "500px";
      case BULK_MODAL_SCREEN_TYPE?.BULK_SUMMARY_VIEW:
        return "500px";
      case BULK_MODAL_SCREEN_TYPE?.SELECTED_BANK_ACTIONS:
        return "830px";
      case BULK_MODAL_SCREEN_TYPE?.SUCCESS_POPUP:
        return "360px";
      case BULK_MODAL_SCREEN_TYPE?.BL_PENDING_DOCS:
        return "460px";
    }
  };

  const onDefaultViewButtonClick = (action) => {
    switch (action?.slug) {
      case BULK_COMMON_SLUGS?.UPLOAD_SB:
        dispatch(updateShowSbForm(true));
        break;
    }
  };

  const onBankBulkClick = (item) => {
    setBulkPopupData(bankSelectionPopupData?.bulk_bank_actions?.[item?.slug]);
    setBank(item?.slug);
    setModalScreenType(BULK_MODAL_SCREEN_TYPE?.SELECTED_BANK_ACTIONS);
  };

  const onViewPopupButtonClick = (action) => {
    switch (action?.slug) {
      case BULK_SUMMARY_VIEW_POPUP_SLUGS?.CANCEL:
        onPopupClose();
        break;
      case BULK_SUMMARY_VIEW_POPUP_SLUGS?.SHOW_DETAILS:
        navigate(action?.deeplink);
        break;
      case BULK_SUMMARY_VIEW_POPUP_SLUGS?.SHOW_SUMMARY:
        navigate(action?.deeplink);
        break;
      case BULK_SUMMARY_VIEW_POPUP_SLUGS?.EXPORT_BILL_LODGEMENT:
        onActionClick(action);
        break;
      case BULK_TABLE_ROW_ACTION?.WITHDRAW:
      case BULK_TABLE_ROW_ACTION?.REMIND:
      case BULK_TABLE_ROW_ACTION?.ACCEPT:
      case BULK_TABLE_ROW_ACTION?.REJECT:
        onActionClick(action, inputValues);
        break;
    }
  };

  const onBankSelectionPopupButtonClick = (action) => {
    switch (action?.slug) {
      case BANK_SELECTED_POPUP_SLUGS?.CHANGE_BANK:
      case BANK_SELECTED_POPUP_SLUGS?.BACK:
        setBulkPopupData(data);
        setModalScreenType(BULK_MODAL_SCREEN_TYPE?.BILL_LODGEMENT);
        break;
      case BANK_SELECTED_POPUP_SLUGS?.VIEW_DETAILS_ELIGIBLE:
        navigate(action?.deeplink);
        break;
      case BANK_SELECTED_POPUP_SLUGS?.VIEW_DETAILS_PENDING_DOCS:
        navigate(action?.deeplink);
        break;
      case BANK_SELECTED_POPUP_SLUGS?.VIEW_DETAILS_OVERDUE:
        navigate(action?.deeplink);
        break;
      case BANK_SELECTED_POPUP_SLUGS?.INITIATE_BULK_ACTION:
        fetchBulkBillLodgementDataSummary(data?.id, bank);
        break;
    }
  };

  const onSuccessPopupButtonClick = (action) => {
    switch (action?.slug) {
      case BULK_MODAL_SCREEN_TYPE?.BILL_LODGEMENT:
      case BULK_MODAL_SCREEN_TYPE?.REGULARISATION:
      case BULK_MODAL_SCREEN_TYPE?.EBRC:
        if (singleBulk)
          navigate("/bulk-info", { state: { slug: action?.slug } });
        else refreshPageData();
        fetchBulkAction(action?.slug);
        break;
      case SUCCESS_POPUP_SLUGS?.VIEW_DETAILS:
        navigate(action?.deeplink);
        break;
    }
  };

  const onBLPendingDocsPopupButtonClick = (action) => {
    switch (action?.slug) {
      case BL_DOCS_POPUP_SLUG?.SUBMIT:
      case BL_DOCS_POPUP_SLUG?.CLOSE:
        onPopupClose(true);
    }
  };

  const getPopupDataByScreenType = (popupData, modalScreenType) => {
    switch (modalScreenType) {
      case BULK_MODAL_SCREEN_TYPE?.BILL_LODGEMENT:
        return popupData?.default_view ? (
          <>
            <div className="m-bulk-action__default-view">
              <img
                src={popupData?.default_view?.logo}
                alt=""
                className="m-bulk-action__default-view-logo"
              />
              <h2 className="m-bulk-action__default-view-title">
                {popupData?.default_view?.title}
              </h2>
              <p className="m-bulk-action__default-view-text">
                {popupData?.default_view?.text}
              </p>
              {popupData?.default_view?.buttons && (
                <div className="m-bulk-action__default-view-buttons">
                  {popupData?.default_view?.buttons?.map((item, idx) => {
                    return (
                      <Button
                        key={idx}
                        icon={item?.icon}
                        buttonType={item?.sub_type}
                        buttonLabel={item?.text}
                        iconAlignment={item?.icon_alignment}
                        onClick={() => onDefaultViewButtonClick(item)}
                      />
                    );
                  })}
                </div>
              )}
              <img
                onClick={() => onPopupClose()}
                src={popupData?.default_view?.close_icon}
                alt=""
                className="m-bulk-action__default-view-close"
              />
            </div>
          </>
        ) : (
          <>
            <div className="m-bulk-action__header">
              <h2 className="m-bulk-action__title">
                {popupData?.bulk_action_summary?.title}
              </h2>
              <img
                src={popupData?.bulk_action_summary?.close_icon}
                alt=""
                onClick={() => onPopupClose()}
              />
            </div>
            <div className="m-bulk-action__list">
              <div className="m-bulk-action__item m-bulk-action__item-head">
                <div className="m-bulk-action__item-left">
                  <p
                    className="m-bulk-action__item-header"
                    dangerouslySetInnerHTML={sanitizeHtml(
                      popupData?.bulk_action_summary?.header1
                    )}
                  />
                </div>
                <div className="m-bulk-action__item-right">
                  <p
                    className="m-bulk-action__item-header"
                    dangerouslySetInnerHTML={sanitizeHtml(
                      popupData?.bulk_action_summary?.header2
                    )}
                  />
                </div>
              </div>
              {popupData?.bulk_action_summary?.bank_list?.map((item, index) => {
                return (
                  <div
                    className="m-bulk-action__item"
                    onMouseEnter={() => setHoveredBankItem(index)}
                    onMouseLeave={() => setHoveredBankItem(-1)}
                    onClick={() => onBankBulkClick(item)}
                  >
                    <div className="m-bulk-action__item-left">
                      {item?.bank_logo && <img src={item?.bank_logo} alt="" />}
                      <p className="m-bulk-action__item-bank">
                        {item?.bank_name}
                      </p>
                      {hoveredBankItem === index && item?.hover_text && (
                        <p className="m-bulk-action__item-hover-text">
                          {item?.hover_text}
                        </p>
                      )}
                    </div>
                    <div className="m-bulk-action__item-right">
                      <p className="m-bulk-action__item-count">{item?.count}</p>
                      <img
                        src={item?.hover_icon}
                        alt=""
                        className="m-bulk-action__item-hover-icon"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            {popupData?.bulk_action_summary?.buttons && (
              <div className="m-bulk-action__buttons">
                {popupData?.bulk_action_summary?.buttons?.map((item, idx) => {
                  return (
                    <Button
                      key={idx}
                      icon={item?.icon}
                      buttonType={item?.sub_type}
                      buttonLabel={item?.text}
                      iconAlignment={item?.icon_alignment}
                      onClick={() => onViewPopupButtonClick(item)}
                    />
                  );
                })}
              </div>
            )}
          </>
        );
      case BULK_MODAL_SCREEN_TYPE?.BULK_SUMMARY_VIEW:
        return (
          <>
            <div className="m-bulk-action__header">
              <h2 className="m-bulk-action__title">{popupData?.title}</h2>
              <img
                src={popupData?.close_icon}
                alt=""
                onClick={() => onPopupClose()}
              />
            </div>
            <div className="m-bulk-table-view__content">
              {popupData?.action_details && (
                <div className="m-bulk-table-view__list">
                  {popupData?.action_details?.map((item, idx) => {
                    return (
                      <div className="m-bulk-table-view__item">
                        <p className="m-bulk-table-view__item-label">
                          {item?.label}
                        </p>
                        <p
                          className="m-bulk-table-view__item-value"
                          style={{ color: item?.color }}
                          onMouseEnter={() => setShowCopyIndex(idx)}
                          onMouseLeave={() => setShowCopyIndex(null)}
                        >
                          {item?.value}
                          <div style={{ width: "20px", height: "16px" }}>
                            {item?.enable_copy && showCopyIndex === idx && (
                              <CopyToClipboard text={item?.value} />
                            )}
                          </div>
                        </p>
                        {item?.subtext && (
                          <p className="m-bulk-table-view__item-subtext">
                            {item?.subtext}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {popupData?.reason && (
                <div className="m-bulk-table-view__reason">
                  <p className="m-bulk-table-view__reason-label">
                    {popupData?.reason?.label}
                  </p>
                  <p className="m-bulk-table-view__reason-value">
                    {popupData?.reason?.value}{" "}
                    {popupData?.reason?.buttons && (
                      <span className="m-bulk-table-view__reason-buttons">
                        {popupData?.reason?.buttons?.map((item, idx) => {
                          return (
                            <Button
                              key={idx}
                              icon={item?.icon}
                              buttonType={item?.sub_type}
                              buttonLabel={item?.text}
                              disabled={item?.disabled}
                              iconAlignment={item?.icon_alignment}
                              onClick={() => onViewPopupButtonClick(item)}
                            />
                          );
                        })}
                      </span>
                    )}
                  </p>
                </div>
              )}
              {popupData?.inputs && popupData?.inputs?.length > 0 && (
                <div className="m-bulk-table-view__inputs">
                  <BulkInputElements
                    inputs={popupData?.inputs}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                  />
                </div>
              )}
            </div>
            {popupData?.declaration && (
              <div className="m-bulk-table-view__declaration">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checkedIcon={<CheckBoxIcon />}
                      onChange={(e) => {
                        handleInputChange(
                          e.target.checked,
                          popupData?.declaration?.slug
                        );
                      }}
                      checked={inputValues?.[popupData?.declaration?.slug]}
                      style={{
                        padding: 0,
                      }}
                    />
                  }
                  disabled={popupData?.declaration?.disabled}
                  label={
                    <p
                      className="m-bulk-table-view__declaration-label"
                      dangerouslySetInnerHTML={sanitizeHtml(
                        popupData?.declaration?.text,
                        ["target"]
                      )}
                    />
                  }
                  style={{ marginLeft: 0 }}
                />
              </div>
            )}
            {popupData?.buttons && (
              <div className="m-bulk-action__buttons">
                {popupData?.buttons?.map((item, idx) => {
                  return (
                    <Button
                      key={idx}
                      icon={item?.icon}
                      buttonType={item?.sub_type}
                      buttonLabel={item?.text}
                      disabled={
                        item?.slug ===
                        BULK_SUMMARY_VIEW_POPUP_SLUGS?.EXPORT_BILL_LODGEMENT
                          ? !inputValues?.[
                              BULK_SUMMARY_VIEW_POPUP_SLUGS?.FEMA_DECLARATION
                            ]
                          : item?.disabled
                      }
                      iconAlignment={item?.icon_alignment}
                      onClick={() => onViewPopupButtonClick(item)}
                    />
                  );
                })}
              </div>
            )}
          </>
        );
      case BULK_MODAL_SCREEN_TYPE?.SELECTED_BANK_ACTIONS:
        return (
          <>
            <div className="m-bulk-action__header">
              <h2 className="m-bulk-action__title">{popupData?.title}</h2>
              <img
                src={popupData?.close_icon}
                alt=""
                onClick={() => onPopupClose()}
              />
            </div>
            <div className="m-bulk-bank-action__container">
              <div className="m-bulk-bank-action__selected">
                <p className="m-bulk-bank-action__selected-label">
                  {popupData?.selected_bank?.text}
                </p>
                <p className="m-bulk-bank-action__selected-value">
                  <img src={popupData?.selected_bank?.bank_logo} alt="" />
                  <span className="m-bulk-bank-action__selected-name">
                    {popupData?.selected_bank?.bank_name}
                  </span>
                </p>
                <div className="m-bulk-bank-action__change">
                  {popupData?.change_bank_button?.map((item, idx) => {
                    return (
                      item?.type === INPUT_TYPES?.BUTTON && (
                        <Button
                          key={idx}
                          icon={item?.icon}
                          buttonType={item?.sub_type}
                          buttonLabel={item?.text}
                          iconAlignment={item?.icon_alignment}
                          variant={item?.button_variant}
                          onClick={() => onBankSelectionPopupButtonClick(item)}
                          extraClass="m-bulk-bank-action__change--default"
                        />
                      )
                    );
                  })}
                </div>
              </div>

              <div className="m-bulk-bank-action__total-summary">
                <span>{popupData?.total_summary?.label}</span>
                <p className="m-bulk-bank-action__item-count">
                  {popupData?.total_summary?.value}
                </p>
              </div>

              <div className="m-bulk-bank-action__list">
                {popupData?.bulk_doc_details?.map((item) => {
                  return (
                    <div className="m-bulk-bank-action__item">
                      <div className="m-bulk-bank-action__item-content">
                        <div className="m-bulk-bank-action__item-header">
                          <p className="m-bulk-bank-action__item-title">
                            {item?.text}
                          </p>
                          <p className="m-bulk-bank-action__item-count">
                            {item?.count}
                          </p>
                        </div>
                        {item?.icon && (
                          <img
                            src={item?.icon}
                            className="m-bulk-bank-action__item-icon"
                          />
                        )}
                        {item?.subtext && (
                          <p className="m-bulk-bank-action__item-text">
                            {item?.subtext}
                          </p>
                        )}
                      </div>

                      <div className="m-bulk-bank-action__item-buttons">
                        {item?.buttons?.map((item, idx) => {
                          return (
                            item?.type === INPUT_TYPES?.BUTTON && (
                              <Button
                                key={idx}
                                icon={item?.icon}
                                buttonType={item?.sub_type}
                                buttonLabel={item?.text}
                                iconAlignment={item?.icon_alignment}
                                variant={item?.button_variant}
                                onClick={() =>
                                  onBankSelectionPopupButtonClick(item)
                                }
                                width="max-content"
                                extraClass="m-bulk-bank-action__change--default"
                              />
                            )
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            {popupData?.buttons && (
              <div className="m-bulk-action__buttons">
                {popupData?.buttons?.map((item, idx) => {
                  return (
                    <Button
                      key={idx}
                      icon={item?.icon}
                      buttonType={item?.sub_type}
                      buttonLabel={item?.text}
                      iconAlignment={item?.icon_alignment}
                      onClick={() => onBankSelectionPopupButtonClick(item)}
                    />
                  );
                })}
              </div>
            )}
          </>
        );
      case BULK_MODAL_SCREEN_TYPE?.SUCCESS_POPUP:
        return (
          <>
            <div className="m-bulk-success">
              <img
                className="m-bulk-success__close"
                src={popupData?.close_icon}
                onClick={() => {
                  onPopupClose(!singleBulk);
                  if (singleBulk) navigate("/bulk-info");
                }}
              />
              <div className="m-bulk-success__content">
                {popupData?.logo && (
                  <img
                    src={popupData?.logo}
                    alt=""
                    className={`s-loader__icon-logo s-loader__icon-sucess`}
                  />
                )}
                {popupData?.text && (
                  <p
                    className="m-bulk-success__text"
                    dangerouslySetInnerHTML={sanitizeHtml(popupData?.text)}
                  />
                )}
              </div>
              {popupData?.doc_summmary && (
                <div className="m-bulk-success__summary">
                  {popupData?.doc_summmary?.map((item) => {
                    return (
                      <div className="m-bulk-success__summary-item">
                        <p className="m-bulk-success__summary-label">
                          {item?.label}
                        </p>
                        <p
                          className="m-bulk-success__summary-value"
                          style={{ backgroundColor: item?.bg_color }}
                        >
                          {item?.value}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
              {popupData?.bulk_id_info && (
                <div className="m-bulk-success__bulkid">
                  <p className="m-bulk-success__bulkid-label">
                    {popupData?.bulk_id_info?.label}
                  </p>
                  <p
                    className="m-bulk-success__bulkid-value"
                    onMouseEnter={() => setShowCopyIndex(-1)}
                    onMouseLeave={() => setShowCopyIndex(null)}
                  >
                    {popupData?.bulk_id_info?.value}
                    <div style={{ width: "20px", height: "16px" }}>
                      {popupData?.bulk_id_info?.enable_copy &&
                        showCopyIndex === -1 && (
                          <CopyToClipboard
                            text={popupData?.bulk_id_info?.value}
                          />
                        )}
                    </div>
                  </p>
                </div>
              )}
              {popupData?.buttons && (
                <div className="m-bulk-success__buttons">
                  {popupData?.buttons?.map((item, idx) => {
                    return (
                      <Button
                        key={idx}
                        icon={item?.icon}
                        buttonType={item?.sub_type}
                        buttonLabel={item?.text}
                        iconAlignment={item?.icon_alignment}
                        onClick={() => onSuccessPopupButtonClick(item)}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </>
        );
      case BULK_MODAL_SCREEN_TYPE?.BL_PENDING_DOCS:
        return (
          <>
            <UploadDocCard
              data={popupData}
              setShowModal={setShowModal}
              sbId={popupData?.sb_id}
              uploadFlow={UPLOAD_DOCUMENT_FLOW?.BULK_BL}
              onActionClick={onBLPendingDocsPopupButtonClick}
              maxFileNameWidth="150px"
              bulkId={popupData?.bulk_id}
            />
          </>
        );
      case BULK_MODAL_SCREEN_TYPE?.REMINDER_HISTORY:
        return (
          <>
            <div className="m-bulk-action__header">
              <h2 className="m-bulk-action__title">{popupData?.title}</h2>
              <img
                src={popupData?.close_icon}
                alt=""
                onClick={() => onPopupClose()}
              />
            </div>
            <div className="m-bulk-remind__content">
              {popupData?.maker_checker_info && (
                <div className="m-bulk-table-view__list">
                  {popupData?.maker_checker_info?.map((item, idx) => {
                    return (
                      <div className="m-bulk-table-view__item">
                        <p className="m-bulk-table-view__item-label">
                          {item?.label}
                        </p>
                        <p
                          className="m-bulk-table-view__item-value"
                          style={{ color: item?.color }}
                          onMouseEnter={() => setShowCopyIndex(idx)}
                          onMouseLeave={() => setShowCopyIndex(null)}
                        >
                          {item?.value}
                          <div style={{ width: "20px", height: "16px" }}>
                            {item?.enable_copy && showCopyIndex === idx && (
                              <CopyToClipboard text={item?.value} />
                            )}
                          </div>
                        </p>
                        {item?.subtext && (
                          <p className="m-bulk-table-view__item-subtext">
                            {item?.subtext}
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="m-bulk-remind__table">
                <table className="s-table__table">
                  <tr className="s-table__data-head">
                    {popupData?.table_data?.headers?.map((header, index) => {
                      return (
                        <th
                          key={`th-${header?.slug}-${index}`}
                          className={`s-table__th--${header?.slug}`}
                          style={header?.style}
                        >
                          <div
                            className={`s-table__table--align-${header?.alignment} `}
                          >
                            <p>{header?.text}</p>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                  {!popupData?.table_data?.default_view &&
                    popupData?.table_data?.data?.map((row, rowIndex) => (
                      <tr
                        key={`tr-${popupData?.table_data?.title}-${rowIndex}`}
                        className={`s-table__data-row s-table__data-row--noHover`}
                        id={`row_id_${row?.id}`}
                      >
                        {popupData?.table_data?.headers?.map(
                          (header, index) => {
                            return (
                              <td key={`td-${header?.slug}-${index}`}>
                                <div
                                  className={`s-table__table--align-${
                                    row?.[header?.slug]?.alignment
                                  } m-bulk-remind__table-cell  m-bulk-remind__table-cell--alignment-${
                                    row?.[header?.slug]?.alignment
                                  }`}
                                >
                                  <p
                                    className={`m-bulk-remind__table-cell-text`}
                                  >
                                    {row?.[header?.slug]?.text}
                                  </p>
                                  <p
                                    className={`m-bulk-remind__table-cell-subtext`}
                                  >
                                    {row?.[header?.slug]?.sub_text}
                                  </p>
                                </div>
                              </td>
                            );
                          }
                        )}
                      </tr>
                    ))}
                </table>
                {popupData?.table_data?.default_view && (
                  <div className="m-bulk-remind__table-default-view">
                    <img
                      src={popupData?.table_data?.default_view?.icon}
                      alt=""
                    />
                    {popupData?.table_data?.default_view?.buttons && (
                      <div className="buyer-details__action">
                        {popupData?.table_data?.default_view?.buttons?.map(
                          (action) => {
                            return (
                              action?.type === "button" && (
                                <Button
                                  buttonLabel={action?.text}
                                  buttonType={action?.sub_type}
                                  icon={action?.icon}
                                  hoverIcon={action?.hover_icon}
                                  disabled={action?.disabled}
                                  iconAlignment={action?.icon_alignment}
                                  tooltipText={action?.tooltip_text}
                                  tooltipPosition={{
                                    bottom: "20px",
                                    left: "40px",
                                  }}
                                  onClick={() => {
                                    onDefaultViewButtonClick(action);
                                  }}
                                />
                              )
                            );
                          }
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <p
                className="m-bulk-remind__text"
                dangerouslySetInnerHTML={sanitizeHtml(popupData?.text)}
              />
            </div>
          </>
        );
    }
  };

  useEffect(() => {
    if (modalScreenType === BULK_MODAL_SCREEN_TYPE?.BILL_LODGEMENT) {
      setBankSelectionPopupData(data);
    }
    setBulkPopupData(data);
  }, [data]);

  return (
    <>
      <div
        className="m-bulk-action"
        style={{ width: getModalWidthByScreenType(modalScreenType) }}
      >
        {getPopupDataByScreenType(bulkPopupData, modalScreenType)}
      </div>
    </>
  );
};

export default BulkActionModal;
