import { useNavigate, useLocation } from "react-router-dom";

const useCustomHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToWithState = (path, state = {}) => {
    navigate(path, { state });
  };

  const goBack = () => {
    navigate(-1);
  };

  const goToWithCallback = (path, state = {}, callback, ...callbackParams) => {
    navigate(path, { state });
    if (callback && typeof callback === "function") {
      callback(...callbackParams);
    }
  };

  return {
    goToWithState,
    goBack,
    goToWithCallback,
    currentPath: location.pathname,
  };
};

export default useCustomHistory;
