// react imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// third party imports
import { Checkbox } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { v4 as uuidv4 } from "uuid";

// redux imports
import { useDispatch } from "react-redux";
import { apiRequest } from "../redux/common/actions";
import endpoints from "../api/endpoints";

// in-app imports
import Tag from "./Tag";
import Button from "./Button";
import circleLoader from "../assets/media/gifs/loader.gif";
import RedirectIcon from "../assets/media/table/Redirect.png";

// utils
import { truncateText } from "../utils";
import SortArrowIcon from "../assets/svg/SortArrowIcon";
import { checkIfAllValuesFalse } from "../utils";
import { checkIfAllValuesTrue } from "../utils";
import { updateShowIrmForm, updateShowSbForm } from "../redux/common/slice";
import { HEADER_SLUG } from "../utils/constants/TableConstants";
import UploadDocHeaders from "./BlunoTableComponents/UploadDocHeaders";
import UploadDocColumn from "./BlunoTableComponents/UploadDocColumn";
import BlunoTooltip from "./BlunoTooltip";
import BlunoTableOptions from "./BlunoTableOptions";
import BlunoSwitch from "./BlunoSwitch";
import CopyToClipboard from "./CopyToClipboard";
import RequestActionColumn from "./export-bills/RequestActionColumn";
import BlunoTableDropdown from "./BlunoTableDropdown";
import { convertNormalTextToCenterEllipsis } from "../utils/formatTextUtils";
import TextInput from "./TextInput";
import Dropdown from "./Dropdown";
import UploadFileCard from "./UploadFile/UploadFile";
import IRMAdditionModal from "./BlunoTableComponents/IRMAdditionModal";
import TicketsTimeline from "./BlunoTableComponents/TicketsTimeline";
import { REFRESH_IRM_ALERT } from "../app/constants/common";
import { showToast } from "../redux/toast/slice";
import ActionColumn from "./DocumentManagement/ActionColumn";
import Avatar from "react-avatar";
import { sanitizeHtml } from "../utils/sanitizeHtml";
import TableProgressBar from "./TableProgressBar";
import NestedTable from "./NestedTable";
import BlunoIcon from "./BlunoIcon";

const BlunoTable = ({
  data,
  section,
  rowSelectionState,
  metadata,
  showHoverBlueRow = true,
  showHoverOnSelect = false,
  OptionsComponent,
  allSelected,
  dataHeight = "95%",
  maxDataHeight,
  onlyShowNonOverflow = false,
  rowFormData,
  undebouncedRowFormData,
  noTableBorder,
  defaultColumnExpansionState = false,
  infiniteScroll = false,
  showInfiniteScrollLoader = false,
  irmSimulationFilter,
  onActionClick = () => {},
  onIconClick = () => {},
  onRowFormChange = () => {},
  onRowFormFocus = () => {},
  onSortOrderClick = () => {},
  onTableRowSelection = () => {},
  onAllTableRowSelection = () => {},
  onImageFunction = () => {},
  onOptionsFunction = () => {},
  onSelectChange = () => {},
  onSwitchChange = () => {},
  specialColumnFunction = () => {},
  onExpandColumn = () => {},
  onInfiniteScrollReachBottom = () => {},
  bulkDownloadEbrc = () => {},

  //this will be used a generic state to pass state from grand-parent , parent, current, child, grand-child etc
  genericState,
  setGenericState = () => {},
}) => {
  // defined to access react based APIs
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showCopyWithId, setShowCopyWithId] = useState(null);
  const [showCopySlug, setShowCopySlug] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [showTableData, setShowTableData] = useState(false);
  const [activeRowId, setActiveRowId] = useState(null);
  const [isTableColumnExpanded, setIsTableColumnExpanded] = useState(
    defaultColumnExpansionState
  );

  const tableRef = useRef();
  const tableHeadRef = useRef();
  const tableDataRef = useRef();
  const previousScrollPosition = useRef(0);
  const compactFirstColumn = useRef(-1);

  // other variables
  const unselected_recommended =
    "https://static.bluno.in/miscl/unselected_recommended.png";

  const expandColumnFunction = () => {
    setIsTableColumnExpanded((prev) => {
      onExpandColumn(!prev);
      return !prev;
    });
  };

  const redirectToLink = (theLink) => {
    const path = document.location.pathname;
    navigate(theLink, {
      state: {
        link: path,
        text: path.includes("buyers") ? "Back To Buyer" : null,
      },
    });
  };

  const onDocDownload = (docId) => {
    const download = async () => {
      const dataRes = await dispatch(
        apiRequest(endpoints.docAction, {
          action: "download",
          document_id: docId,
        })
      );
      if (dataRes?.success && dataRes?.data) {
        if (dataRes?.data?.document_link) {
          window.open(dataRes?.data?.document_link, "_blank");
        }
      }
    };
    if (docId) download();
  };

  const refreshIrm = async () => {
    const dataRes = await dispatch(apiRequest(endpoints.refreshIRMData));
    if (dataRes?.success) {
      dispatch(showToast(REFRESH_IRM_ALERT));
    }
  };

  const onDefaultViewButtonClick = (action) => {
    switch (action?.slug) {
      case "add_irm":
        dispatch(updateShowIrmForm(true));
        break;
      case "add_sb":
        dispatch(updateShowSbForm(true));
        break;
      case "refresh_irm":
        refreshIrm();
        break;
    }
  };

  const handleActionClick = (e, row, header) => {
    e.stopPropagation();
    if (row?.[header?.slug]?.slug === "manual_attach_irm") {
      setActiveRowId(row?.id);
    } else if (row?.[header?.slug]?.slug === "upload_pending_doc") {
      onActionClick(row?.[header?.slug], row?.id);
    } else {
      redirectToLink(row?.[header?.slug]?.deeplink);
    }
  };

  const handleAccrodionTypeClick = (e, row) => {
    e.stopPropagation();
    if (activeRowId === row?.id) {
      setActiveRowId(null);
    } else {
      setActiveRowId(row?.id);
    }
  };

  const getComponent = (row, header, rowIndex) => {
    switch (row?.[header?.slug]?.type) {
      case HEADER_SLUG?.tag:
        return (
          <BlunoTooltip
            useChildren={true}
            position="fixed"
            text={
              truncateText(row?.[header?.slug]?.text) !==
                row?.[header?.slug]?.text &&
              header?.slug !== HEADER_SLUG?.bankFlow &&
              (row?.[header?.slug]?.hover_text ?? row?.[header?.slug]?.text)
            }
            internalToolTipDivWidth="100%"
            alignment={"center"}
          >
            <Tag
              text={truncateText(row?.[header?.slug]?.text)}
              extraClass={`s-tag--${row?.[header?.slug].sub_type}`}
            />
          </BlunoTooltip>
        );
      case HEADER_SLUG?.action:
        return (
          <div
            className={`s-table__table-action ${
              row?.id === activeRowId && "mapirm__tablerow-button--display"
            }`}
          >
            <Button
              onClick={(e) => {
                handleActionClick(e, row, header);
              }}
              buttonSize="sm2"
              fontSize={row?.[header?.slug].font_size}
              buttonLabel={row?.[header?.slug].text}
              extraClass={`s-button--${row?.[header?.slug].sub_type}`}
              icon={row?.[header?.slug].icon}
              iconAlignment="left"
            />
          </div>
        );
      case HEADER_SLUG?.imageWithLink:
        return (
          <img
            className="s-table__table-imglink"
            src={row?.[header?.slug]?.icon}
            alt={header?.slug}
            onClick={() => onDocDownload(row?.[header?.slug]?.doc_id)}
          />
        );
      case HEADER_SLUG?.bankFlow:
        return (
          <UploadDocColumn
            rowData={row}
            headerData={header}
            tableData={tableData}
            setTableData={setTableData}
          />
        );
      case HEADER_SLUG?.actionRequest:
        return (
          <RequestActionColumn
            row={row}
            data={row?.[header?.slug]}
            rowSelectionState={rowSelectionState}
            handleClick={specialColumnFunction}
          />
        );
      case HEADER_SLUG?.sbDocAction:
        return (
          <ActionColumn
            data={row?.[header?.slug]}
            row={row}
            OptionsComponent={OptionsComponent}
            onOptionsFunction={onOptionsFunction}
            tableRef={tableRef}
            tableHeadRef={tableHeadRef}
            showHoverBlueRow={showHoverBlueRow}
            specialColumnFunction={specialColumnFunction}
            highlightRow={highlightRow}
            unHighlightRow={unHighlightRow}
          />
        );
      case HEADER_SLUG?.progressBar:
        return <TableProgressBar data={row?.[header?.slug]} />;
      case HEADER_SLUG?.dropdown:
        return (
          <BlunoTableDropdown
            options={row?.[header?.slug]?.options}
            height="35px"
            placeholderFontSize="13px"
            fontSize="13px"
            fontWeight="400"
            menuPosition="fixed"
            minWidth="60px"
            menuPlacement="auto"
            defaultValue={row?.[header?.slug]?.default?.value}
            onSelectChange={(e) => onSelectChange(e, row?.id)}
            useValue={true}
            isDisabled={row?.[header?.slug]?.disabled}
            tableRef={tableRef}
          />
        );
      case HEADER_SLUG?.switch:
        return (
          <BlunoSwitch
            isToggleActive={row?.[header?.slug]?.default_value}
            handleToggleChange={(e) => {
              onSwitchChange(e, row?.id, row?.[header?.slug]?.slug);
            }}
            disabled={row?.[header?.slug]?.disabled}
          />
        );
      case HEADER_SLUG?.options:
        return (
          <>
            <BlunoTableOptions
              row={row}
              OptionsComponent={OptionsComponent}
              onOptionsFunction={onOptionsFunction}
              header={header}
              tableRef={tableRef}
              tableHeadRef={tableHeadRef}
              showHoverBlueRow={showHoverBlueRow}
              highlightRow={highlightRow}
              unHighlightRow={unHighlightRow}
            />
          </>
        );
      case HEADER_SLUG?.imageWithFunction:
        return (
          <BlunoTooltip
            useChildren={true}
            text={!allSelected && row?.[header?.slug]?.tooltip_text}
            position="fixed"
            tooltipDivWidth="max-content"
            left="12px"
          >
            <div
              className={`${
                row?.[header?.slug]?.icon_bg_hover
                  ? "s-table__table-imgfunction-background"
                  : ""
              } ${
                row?.[header?.slug]?.hidden
                  ? "s-table__table-imgfunction--hidden"
                  : ""
              }`}
              onClick={() => onImageFunction(row?.id)}
            >
              <img
                className={`s-table__table-imgfunction ${
                  showHoverOnSelect && rowSelectionState?.[row?.id]
                    ? "s-table__table-imgfunction--hovered"
                    : ""
                }`}
                id={row?.id + "imgFunction"}
                src={row?.[header?.slug]?.icon}
                alt={header?.slug}
              />
            </div>
          </BlunoTooltip>
        );
      case HEADER_SLUG?.textWithIcon:
        return (
          <>
            <p
              className={` ${
                tableData?.enable_long_text
                  ? "s-table__ellipsis--disabled"
                  : "s-table__ellipsis"
              }`}
            >
              {row?.[header?.slug]?.text}
            </p>

            {row?.[header?.slug]?.icon &&
              (row?.[header?.slug]?.tooltip_text ? (
                <BlunoTooltip
                  text={row?.[header?.slug]?.tooltip_text}
                  right="0px"
                />
              ) : (
                <img
                  className="s-table__table-text-icon"
                  style={{
                    padding: row?.[header?.slug]?.padding,
                    width: row?.[header?.slug]?.width,
                    height: row?.[header?.slug]?.height,
                  }}
                  src={row?.[header?.slug]?.icon}
                />
              ))}
          </>
        );
      case HEADER_SLUG?.textBox:
        return (
          <div className="s-table__textinput">
            <div style={{ width: row?.[header?.slug]?.width }}>
              <BlunoTooltip
                text={row?.[header?.slug]?.hover_text}
                position="fixed"
                useChildren={true}
                alignment="center"
              >
                <TextInput
                  placeholder=""
                  height="26px"
                  minWidth="86px"
                  iconAlignment=""
                  value={
                    rowFormData?.[row?.id]?.[row?.[header?.slug]?.slug]
                      ?.value ?? ""
                  }
                  onFocus={(e) =>
                    onRowFormFocus(e, row, row?.[header?.slug]?.slug)
                  }
                  textAlign={row?.[header?.slug]?.text_align}
                  onChange={(e) =>
                    onRowFormChange(
                      e.target.value,
                      row,
                      row?.[header?.slug]?.slug
                    )
                  }
                  disabled={
                    undebouncedRowFormData?.[row?.id]?.[
                      row?.[header?.slug]?.slug
                    ]?.disabled
                  }
                />
              </BlunoTooltip>
            </div>
            <p className="s-table__textinput-label">
              {row?.[header?.slug]?.title}
            </p>
            {undebouncedRowFormData?.[row?.id]?.[row?.[header?.slug]?.slug]
              ?.secondary_icon && (
              <img
                src={row?.[header?.slug]?.secondary_icon}
                className="s-table__textinput-icon"
              />
            )}
            {undebouncedRowFormData?.[row?.id]?.[row?.[header?.slug]?.slug]
              ?.primary_icon && (
              <img
                src={row?.[header?.slug]?.icon}
                className="s-table__textinput-icon"
              />
            )}
            {undebouncedRowFormData?.[row?.id]?.[row?.[header?.slug]?.slug]
              ?.error && (
              <BlunoTooltip
                text={
                  undebouncedRowFormData?.[row?.id]?.[row?.[header?.slug]?.slug]
                    ?.error_text
                }
                position="fixed"
                tooltipDivPosition="absolute"
                tooltipDivCoordinates={{ right: "0px" }}
                useChildren={true}
                alignment="center"
                top="-8px"
              >
                <img
                  src={row?.[header?.slug]?.error_icon}
                  className="s-table__textinput-icon"
                  style={{ top: "-8px" }}
                />
              </BlunoTooltip>
            )}
          </div>
        );
      case HEADER_SLUG?.accordion:
        return (
          <div
            className={`s-table__accrodion-action s-table__accrodion-action--${
              row?.[header?.slug]?.slug
            } `}
            onClick={(e) => {
              handleAccrodionTypeClick(e, row);
            }}
          >
            <p>{row?.[header?.slug]?.text}</p>
            <img
              src={"https://static.bluno.in/miscl/dropdown_icon_black.png"}
              alt={row?.[header?.slug]?.text}
            />
          </div>
        );
      case HEADER_SLUG?.ellipsis:
        return (
          <BlunoTooltip
            useChildren={true}
            position="fixed"
            text={row?.[header?.slug]?.text}
            internalToolTipDivWidth="100%"
            alignment={"center"}
          >
            <p
              className={` ${
                tableData?.enable_long_text
                  ? "s-table__ellipsis--disabled"
                  : "s-table__ellipsis"
              }`}
            >
              {truncateText(
                row?.[header?.slug]?.text,
                row?.[header?.slug]?.ellipsis_max_char
              )}
            </p>
          </BlunoTooltip>
        );
      case HEADER_SLUG?.sbStatus:
        return (
          <>
            <p
              style={row?.[header?.slug]?.style}
              className="s-table__sb-status"
            >
              {row?.[header?.slug]?.text}
            </p>
          </>
        );
      case HEADER_SLUG?.ebrcDownload:
        return (
          <div
            className="s-table__upload-action-cont"
            style={{ width: "112px" }}
          >
            <div
              className="s-table__upload-action"
              onClick={(e) => {
                e.stopPropagation();
                bulkDownloadEbrc(row?.ebrc_ids);
              }}
            >
              <img
                className="s-table__upload-action-icon"
                src={row?.[header?.slug]?.icon}
              />
              <p className="s-table__ellipsis s-table__ellipsis--pending">
                {row?.[header?.slug]?.text}
              </p>
            </div>
          </div>
        );
      default:
        const centerEllipsisText =
          row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis &&
          convertNormalTextToCenterEllipsis(row?.[header?.slug]?.text, 6, 6);

        if (row?.[header?.slug]?.sub_text) {
          return (
            <BlunoTooltip
              useChildren={true}
              position="fixed"
              text={
                (row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis ||
                  truncateText(row?.[header?.slug]?.text) !==
                    row?.[header?.slug]?.text) &&
                header?.slug !== HEADER_SLUG?.bankFlow &&
                (row?.[header?.slug]?.hover_text ?? row?.[header?.slug]?.text)
              }
              internalToolTipDivWidth="100%"
              alignment={"center"}
            >
              <div className="s-table__table-cell-subtext">
                {row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis
                  ? centerEllipsisText
                  : truncateText(row?.[header?.slug]?.text)}
                <p className="s-table__table-subtext">
                  {row?.[header?.slug]?.sub_text}
                </p>
              </div>
            </BlunoTooltip>
          );
        } else if (row?.[header?.slug]?.small_tag) {
          return (
            <div
              className="s-table__table-cell-smalltag"
              onMouseEnter={() => {
                setShowCopyWithId(row?.id);
                setShowCopySlug(header?.slug);
              }}
              onMouseLeave={() => {
                setShowCopyWithId(null);
                setShowCopySlug(false);
              }}
            >
              {row?.[header?.slug]?.icon && (
                <img src={row?.[header?.slug]?.icon} />
              )}
              <BlunoTooltip
                useChildren={true}
                position="fixed"
                text={
                  (row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis ||
                    truncateText(row?.[header?.slug]?.text) !==
                      row?.[header?.slug]?.text) &&
                  header?.slug !== HEADER_SLUG?.bankFlow &&
                  (row?.[header?.slug]?.hover_text ?? row?.[header?.slug]?.text)
                }
                internalToolTipDivWidth="100%"
                alignment={"center"}
              >
                {row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis
                  ? centerEllipsisText
                  : truncateText(row?.[header?.slug]?.text)}
              </BlunoTooltip>
              <div className="s-table__table-smalltag">
                <Tag
                  text={truncateText(row?.[header?.slug]?.small_tag?.text)}
                  extraClass={`s-tag--${
                    row?.[header?.slug]?.small_tag?.sub_type
                  } s-tag--smalltag`}
                />
              </div>
              <div
                className={`s-table__table-cell-copy ${
                  row?.[header?.slug]?.enable_copy && showCopyWithId === row?.id
                    ? "s-table__table-cell-copy--visible"
                    : ""
                }`}
              >
                {row?.[header?.slug]?.enable_copy &&
                  showCopyWithId === row?.id &&
                  showCopySlug === header?.slug && (
                    <CopyToClipboard
                      text={row?.[header?.slug]?.text}
                      textPosition={"above"}
                    />
                  )}
              </div>
            </div>
          );
        } else if (row?.[header?.slug]?.hz_text) {
          return (
            <div className={`s-table__table-cell-hztext`}>
              {row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis
                ? centerEllipsisText
                : // : truncateText(row?.[header?.slug]?.text)
                  (() => {
                    const text = truncateText(row?.[header?.slug]?.text);
                    const isDecimalFormat = /\.\d{2}$/.test(text); // Checks if third last is '.' and last two are digits
                    if (isDecimalFormat) {
                      const [whole, decimals] = text.split(".");
                      return (
                        <>
                          {whole}.
                          <span
                            className={`s-table__table-cell-hztext-decimal ${
                              row?.is_footer ? "s-table__table--footer" : ""
                            }`}
                          >
                            {decimals}
                          </span>
                        </>
                      );
                    }
                    return text;
                  })()}
              <p
                className={`s-table__table-hztext ${
                  row?.is_footer ? "s-table__table--footer" : ""
                }`}
              >
                {row?.[header?.slug]?.hz_text}
              </p>
              {row?.[header?.slug]?.icon && (
                <BlunoTooltip
                  useChildren={true}
                  position="fixed"
                  text={
                    (row?.[header?.slug]?.type ===
                      HEADER_SLUG?.centerEllipsis ||
                      truncateText(row?.[header?.slug]?.text) !==
                        row?.[header?.slug]?.text ||
                      row?.[header?.slug]?.hover_text) &&
                    header?.slug !== HEADER_SLUG?.bankFlow &&
                    (row?.[header?.slug]?.hover_text ??
                      row?.[header?.slug]?.text)
                  }
                  internalToolTipDivWidth="100%"
                  alignment={"center"}
                  setTextDangerously={true}
                >
                  <img
                    src={row?.[header?.slug]?.icon}
                    className={`s-table__table-cell-hztext-icon ${
                      row?.[header?.slug]?.slug === "show_bank_charges"
                        ? "s-table__table-cell-hztext-icon--edit"
                        : ""
                    }`}
                    onClick={() => {
                      row?.[header?.slug]?.slug === "show_bank_charges" &&
                        onIconClick(row?.id, row?.[header?.slug]);
                    }}
                  />
                </BlunoTooltip>
              )}
            </div>
          );
        } else if (row?.[header?.slug]?.enable_copy) {
          return (
            <BlunoTooltip
              useChildren={true}
              position="fixed"
              text={
                (row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis ||
                  truncateText(
                    row?.[header?.slug]?.text,
                    row?.[header?.slug]?.max_char_limit
                  ) !== row?.[header?.slug]?.text) &&
                header?.slug !== HEADER_SLUG?.bankFlow &&
                (row?.[header?.slug]?.hover_text ?? row?.[header?.slug]?.text)
              }
              internalToolTipDivWidth="100%"
              alignment={"center"}
            >
              <div
                className={`s-table__table-cell-hztext`}
                onMouseEnter={() => {
                  setShowCopyWithId(row?.id);
                  setShowCopySlug(header?.slug);
                }}
                onMouseLeave={() => {
                  setShowCopyWithId(null);
                  setShowCopySlug(false);
                }}
              >
                {row?.[header?.slug]?.show_avatar && (
                  <Avatar
                    name={row?.[header?.slug]?.text}
                    maxInitials="2"
                    size="24px"
                    round={true}
                    color={
                      ["#D6ECFF", "#F2F2CF", "#EAFFF5", "#E01E5A1A"][
                        rowIndex % 4
                      ]
                    }
                    fgColor="#000000"
                    title={false}
                  />
                )}
                {row?.[header?.slug]?.icon && (
                  <img src={row?.[header?.slug]?.icon} />
                )}
                {row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis
                  ? centerEllipsisText
                  : truncateText(
                      row?.[header?.slug]?.text,
                      row?.[header?.slug]?.max_char_limit
                    )}
                <div
                  className={`s-table__table-cell-copy ${
                    row?.[header?.slug]?.enable_copy &&
                    showCopyWithId === row?.id
                      ? "s-table__table-cell-copy--visible"
                      : ""
                  }`}
                  onMouseEnter={() => {
                    setShowCopyWithId(row?.id);
                    setShowCopySlug(header?.slug);
                  }}
                >
                  {row?.[header?.slug]?.enable_copy &&
                    showCopyWithId === row?.id &&
                    showCopySlug === header?.slug && (
                      <CopyToClipboard
                        text={row?.[header?.slug]?.text}
                        textPosition={"below"}
                      />
                    )}
                </div>
              </div>
            </BlunoTooltip>
          );
        } else if (row?.[header?.slug]?.sub_type) {
          return (
            <BlunoTooltip
              useChildren={true}
              position="fixed"
              text={
                row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis &&
                header?.slug !== HEADER_SLUG?.bankFlow &&
                (row?.[header?.slug]?.hover_text ?? row?.[header?.slug]?.text)
              }
              internalToolTipDivWidth="100%"
              alignment={"center"}
            >
              <p
                className={` s-table__ellipsis--${
                  row?.[header?.slug]?.sub_type
                } ${
                  tableData?.enable_long_text
                    ? "s-table__ellipsis--disabled"
                    : "s-table__ellipsis"
                }`}
              >
                {row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis
                  ? centerEllipsisText
                  : row?.[header?.slug]?.text}
              </p>
            </BlunoTooltip>
          );
        } else {
          let tooltipText =
            row?.[header?.slug]?.hover_text ||
            (row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis &&
              header?.slug !== HEADER_SLUG?.bankFlow)
              ? row?.[header?.slug]?.hover_text
              : row?.[header?.slug]?.text?.length >= 15 &&
                row?.[header?.slug]?.truncate !== false
              ? row?.[header?.slug]?.text
              : null;
          return (
            <BlunoTooltip
              useChildren={true}
              position="fixed"
              text={tooltipText}
              internalToolTipDivWidth="100%"
              alignment={row?.[header?.slug]?.tooltip_alignment ?? "center"}
            >
              <p
                className={` ${
                  tableData?.enable_long_text
                    ? "s-table__ellipsis--disabled"
                    : "s-table__ellipsis"
                }`}
                style={
                  row?.[header?.slug]?.font_size
                    ? { fontSize: row?.[header?.slug]?.font_size }
                    : {}
                }
              >
                {row?.[header?.slug]?.type === HEADER_SLUG?.centerEllipsis
                  ? centerEllipsisText
                  : row?.[header?.slug]?.truncate === false
                  ? row?.[header?.slug]?.text
                  : truncateText(
                      row?.[header?.slug]?.text,
                      row?.[header?.slug]?.ellipsis_max_char
                    )}
              </p>
            </BlunoTooltip>
          );
        }
    }
  };

  const highlightRow = (id) => {
    document
      ?.getElementById("row_id_" + id)
      ?.classList?.add("s-table__data-row--options-hover");
  };

  const unHighlightRow = (id) => {
    document
      ?.getElementById("row_id_" + id)
      ?.classList?.remove("s-table__data-row--options-hover");
  };

  const renderAccordianData = (accordionData, rowId) => {
    switch (accordionData?.slug) {
      case "ticket_accordion":
        return (
          <tr className="b-tickets__timeline-row">
            <td className="b-tickets__timeline-row-data" colSpan="5">
              <TicketsTimeline
                data={accordionData}
                rowId={rowId}
                setIsCallGetApi={setGenericState}
              />
            </td>
          </tr>
        );
    }
  };

  const handleScroll = () => {
    if (!infiniteScroll || !tableRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = tableDataRef.current;

    if (scrollTop > previousScrollPosition.current) {
      if (scrollTop + clientHeight >= scrollHeight - 50)
        onInfiniteScrollReachBottom();
    }
    previousScrollPosition.current = scrollTop;
  };

  const measureTableRef = useCallback(
    (node) => {
      tableRef.current = node;
      if (
        tableRef?.current?.getBoundingClientRect()?.bottom &&
        tableHeadRef?.current?.getBoundingClientRect()?.bottom &&
        tableRef?.current?.getBoundingClientRect()?.top &&
        tableHeadRef?.current?.getBoundingClientRect()?.top &&
        tableData &&
        (!tableData?.title ||
          tableRef?.current?.getBoundingClientRect()?.top !==
            tableHeadRef?.current?.getBoundingClientRect()?.top)
      ) {
        setShowTableData(true);
      }
    },
    [tableData]
  );

  const measureTableHeadRef = useCallback((node) => {
    tableHeadRef.current = node;
  }, []);

  const measureTableDataRef = useCallback(() => {
    if (tableDataRef.current) {
      if (
        infiniteScroll &&
        tableDataRef?.current?.scrollHeight <=
          tableDataRef?.current?.clientHeight
      ) {
        onInfiniteScrollReachBottom();
      }
    }
  }, [infiniteScroll, onInfiniteScrollReachBottom]);

  const checkToLoadNonOverflowData = (index) => {
    return (
      (tableRef.current.getBoundingClientRect().bottom -
        tableHeadRef.current.getBoundingClientRect().bottom) /
        60 >=
      index + 1
    );
  };

  useEffect(() => {
    setTableData(data);
    const tableDataElement = tableDataRef.current;

    if (tableDataElement && infiniteScroll) {
      tableDataElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (tableDataElement && infiniteScroll) {
        tableDataElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [data]);

  useEffect(() => {
    const handleMeasure = () => {
      if (tableDataRef?.current) measureTableDataRef();
    };
    const timeout = setTimeout(handleMeasure, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [tableData, measureTableDataRef]);

  return (
    <>
      <div
        className={`s-table ${
          tableData?.border_supported ? "s-table--border" : ""
        }`}
        style={{ border: noTableBorder && "0px" }}
        ref={measureTableRef}
      >
        {tableData?.title && (
          <div className="s-table__heading">
            <div className="s-table__title">{tableData?.title}</div>
            {tableData?.deeplink && (
              <div className="s-table__link">
                <BlunoIcon src={RedirectIcon} alt="Link" />
              </div>
            )}
          </div>
        )}

        <div
          className={`s-table__data ${
            tableData?.has_full_height ? "s-table__data--full" : ""
          }`}
          style={{ height: dataHeight, maxHeight: maxDataHeight }}
          ref={tableDataRef}
        >
          <table className="s-table__table">
            <thead>
              <tr className="s-table__data-head" ref={measureTableHeadRef}>
                {tableData?.headers?.map((header, index) => {
                  if (!isTableColumnExpanded && header?.compact_header) return;
                  if (isTableColumnExpanded && header?.compact_only) return;
                  if (
                    header?.compact_header &&
                    compactFirstColumn.current === -1
                  ) {
                    compactFirstColumn.current = header?.slug;
                  }
                  return (
                    <th
                      key={`th-${header?.slug}-${index}`}
                      className={`s-table__th--${header?.slug} ${
                        tableData?.enable_long_text
                          ? "s-table__ellipsis--disabled"
                          : ""
                      }${
                        header?.compact_header
                          ? " s-table__table-header--compact" +
                            (compactFirstColumn.current === header?.slug
                              ? " s-table__table-header--compact-first"
                              : header?.compacting_header
                              ? " s-table__table-header--compact-last"
                              : "")
                          : ""
                      }`}
                      style={header?.style}
                    >
                      <div
                        className={`s-table__table--align-${
                          header?.alignment
                        } ${
                          index === 0 ? `s-table__table-header-flexcell` : ""
                        } ${
                          tableData?.select_all_supported && index === 0
                            ? "s-table__table-flexcell"
                            : ""
                        } `}
                      >
                        {tableData?.select_all_supported && index === 0 && (
                          <BlunoTooltip
                            useChildren={true}
                            text={
                              allSelected ||
                              (rowSelectionState
                                ? Object.keys(rowSelectionState).length <=
                                    tableData?.data?.length &&
                                  Object.keys(rowSelectionState).length !== 0 &&
                                  checkIfAllValuesTrue(rowSelectionState)
                                : false)
                                ? "Deselect all"
                                : "Select all"
                            }
                            position="fixed"
                          >
                            <Checkbox
                              size="small"
                              defaultChecked={false}
                              checkedIcon={<CheckBoxIcon />}
                              indeterminateIcon={
                                <IndeterminateCheckBoxOutlinedIcon
                                  sx={{ color: "black" }}
                                />
                              }
                              indeterminate={
                                !allSelected && rowSelectionState
                                  ? (Object.keys(rowSelectionState).length >
                                      0 &&
                                      Object.keys(rowSelectionState).length <
                                        tableData?.data?.length &&
                                      !checkIfAllValuesFalse(
                                        rowSelectionState
                                      )) ||
                                    (Object.keys(rowSelectionState).length ===
                                      tableData?.data?.length &&
                                      !checkIfAllValuesTrue(
                                        rowSelectionState
                                      ) &&
                                      !checkIfAllValuesFalse(rowSelectionState))
                                  : false
                              }
                              checked={
                                allSelected ||
                                (rowSelectionState
                                  ? Object.keys(rowSelectionState).length ===
                                      tableData?.data?.length &&
                                    checkIfAllValuesTrue(rowSelectionState)
                                  : false)
                              }
                              // disabled={row?.is_disabled}
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{
                                color: "black",
                                "&.Mui-checked": {
                                  color: "black",
                                },
                                marginLeft: "-12px",
                                // marginTop: "10px",
                                "& .MuiSvgIcon-root": { fontSize: 18 },
                              }}
                              onChange={() =>
                                !tableData?.selection_with_api &&
                                onAllTableRowSelection(
                                  rowSelectionState,
                                  tableData?.data
                                )
                              }
                            />
                          </BlunoTooltip>
                        )}
                        {header?.sort_supported && (
                          <span
                            onClick={() =>
                              onSortOrderClick(header?.slug, header?.sort_order)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <SortArrowIcon
                              topColor={
                                header?.sort_order === "asc"
                                  ? "#000000"
                                  : "rgba(29, 28, 29, 0.4)"
                              }
                              bottomColor={
                                header?.sort_order === "desc"
                                  ? "#000000"
                                  : "rgba(29, 28, 29, 0.4)"
                              }
                            />
                          </span>
                        )}
                        <div className="s-table__textbox">
                          {header?.slug === HEADER_SLUG?.bankFlow ? (
                            <>
                              <UploadDocHeaders headerData={header} />
                            </>
                          ) : (
                            <>
                              <BlunoTooltip
                                useChildren={true}
                                position="fixed"
                                text={header?.hover_text}
                                internalToolTipDivWidth="100%"
                                alignment={"center"}
                                setTextDangerously={true}
                              >
                                {header?.icon && (
                                  <img
                                    src={header?.icon}
                                    className="s-table__data-head-icon"
                                  />
                                )}
                                <p
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {header?.text}
                                  {header?.sub_text && (
                                    <span>{header?.sub_text}</span>
                                  )}
                                </p>

                                {header?.expansion_header &&
                                  !isTableColumnExpanded && (
                                    <div className="s-table__data-expand">
                                      {!isTableColumnExpanded && (
                                        <div className="s-table__data-expand-divider" />
                                      )}
                                      <img
                                        src={header?.expansion_header}
                                        className="s-table__data-expand-icon s-table__data-expand-icon--plus"
                                        onClick={expandColumnFunction}
                                      />
                                    </div>
                                  )}
                                {header?.compacting_header &&
                                  isTableColumnExpanded && (
                                    <div className="s-table__data-expand">
                                      <img
                                        src={header?.compacting_header}
                                        className="s-table__data-expand-icon"
                                        onClick={expandColumnFunction}
                                      />
                                    </div>
                                  )}
                              </BlunoTooltip>
                            </>
                          )}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {!tableData?.default_view &&
                tableData?.data?.map(
                  (row, rowIndex) =>
                    (!onlyShowNonOverflow ||
                      (showTableData &&
                        checkToLoadNonOverflowData(rowIndex))) && (
                      <>
                        <tr
                          key={`tr-${tableData?.title}-${rowIndex}`}
                          className={`s-table__data-row ${
                            row?.is_selected
                              ? "s-table__data-row--selected"
                              : ""
                          } ${
                            row?.is_footer ? "s-table__data-row--footer" : ""
                          } ${
                            showHoverOnSelect && rowSelectionState?.[row?.id]
                              ? "s-table__data-row--hover"
                              : ""
                          } ${
                            row?.disabled ? " s-table__data-row--disabled" : ""
                          } ${row?.greyed ? "s-table__data-row--grey" : ""} ${
                            showHoverBlueRow ? "" : "s-table__data-row--noHover"
                          } ${
                            row?.id === activeRowId && "mapirm__tablerow-parent"
                          }
                        ${row?.nested_data ? "s-table__data-row--noBorder" : ""}
                         ${
                           row?.id === activeRowId &&
                           row?.accordion_data &&
                           "s-table__accordian-type--highlight"
                         }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (row?.deeplink) {
                              redirectToLink(row?.deeplink);
                            } else {
                              !(
                                metadata?.sb_balance_amount !== undefined &&
                                !row?.is_selected &&
                                metadata?.sb_balance_amount === 0
                              ) &&
                                onActionClick(
                                  section,
                                  {
                                    slug: "simulation",
                                    irm_filter: irmSimulationFilter,
                                  },
                                  row
                                );
                            }
                          }}
                          onMouseEnter={(e) => {
                            if (
                              row?.id &&
                              document.getElementById(row?.id + "imgFunction")
                            ) {
                              document
                                ?.getElementById(row?.id + "imgFunction")
                                ?.classList?.add(
                                  "s-table__table-imgfunction--hovered"
                                );
                            }
                          }}
                          onMouseLeave={(e) => {
                            if (
                              row?.id &&
                              document.getElementById(
                                row?.id + "imgFunction"
                              ) &&
                              !(
                                showHoverOnSelect &&
                                rowSelectionState?.[row?.id]
                              )
                            ) {
                              document
                                ?.getElementById(row?.id + "imgFunction")
                                ?.classList?.remove(
                                  "s-table__table-imgfunction--hovered"
                                );
                            }
                          }}
                          id={`row_id_${row?.id}`}
                        >
                          {tableData?.headers?.map((header, index) => {
                            if (
                              !isTableColumnExpanded &&
                              header?.compact_header
                            )
                              return;
                            if (isTableColumnExpanded && header?.compact_only)
                              return;
                            return (
                              <td key={`td-${header?.slug}-${index}`}>
                                <div
                                  className={`s-table__table--align-${
                                    row?.[header?.slug]?.alignment
                                  } ${
                                    row?.is_footer
                                      ? "s-table__table--footer"
                                      : ""
                                  } ${
                                    index === 0 ? `s-table__table-flexcell` : ""
                                  }`}
                                >
                                  {tableData?.selection_supported &&
                                    index === 0 && (
                                      <BlunoTooltip
                                        useChildren={true}
                                        text={
                                          row?.disabled
                                            ? undefined
                                            : metadata?.sb_balance_amount !==
                                                undefined &&
                                              !row?.is_selected &&
                                              metadata?.sb_balance_amount === 0
                                            ? "Your balance amount is zero.<br>To select this IRM, unselect other."
                                            : row?.is_disabled &&
                                              row?.disabled_text
                                            ? row?.disabled_text
                                            : "Select"
                                        }
                                        position="fixed"
                                        tooltipDivWidth="max-content"
                                        setTextDangerously={true}
                                      >
                                        <Checkbox
                                          size="small"
                                          defaultChecked={false}
                                          checked={
                                            !tableData?.selection_with_api
                                              ? rowSelectionState?.[row?.id] ===
                                                undefined
                                                ? false
                                                : true
                                              : row?.is_selected
                                          }
                                          disabled={
                                            row?.is_disabled ||
                                            (metadata?.sb_balance_amount !==
                                              undefined &&
                                              !row?.is_selected &&
                                              metadata?.sb_balance_amount ===
                                                0) ||
                                            (tableData?.selection_supported &&
                                              Object.keys(row).includes(
                                                "selection_support"
                                              ) &&
                                              row?.selection_support === false)
                                          }
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          sx={{
                                            color: "black",
                                            "&.Mui-checked": {
                                              color: "black",
                                            },
                                            marginLeft: "-12px",
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 18,
                                            },
                                          }}
                                          onChange={() =>
                                            // !tableData?.selection_with_api &&
                                            onTableRowSelection(
                                              row?.id,
                                              section,
                                              {
                                                slug: "simulation",
                                                irm_filter: irmSimulationFilter,
                                              },
                                              row
                                            )
                                          }
                                          onClick={(e) => e.stopPropagation()}
                                        />
                                      </BlunoTooltip>
                                    )}
                                  {getComponent(row, header, rowIndex)}
                                  {row?.is_recommended && index === 0 && (
                                    <img
                                      style={{ marginLeft: "10px" }}
                                      src={unselected_recommended}
                                      alt="unselected recommonded"
                                    />
                                  )}
                                </div>
                              </td>
                            );
                          })}
                        </tr>
                        <>
                          {row?.id === activeRowId &&
                            row?.attach_irm_modal_data && (
                              <tr className="mapirm__tablerow">
                                <td className="mapirm__tabledata" colSpan="7">
                                  {
                                    <IRMAdditionModal
                                      data={row?.attach_irm_modal_data}
                                      irmId={row?.id}
                                      isIrmAddedManually={genericState}
                                      setIsIrmAddedManually={setGenericState}
                                      setActiveRowId={setActiveRowId}
                                    />
                                  }
                                </td>
                              </tr>
                            )}
                        </>
                        <>
                          {row?.id === activeRowId &&
                            row?.accordion_data &&
                            renderAccordianData(row?.accordion_data, row?.id)}
                        </>
                        <>
                          {row?.nested_data && (
                            <NestedTable
                              data={row?.nested_data}
                              headers={tableData?.headers}
                              getComponent={getComponent}
                            />
                          )}
                        </>
                      </>
                    )
                )}
            </tbody>
          </table>
          {showInfiniteScrollLoader && (
            <>
              <img
                src={circleLoader}
                alt=""
                className="s-table__infinite-loader"
              />
            </>
          )}

          {tableData?.default_view && (
            <div className="s-table__default-view">
              <div className="s-table__default-view-illustration">
                <img
                  src={tableData?.default_view?.icon}
                  alt=""
                  className="s-table__default-view-icon"
                />
              </div>
              <div className="s-table__default-view-content">
                <h2 className="s-table__default-view-title">
                  {tableData?.default_view?.title}
                </h2>
                <p className="s-table__default-view-text">
                  {tableData?.default_view?.text}
                </p>
                {tableData?.default_view?.buttons && (
                  <div className="buyer-details__action">
                    {tableData?.default_view?.buttons?.map((action) => {
                      return (
                        action?.type === "button" && (
                          <Button
                            buttonLabel={action?.text}
                            buttonType={action?.sub_type}
                            icon={action?.icon}
                            hoverIcon={action?.hover_icon}
                            disabled={action?.disabled}
                            iconAlignment={action?.icon_alignment}
                            tooltipText={action?.tooltip_text}
                            tooltipPosition={{ bottom: "20px", left: "40px" }}
                            onClick={() => {
                              onDefaultViewButtonClick(action);
                            }}
                          />
                        )
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlunoTable;
