// react imports
import React from "react";

// third-party imports

// in-app imports
import { sanitizeHtml } from "../utils/sanitizeHtml";

const TableProgressBar = ({ data }) => {
  return (
    <>
      <div className="s-table__table-progress">
        {data?.text && (
          <p
            className="s-table__table-progress-title"
            dangerouslySetInnerHTML={sanitizeHtml(data?.text)}
          />
        )}
        {data?.progress_details && (
          <div
            className="s-table__table-progress-bar"
            style={{
              backgroundColor: data?.progress_details?.secondary_color,
            }}
          >
            <span
              style={{
                width: data?.progress_details?.progress_percent,
                backgroundColor: data?.progress_details?.primary_color,
              }}
            ></span>
          </div>
        )}
        {data?.subtext && (
          <p
            className="s-table__table-progress-text"
            dangerouslySetInnerHTML={sanitizeHtml(data?.subtext)}
          />
        )}
      </div>
    </>
  );
};

export default TableProgressBar;
