// react imports
import React, { useState, useEffect } from "react";

// third-party imports

// in-app imports
import uploadCloud from "../../assets/media/upload/upload-cloud.png";

// utils
import UploadedList from "./UploadedList";
import { v4 as uuidv4 } from "uuid";

const UploadFileCard = (props) => {
  // Props variables
  const {
    documents,
    upload_enabled,
    doc_type,
    uploadSingleFile,
    docId,
    invoiceId,
    section,
    onDocAction = () => {},
    sessionFileList = null,
    screen = "",
    setIsErrorOccurred = () => {},
    isErrorOccurred,
    setError = () => {},
    disabled = false,
    element_id,
    primaryText = "Upload a file ",
    secondaryText,
    padding,
    subTitle,
    backgroundColor = "white",
    parBgColor,
    showPreviousFiles = false,
    errorFileNames,
    setErrorFileNames,
    maxFileNameWidth = "180px",
    allowedDocTypes = { ".pdf": 5120 },
    extraUploadListClass,
  } = props;

  // local state variables
  const [files, setFiles] = useState(documents ? documents : []);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isUploadShow, setIsUploadShow] = useState();
  const [acceptedExtensionsList, setAcceptedExtensionsList] = useState("");
  const id = uuidv4();

  const handleFileInputChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedFiles = e.target.files;
      if (selectedFiles?.[0]?.size > 5 * Math.pow(2, 20)) {
        setIsErrorOccurred(true);
        setError("The file size is too large. Please upload a smaller file.");
        return;
      }
      setIsErrorOccurred(false);
      uploadSingleFile(selectedFiles, doc_type, invoiceId, files);
      setIsUploadShow(doc_type === "SHIPPING_BILL");

      const newFiles = [...files, ...Array.from(selectedFiles)];
      setFiles(newFiles);
    }
  };

  const handleFileDrop = (e) => {
    setIsErrorOccurred(false);
    e.preventDefault();
    const selectedFiles = e.dataTransfer.files;
    if (selectedFiles.length > 1) {
      setIsErrorOccurred(true);
      setError("Please upload only one file");
      return;
    }
    if (selectedFiles?.[0]?.size > 5 * Math.pow(2, 20)) {
      setIsErrorOccurred(true);
      setError("The file size is too large. Please upload a smaller file.");
      return;
    }
    if (e.dataTransfer.files[0].type === "application/pdf") {
      uploadSingleFile(selectedFiles, doc_type, invoiceId);

      const newFiles = [...files, ...Array.from(selectedFiles)];
      setFiles(newFiles);
      setIsDraggingOver(false);
      setIsUploadShow(doc_type === "SHIPPING_BILL");
    } else {
      setIsErrorOccurred(true);
      setError("Please upload valid file format");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  useEffect(() => {
    if (showPreviousFiles) {
      const uploaded_filenames = documents.map((item) => item?.name);
      setFiles((prev) =>
        prev.map((item, idx) => {
          if (uploaded_filenames.includes(item?.name)) {
            return documents.filter((item1, idx) => {
              return item1?.name === item?.name;
            })[0];
          } else {
            return item;
          }
        })
      );
    } else setFiles(documents);
  }, [documents]);

  useEffect(() => {
    if (isErrorOccurred) {
      if (showPreviousFiles && errorFileNames) {
        setFiles((prev) =>
          prev.filter((item) => {
            return !errorFileNames.includes(item?.name);
          })
        );
        setErrorFileNames([]);
      } else setFiles(documents);
    }
  }, [isErrorOccurred]);

  useEffect(() => {
    if (upload_enabled) {
      setIsUploadShow(upload_enabled);
    }
  }, [upload_enabled]);

  useEffect(() => {
    let count = 0;
    let acceptedExtensionsListTemp = "";
    for (let item in allowedDocTypes) {
      acceptedExtensionsListTemp += (count === 0 ? "" : ",") + item;
      count++;
    }
    setAcceptedExtensionsList(acceptedExtensionsListTemp);
  }, []);

  return (
    <div
      className="s-upload-card"
      style={parBgColor && { backgroundColor: parBgColor }}
    >
      {isUploadShow && (
        <label className="s-upload-card__label" htmlFor={id}>
          <section
            className={`s-upload-card__input 
        ${isDraggingOver && "s-upload-card__input--drag"} ${
              !disabled && "s-upload-card__input--hover"
            }`}
            onDrop={handleFileDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            style={{ padding: padding, backgroundColor: backgroundColor }}
          >
            <input
              id={id}
              className="s-upload-card__upload"
              type="file"
              accept={acceptedExtensionsList}
              multiple={false}
              onChange={handleFileInputChange}
              disabled={disabled}
            />
            <img
              className={`s-upload-card__label-img ${
                disabled && "s-upload-card--disabled"
              }`}
              src={uploadCloud}
              alt="upload cloud"
            />
            <div className="s-upload-card__text-cont">
              <p
                className={`s-upload-card__text ${
                  disabled && "s-upload-card--disabled"
                }`}
              >
                <span
                  className={`s-upload-card__label-text ${
                    disabled && "s-upload-card--disabled"
                  }`}
                >
                  {primaryText}
                </span>
                or drag and drop here
              </p>
              {secondaryText && (
                <p className="s-upload-card__secondary-text">{secondaryText}</p>
              )}
            </div>
          </section>
        </label>
      )}

      <section className={`s-upload-card__list ${extraUploadListClass}`}>
        {files?.length > 0 &&
          files?.map((file, idx) => {
            return (
              <>
                <UploadedList
                  files={files}
                  file={file}
                  idx={idx}
                  onDocAction={onDocAction}
                  section={section}
                  docId={docId}
                  docType={doc_type}
                  maxFileNameWidth={maxFileNameWidth}
                />
              </>
            );
          })}
      </section>
    </div>
  );
};

export default UploadFileCard;
